import React from 'react';
import Events from '../view/subViews/Dashboard/Events';
import NewEventView from '../view/subViews/Dashboard/NewEvent';

const protectedRoutesNoEvent = [
  {
    name: 'Events',
    exact: true,
    path: '/events',
    main: (props) => <Events {...props} />,
    public: false,
  },
  {
    name: 'New Event',
    exact: true,
    path: '/newevent',
    main: (props) => <NewEventView {...props} />,
    public: false,
  }
];

export default protectedRoutesNoEvent;
