import React, { useState, useContext, useEffect, useRef } from 'react';

import { StoreContext } from '../../../context/storeContext';
import { GlobalContext } from '../../../context/globalContext';

// Component
import StatsTab from './StatsTab';
import ConfigTab from './ConfigTab';
import ExportsTab from './ExportsTab';

import HeaderPannel from '../../Component/HeaderPannel';

// Material Component
import {
  Tabs,
  Tab,
} from '@material-ui/core';

// Style
import { useMainStyles } from '../../classes/main';
import PropTypes from 'prop-types';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Event = () => {
  const store = useContext(StoreContext);
  const globalC = useContext(GlobalContext);


  const classes = useMainStyles();

  // States
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const unsubscribe = store.loadStands();
    const unsubscribe2 = store.loadItems();
    const unsubscribe3 = store.loadDevices();

    globalC.setActiveMenu(1);


    return () => {
      unsubscribe && unsubscribe();
      unsubscribe2 && unsubscribe2();
      unsubscribe3 && unsubscribe3();
    };
  }, []);

  return (
    <div className={classes.main}>
      <HeaderPannel />
      <div className={classes.body}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          classes={{ indicator: classes.eventsTabIndicator }}
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example">
          <Tab label="Configurer" {...a11yProps(0)} />
          <Tab label="Suivi" {...a11yProps(1)} />
          <Tab label="Exports" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ConfigTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StatsTab />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ExportsTab />
        </TabPanel>
      </div>
    </div>
  );
};

export default Event;
