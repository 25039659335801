import React, { useState, useEffect, useContext } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import {AuthContext} from '../../../context/authContext';
import {StoreContext} from '../../../context/storeContext';
import {GlobalContext} from '../../../context/globalContext';
//import {Exports} from './Exports';

import { TextField } from '@material-ui/core';
import { toInteger } from 'lodash';

const useStyles = makeStyles((theme) => ({
    root: {
      //display: 'flex',
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    buttonForm: {
      margin: theme.spacing(2)
    },
    boxFlex: {
      display: 'flex',
    },
    instructions: {
      margin: theme.spacing(2),
      fontSize: '150%',
    },
    totauxCalcules: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        backgroundColor: "white" ,
        borderWidth: '5px',
        borderColor: "black",
        borderRadius: '10px',
        textAlign: 'center',
    },
    formInput: {
      margin: theme.spacing(2),
      width: '25ch',
    },
    form: {
      padding: theme.spacing(1),
      borderRadius: "10px",
      alignSelf: "center",
    },
    divstandard: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
    },
  
  }));

export default function ComptaDevis() 
{
    const store = useContext(StoreContext);
    const authContext = useContext(AuthContext);
    const globalContext = useContext(GlobalContext);
    const classes = useStyles();

    useEffect(() => {
        globalContext.setActiveMenu(6);
    },[])

    const [fcompta, setfcompta] = React.useState(1700);
    const handleChangefcompta = (event) => {
      setfcompta(event.target.value);
    };

    const [domiciliation, setdomiciliation] = React.useState(280);
    const handleChangedomiciliation = (event) => {
      setdomiciliation(event.target.value);
    };

    const [nomDomaine, setnomDomaine] = React.useState(250);
    const handleChangenomDomaine = (event) => {
      setnomDomaine(event.target.value);
    };

    const [hebergement, setHebergement] = React.useState(250);
    const handleChangeHebergement = (event) => {
      setHebergement(event.target.value);
    };

    const [fMarketing, setfMarketing] = React.useState(300);
    const handleChangefMarketing = (event) => {
      setfMarketing(event.target.value);
    };

    const [fOpeInternet, setfOpeInternet] = React.useState(600);
    const handleChangefOpeInternet = (event) => {
      setfOpeInternet(event.target.value);
    };

    const [fLocaLocaux, setfLocaLocaux] = React.useState(0);
    const handleChangefLocaLocaux = (event) => {
      setfLocaLocaux(event.target.value);
    };

    const [fTransport, setfTransports] = React.useState(100);
    const handleChangefTransports = (event) => {
      setfTransports(event.target.value);
    };

    const [fNourriture, setfNourriture] = React.useState(100);
    const handleChangefNourriture = (event) => {
      setfNourriture(event.target.value);
    };

    const [fLogement, setfLogement] = React.useState(200);
    const handleChangefLogement = (event) => {
      setfLogement(event.target.value);
    };

    const [fSalaires, setfSalaires] = React.useState(0);
    const handleChangefSalaires = (event) => {
      setfSalaires(event.target.value);
      setfChargesSociales(event.target.value*0.82);
    };

    const [fChargesSociales, setfChargesSociales] = React.useState(fSalaires*0.82);
    const handleChangefChargesSociales = (event) => {
      setfChargesSociales(event.target.value);
    };

    const [fTelephonie, setfTelephonie] = React.useState(0);
    const handleChangefTelephonie = (event) => {
      setfTelephonie(event.target.value);
    };

    const [fSupportInfo, setfSupportInfo] = React.useState(0);
    const handleChangefSupportInfo = (event) => {
      setfSupportInfo(event.target.value);
    };

    const [fMateriel, setfMateriel] = React.useState(0);
    const handleChangefMateriel = (event) => {
      setfMateriel(event.target.value);
    };

    const [fPresta, setfPresta] = React.useState(0);
    const handleChangefPresta = (event) => {
      setfPresta(event.target.value);
    };

    const [fImpressions, setfImpressions] = React.useState(20);
    const handleChangefImpressions = (event) => {
      setfImpressions(event.target.value);
    };

    const [fCourriers, setfCourriers] = React.useState(20);
    const handleChangefCourriers = (event) => {
      setfCourriers(event.target.value);
    };

    const [fAssurances, setfAssurances] = React.useState(300);
    const handleChangefAssurances = (event) => {
      setfAssurances(event.target.value);
    };

    const [fPenalites, setfPenalites] = React.useState(0);
    const handleChangefPenalites = (event) => {
      setfPenalites(event.target.value);
    };

    const [fAmendes, setfAmendes] = React.useState(0);
    const handleChangefAmendes = (event) => {
      setfAmendes(event.target.value);
    };

    const [fEmprunts, setfEmprunts] = React.useState(10);
    const handleChangefEmprunts = (event) => {
      setfEmprunts(event.target.value);      
    };

    const [totalFraisAnnuels, setTotalFraisAnnuels] = React.useState(
        parseInt(fcompta)+
        parseInt(domiciliation)+
        parseInt(nomDomaine)+
        parseInt(hebergement)+
        parseInt(fMarketing)+
        parseInt(fOpeInternet)+
        parseInt(fLocaLocaux)+
        parseInt(fTransport)+
        parseInt(fNourriture)+
        parseInt(fLogement)+
        parseInt(fSalaires)+
        parseInt(fChargesSociales)+
        parseInt(fTelephonie)+
        parseInt(fSupportInfo)+
        parseInt(fMateriel)+
        parseInt(fPresta)+
        parseInt(fImpressions)+
        parseInt(fCourriers)+
        parseInt(fAssurances)+
        parseInt(fPenalites)+
        parseInt(fAmendes)+
        parseInt(fEmprunts)
    );

    const calculTotalFraisAnnuels = () => {
        let totalFrais = (
            parseInt(fcompta)+
            parseInt(domiciliation)+
            parseInt(nomDomaine)+
            parseInt(hebergement)+
            parseInt(fMarketing)+
            parseInt(fOpeInternet)+
            parseInt(fLocaLocaux)+
            parseInt(fTransport)+
            parseInt(fNourriture)+
            parseInt(fLogement)+
            parseInt(fSalaires)+
            parseInt(fChargesSociales)+
            parseInt(fTelephonie)+
            parseInt(fSupportInfo)+
            parseInt(fMateriel)+
            parseInt(fPresta)+
            parseInt(fImpressions)+
            parseInt(fCourriers)+
            parseInt(fAssurances)+
            parseInt(fPenalites)+
            parseInt(fAmendes)+
            parseInt(fEmprunts)
        );
        setTotalFraisAnnuels(totalFrais);
    };

    const [nbEvents, setnbEvents] = React.useState(4);
    const [nbJoursEvents, setnbJoursEvents] = React.useState(2);
    const [nbMoyGuests, setnbMoyGuests] = React.useState(500);
    const [nbMoyTel, setnbMoyTel] = React.useState(10);
    const [nbPrixTel, setnbPrixTel] = React.useState(50);
    const [nbPrixEntreeEvent, setPrixEntreeEvent] = React.useState(50);
    const [nbPrixRepasEvent, setPrixRepasEvent] = React.useState(75);
    const [nbPrixTransportEvent, setPrixTransportEvent] = React.useState(100);
    const [nbPrixLogementEvent, setPrixLogementEvent] = React.useState(100);
    const [prixDevis, setPrixDevis] = React.useState(0);

    const CalculDevis = () => {
        let totalFrais = (
            parseInt(fcompta)+
            parseInt(domiciliation)+
            parseInt(nomDomaine)+
            parseInt(hebergement)+
            parseInt(fMarketing)+
            parseInt(fOpeInternet)+
            parseInt(fLocaLocaux)+
            parseInt(fTransport)+
            parseInt(fNourriture)+
            parseInt(fLogement)+
            parseInt(fSalaires)+
            parseInt(fChargesSociales)+
            parseInt(fTelephonie)+
            parseInt(fSupportInfo)+
            parseInt(fMateriel)+
            parseInt(fPresta)+
            parseInt(fImpressions)+
            parseInt(fCourriers)+
            parseInt(fAssurances)+
            parseInt(fPenalites)+
            parseInt(fAmendes)+
            parseInt(fEmprunts)
        );
        let nbTechniciens = (Math.round(nbMoyGuests/500)*nbEvents);
        let devis = (
                        (totalFrais+
                        (nbTechniciens*(
                            parseInt(nbJoursEvents)*(
                                parseInt(nbPrixRepasEvent)+
                                parseInt(nbPrixLogementEvent)
                            )+
                            nbPrixEntreeEvent+
                            parseInt(nbPrixTransportEvent)
                            )
                        )
                        )
                        /parseInt(nbEvents)
                    );
        setPrixDevis(devis);
    };

    const nbTechos = (eventnumber, guestsnumber) =>
    {
        const nbTechInterm = Math.round(guestsnumber / 500);
        const nbTechTotal = nbTechInterm * eventnumber ;
        return nbTechTotal;
    };

    //const [nbTech, setnbTech] = React.useState(nbTechos(nbEvents, nbMoyGuests));
    /*const handleChangenbMoyGuests = () => {
      setnbMoyGuests(event.target.value);
    };*/

    const handleChangenbEvents = (event, newValue) => {
      setnbEvents(newValue);
      //setnbTech(nbTechos(nbEvents, nbMoyGuests));
      //console.log(nbEvents);
    };

    const handleChangenbJoursEvents = (event, newValue) => {
        setnbJoursEvents(newValue);
        //setnbTech(nbTechos(nbEvents, nbMoyGuests));
        //console.log(nbEvents);
    };

    const handleChangenbMoyGuests = (event, newValue) => {
      setnbMoyGuests(newValue /*event.target.value*/);
      //setnbTech(nbTechos(nbEvents, nbMoyGuests));
      //console.log(nbMoyGuests);
    };

    const handleChangenbMoyTel = (event, newValue) => {
        setnbMoyTel(newValue /*event.target.value*/);
        //setnbTech(nbTechos(nbEvents, nbMoyGuests));
        //console.log(nbMoyGuests);
      };

    const handleChangenbPrixTel = (event, newValue) => {
    setnbPrixTel(newValue /*event.target.value*/);
    //setnbTech(nbTechos(nbEvents, nbMoyGuests));
    //console.log(nbMoyGuests);
    };

    const handleChangePrixTransportEvent = (event, newValue) => {
        setPrixTransportEvent(newValue /*event.target.value*/);
        //setnbTech(nbTechos(nbEvents, nbMoyGuests));
        //console.log(nbMoyGuests);
    };

    const handleChangePrixRepasEvent = (event, newValue) => {
        setPrixRepasEvent(newValue /*event.target.value*/);
        //setnbTech(nbTechos(nbEvents, nbMoyGuests));
        //console.log(nbMoyGuests);
    };

    const handleChangePrixLogementEvent = (event, newValue) => {
        setPrixLogementEvent(newValue /*event.target.value*/);
        //setnbTech(nbTechos(nbEvents, nbMoyGuests));
        //console.log(nbMoyGuests);
    };

    const handleChangePrixEntreeEvent = (event, newValue) => {
        setPrixEntreeEvent(newValue /*event.target.value*/);
        //setnbTech(nbTechos(nbEvents, nbMoyGuests));
        //console.log(nbMoyGuests);
    };

    function valuetext(value) {
        return value;
    };

    const marksNbEvent = [
        {
        value: 0,
        label: '0',
        },
        {
        value: 2,
        label: '2',
        },
        {
        value: 5,
        label: '5',
        },
        {
        value: 10,
        label: '10',
        },
        {
        value: 20,
        label: '20',
        },
    ];

    const marksNbJoursEvent = [
        {
        value: 0,
        label: '0',
        },
        {
        value: 2,
        label: '2',
        },
        {
        value: 4,
        label: '4',
        },
        {
        value: 6,
        label: '6',
        },
        {
        value: 8,
        label: '8',
        },
    ];

    const marksNbGuests = [
        {
        value: 100,
        label: '100',
        },
        {
        value: 2000,
        label: '2 000',
        },
        {
        value: 5000,
        label: '5 000',
        },
        {
        value: 10000,
        label: '10 000',
        },
    ];

    const marksNbTel = [
        {
        value: 0,
        label: '0',
        },
        {
        value: 25,
        label: '25',
        },
        {
        value: 50,
        label: '50',
        },
        {
        value: 75,
        label: '75',
        },
        {
        value: 100,
        label: '100',
        },
    ];

    const marksPrixTel = [
        {
        value: 0,
        label: '0',
        },
        {
        value: 25,
        label: '25',
        },
        {
        value: 50,
        label: '50',
        },
        {
        value: 75,
        label: '75',
        },
        {
        value: 100,
        label: '100',
        },
        {
        value: 200,
        label: '200',
        },
    ];


    return(
        <Box 
        className={classes.boxFlex}  
        bgcolor="white" 
        width="100%" 
        display="flex"  
        flexDirection="row" 
        >
            <Box 
                className={classes.boxFlex}  
                bgcolor="white" 
                width="20%" 
                display="flex"  
                flexDirection="column" 
                alignItems="center"
                borderColor="primary"
            >
                <Typography className={classes.instructions}>
                    Frais de fonctionnement de la société à l'année <br></br>
                </Typography>
                <Box>
                    <TextField className={classes.formInput} id="fComptable" label="Frais comptables" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fcompta} onChange={handleChangefcompta} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fDomiciliation" label="Frais de Domiciliation" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={domiciliation} onChange={handleChangedomiciliation} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fNomDomaine" label="Frais de nom de domaine" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={nomDomaine} onChange={handleChangenomDomaine} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fHebergement" label="Frais d'hébergement" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={hebergement} onChange={handleChangeHebergement} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fMarketing" label="Frais Marketing" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fMarketing} onChange={handleChangefMarketing} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fOpeInternet" label="Frais de l'opérateur Internet" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fOpeInternet} onChange={handleChangefOpeInternet} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fLocaLocaux" label="Frais de location des locaux" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fLocaLocaux} onChange={handleChangefLocaLocaux} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fTransports" label="Frais de transports" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fTransport} onChange={handleChangefTransports} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fNourriture" label="Frais de bouche" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fNourriture} onChange={handleChangefNourriture} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fLogement" label="Frais de logement" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fLogement} onChange={handleChangefLogement} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fSalaires" label="Salaires des employés" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fSalaires} onChange={handleChangefSalaires} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fChargesSociales" label="Charges sociales sur les salaires" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={Math.round(fSalaires*0.82)} onChange={handleChangefChargesSociales} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fTelephonie" label="Frais liés à la téléphonie" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fTelephonie} onChange={handleChangefTelephonie} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fSupportInfo" label="Frais de support informatique" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fSupportInfo} onChange={handleChangefSupportInfo} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fMateriel" label="Frais d'achats de matériel" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fMateriel} onChange={handleChangefMateriel} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fPresta" label="Frais d'achats de prestation" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fPresta} onChange={handleChangefPresta} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fImpressions" label="Frais d'impressions de documents" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fImpressions} onChange={handleChangefImpressions} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fCourriers" label="Frais de courrier" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fCourriers} onChange={handleChangefCourriers} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fAssurances" label="Frais d'asssurances" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fAssurances} onChange={handleChangefAssurances} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fPenalites" label="Pénalités à verser" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fPenalites} onChange={handleChangefPenalites} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fAmendes" label="Amendes à verser" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fAmendes} onChange={handleChangefAmendes} />
                </Box>
                <Box>
                    <TextField className={classes.formInput} id="fEmprunts" label="Remboursement des emprunts" 
                        type="number"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        value={fEmprunts} onChange={handleChangefEmprunts} />
                </Box>
                <Box>
                    <Typography>
                        Somme totale des frais de fonctionnement : 
                        {
                            //totalFraisAnnuels
                            parseInt(fcompta)+
                            parseInt(domiciliation)+
                            parseInt(nomDomaine)+
                            parseInt(hebergement)+
                            parseInt(fMarketing)+
                            parseInt(fOpeInternet)+
                            parseInt(fLocaLocaux)+
                            parseInt(fTransport)+
                            parseInt(fNourriture)+
                            parseInt(fLogement)+
                            parseInt(fSalaires)+
                            parseInt(fChargesSociales)+
                            parseInt(fTelephonie)+
                            parseInt(fSupportInfo)+
                            parseInt(fMateriel)+
                            parseInt(fPresta)+
                            parseInt(fImpressions)+
                            parseInt(fCourriers)+
                            parseInt(fAssurances)+
                            parseInt(fPenalites)+
                            parseInt(fAmendes)+
                            parseInt(fEmprunts)}
                    </Typography>
                </Box>
{/*                <Box>
                    <Button 
                            id="updateTotalFrais"
                            variant="contained" 
                            onClick={calculTotalFraisAnnuels} 
                    >
                    Update
                    </Button>
                </Box>*/}
            </Box>
            
            <Box 
                className={classes.boxFlex}  
                bgcolor="white" 
                width="20%" 
                display="flex"  
                flexDirection="column" 
                alignItems="center"
                borderColor="primary"
            >
                <Typography className={classes.instructions}>
                    Estimation de l'activité sur l'année <br></br>
                </Typography>
                <Box>
                    <Typography id="discrete-slider-custom" gutterBottom>
                        Estimation du nombre d'évènements sur l'année
                    </Typography>
                    <Slider
                        //defaultValue={nbEvents}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={1}
                        min={0}
                        max={20}
                        valueLabelDisplay="auto"
                        marks={marksNbEvent}
                        value={nbEvents}
                        onChange={handleChangenbEvents}
                    />
                </Box>
                <Box>
                    <Typography id="discrete-slider-custom" gutterBottom>
                        Estimation du nombre moyen de participants par évènement
                    </Typography>
                    <Slider
                        //defaultValue={nbMoyGuests}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={100}
                        min={100}
                        max={10000}
                        valueLabelDisplay="auto"
                        marks={marksNbGuests}
                        value={nbMoyGuests}
                        onChange={handleChangenbMoyGuests}
                    />
                </Box>
                <Box>
                    <Typography className={classes.totauxCalcules} >
                        Nombre de techniciens de maintenance nécessaires sur l'année = {(Math.round(nbMoyGuests/500)*nbEvents)}
                    </Typography>
                </Box>
                <Box>
                    <Typography id="discrete-slider-custom" gutterBottom>
                        Estimation de la durée moyenne en jours d'un évènement
                    </Typography>
                    <Slider
                        //defaultValue={nbEvents}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={0.5}
                        min={0}
                        max={8}
                        valueLabelDisplay="auto"
                        marks={marksNbJoursEvent}
                        value={nbJoursEvents}
                        onChange={handleChangenbJoursEvents}
                    />
                </Box>
                <Box>
                    <Typography id="discrete-slider-custom" gutterBottom >
                        Estimation du nombre moyen de téléphones nécessaires sur un évènement
                    </Typography>
                    <Slider
                        //defaultValue={nbMoyGuests}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={1}
                        min={0}
                        max={100}
                        valueLabelDisplay="auto"
                        marks={marksNbTel}
                        value={nbMoyTel}
                        onChange={handleChangenbMoyTel}
                    />
                </Box>
                <Box>
                    <Typography id="discrete-slider-custom" gutterBottom >
                        Estimation du prix moyen de la location d'un téléphone
                    </Typography>
                    <Slider
                        //defaultValue={nbMoyGuests}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={1}
                        min={0}
                        max={200}
                        valueLabelDisplay="auto"
                        marks={marksPrixTel}
                        value={nbPrixTel}
                        onChange={handleChangenbPrixTel}
                    />
                </Box>
                <Box>
                    <Typography id="discrete-slider-custom" gutterBottom >
                        Estimation du prix moyen de transports aller-retour par agent de maintenance sur un évènement
                    </Typography>
                    <Slider
                        //defaultValue={nbMoyGuests}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={1}
                        min={0}
                        max={200}
                        valueLabelDisplay="auto"
                        marks={marksPrixTel}
                        value={nbPrixTransportEvent}
                        onChange={handleChangePrixTransportEvent}
                    />
                </Box>
                <Box>
                    <Typography id="discrete-slider-custom" gutterBottom >
                        Estimation du prix moyen des repas par agent de maintenance sur un évènement
                    </Typography>
                    <Slider
                        //defaultValue={nbMoyGuests}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={1}
                        min={0}
                        max={200}
                        valueLabelDisplay="auto"
                        marks={marksPrixTel}
                        value={nbPrixRepasEvent}
                        onChange={handleChangePrixRepasEvent}
                    />
                </Box>
                <Box>
                    <Typography id="discrete-slider-custom" gutterBottom >
                        Estimation du prix moyen du logement par agent de maintenance sur un évènement
                    </Typography>
                    <Slider
                        //defaultValue={nbMoyGuests}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={1}
                        min={0}
                        max={200}
                        valueLabelDisplay="auto"
                        marks={marksPrixTel}
                        value={nbPrixLogementEvent}
                        onChange={handleChangePrixLogementEvent}
                    />
                </Box>
                <Box>
                    <Typography id="discrete-slider-custom" gutterBottom >
                        Estimation du prix moyen de l'éntrée par agent de maintenance sur un évènement
                    </Typography>
                    <Slider
                        //defaultValue={nbMoyGuests}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={1}
                        min={0}
                        max={200}
                        valueLabelDisplay="auto"
                        marks={marksPrixTel}
                        value={nbPrixEntreeEvent}
                        onChange={handleChangePrixEntreeEvent}
                    />
                </Box>
            </Box>

            <Box 
                className={classes.boxFlex}  
                bgcolor="white" 
                width="20%" 
                display="flex"  
                flexDirection="column" 
                alignItems="center"
                borderColor="primary"
            >
                <Typography className={classes.instructions}>
                    Estimation du prix a facturer par festival : <br></br>
                </Typography>
                <Typography className={classes.instructions}>
                    {prixDevis}
                </Typography>
                <Button variant='contained' onClick={CalculDevis}>Générer Devis</Button>
            </Box>
    </Box>
    )
}