import { createTheme } from '@material-ui/core/styles';
import { palette } from '@mui/system';
// import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#796BCF',
      main: '#42339D',
      darker: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffff66',
      main: '#FFC000',
      darker: '#ba000d',
      // dark: '#ba000d',
      contrastText: '#000',
    },
    white: '#FFFFFF',
    danger: '#FF6B6C',
    black: '#262626',
    grey: {
      main: '#EBEBED',
      light: '#F3F3F4',
      dark: '#A6A6A6',
    },
    success: { main: '#008040', light: '#99ff66', dark: '#00b050' },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#796BCF',
      },
    },
    MuiDropzoneArea: {
      root: {
        width: '300px',
      },
      text: {
        fontSize: 20,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid #ececec',
      },
      footer: {
        border: '0px',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#002884',
          '&:hover': {
            backgroundColor: '#42339D',
          },
        },
      },
      button: {
        '&:hover': {
          backgroundColor: '#42339D',
        },
      },
    },
    // MuiIconButton: {
    //   root: {
    //     padding: '1px',
    //   },
    // },
  },
  typography: {
    fontFamily: "'Roboto','Helvetica','Arial'",
  },
});

export default theme;
