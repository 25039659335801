import React, { useContext } from 'react';

import { AuthContext } from '../../context/authContext';
import { StoreContext } from '../../context/storeContext';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Material Component
import { Box, MenuItem, Select, Typography } from '@mui/material';

// Style
import { useMainStyles } from '../classes/main';

const HeaderPannel = (props) => {
  const classes = useMainStyles();

  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);

  const selectedEvent = store.festivals?.find(
    (e) => e.id === authC.selectedFestival,
  )
    ? store.festivals.find((e) => e.id === authC.selectedFestival)
    : undefined;

  return (
    <Box className={classes.header}>
      <Select
        className={classes.selectEvent}
        variant="outlined"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={
          store.festivals?.find((e) => e.id === authC.selectedFestival)
            ? store.festivals.find((e) => e.id === authC.selectedFestival).id
            : ''
        }
        IconComponent={ExpandMoreIcon}
        onChange={(e) => {
          authC.setSelectedFestival(e.target.value);
        }}>
        {store.festivals?.map((festival) => {
          return (
            <MenuItem key={festival.id} value={festival.id}>
              {festival.name}
            </MenuItem>
          );
        })}
      </Select>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        flexGrow={1}
        style={{ marginLeft: '36px' }}>
        <Box>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            {selectedEvent?.name}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="80%">
          <Box>Localisation: {selectedEvent?.location || '-'}</Box>
          <Box>
            Du: {selectedEvent?.start?.toDate().toLocaleString() || '-'}
          </Box>
          <Box>Au: {selectedEvent?.end?.toDate().toLocaleString() || '-'}</Box>
        </Box>
      </Box>
      {/* </AppBar> */}
    </Box>
  );
};

export default HeaderPannel;
