import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../../context/authContext';
import { StoreContext } from '../../../context/storeContext';
import { GlobalContext } from '../../../context/globalContext';

// Material Component
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputBase,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

// Style
import { useMainStyles } from '../../classes/main';
import PropTypes from 'prop-types';

// Component
import EventsTab from './EventsTab';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Header = (props) => {
  const [search, setSearch] = useState(null);
  const classes = useMainStyles();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  return (
    <Box className={classes.header}>
      {/* <AppBar elevation={0} className={classes.header} position="static"> */}
      <Typography className={classes.dashTitle} variant="subtitle1">
        DASHBOARD
      </Typography>
      <form
        className={classes.search}
        onSubmit={(e) => {
          e.preventDefault();
          props.onSubmit(search);
        }}>
        <InputBase
          onChange={(event) => setSearch(event.target.value)}
          value={search || ''}
          className={classes.searchInput}
          id="search"
          inputProps={{ 'aria-label': 'search' }}
        />
        <IconButton
          classes={{ root: classes.cancelIcon }}
          // className={classes.cancelIcon}
          aria-label="cancel"
          // type="submit"
          onClick={() => {
            setSearch(undefined);
            props.onSubmit(undefined);
          }}>
          <CloseIcon />
        </IconButton>
        <Divider orientation="vertical" flexItem />
        <IconButton
          type="submit"
          className={classes.searchIcon}
          aria-label="search">
          <SearchIcon />
        </IconButton>
      </form>
      {/* </FormControl> */}
      {/* </Box> */}
      <Box>
        <Button
          onClick={() => {
            navigate('/newevent');
          }}
          color="secondary"
          variant="contained"
          className={classes.newEventButton}>
          {authContext.type === 'superadmin'
            ? 'Créer un évènement'
            : 'Demande de nouvel évènement'}
        </Button>
      </Box>
      {/* </Toolbar> */}
      {/* </AppBar> */}
    </Box>
  );
};

const Events = () => {
  const store = useContext(StoreContext);
  const globalC = useContext(GlobalContext);

  const classes = useMainStyles();

  // States
  const [value, setValue] = useState(0);
  const [searchValue, setSearchValue] = useState(undefined);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const unsubscribe = store.loadEventRequests();
    const unsubscribe2 = store.loadUsers();
    const unsubscribe3 = store.loadRequests();

    globalC.setActiveMenu(0);

    return () => {
      unsubscribe && unsubscribe();
      unsubscribe2 && unsubscribe2();
      unsubscribe3 && unsubscribe3();
    };
  }, []);

  return (
    <div className={classes.main}>
      <Header
        onSubmit={(e) => {
          setSearchValue(e);
        }}
      />
      <div className={classes.body}>
        <Tabs
          scrollButtons="on"
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          classes={{ indicator: classes.eventsTabIndicator }}
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example">
          <Tab
            label={
              <Box>
                <Typography variant="caption" style={{ margin: 10 }}>
                  tous
                </Typography>
                <Chip
                  size="small"
                  label={
                    store.festivals.filter((e) => {
                      return searchValue
                        ? e.name
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                        : true;
                    }).length
                  }
                  className={classes.tabBadge}
                  color="secondary"
                />
              </Box>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Box>
                <Typography variant="caption" style={{ margin: 10 }}>
                  EN COURS
                </Typography>
                <Chip
                  size="small"
                  label={
                    store.festivals
                      .filter((e) => {
                        return searchValue
                          ? e.name
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          : true;
                      })
                      .filter(
                        (e) =>
                          ((e.end ? e.end.toDate() - new Date() : 20) /
                            (1000 * 60 * 60 * 24) >
                            0 &&
                            (e.start ? e.start.toDate() - new Date() : 10)) /
                            (1000 * 60 * 60 * 24) <
                          0,
                      ).length
                  }
                  className={classes.tabBadge}
                  color="secondary"
                />
              </Box>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Box>
                <Typography variant="caption" style={{ margin: 10 }}>
                  A VENIR
                </Typography>
                <Chip
                  size="small"
                  label={
                    store.festivals
                      .filter((e) => {
                        return searchValue
                          ? e.name
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          : true;
                      })
                      .filter(
                        (e) =>
                          Math.ceil(
                            (e.start ? e.start.toDate() - new Date() : 10) /
                              (1000 * 60 * 60 * 24),
                          ) > 0,
                      ).length
                  }
                  className={classes.tabBadge}
                  color="secondary"
                />
              </Box>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <Box>
                <Typography variant="caption" style={{ margin: 10 }}>
                  terminés
                </Typography>
                <Chip
                  size="small"
                  label={
                    store.festivals
                      .filter((e) => {
                        return searchValue
                          ? e.name
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          : true;
                      })
                      .filter(
                        (e) =>
                          (e.end ? e.end.toDate() - new Date() : 20) /
                            (1000 * 60 * 60 * 24) <
                          0,
                      ).length
                  }
                  className={classes.tabBadge}
                  color="secondary"
                />
              </Box>
            }
            {...a11yProps(3)}
          />

          <Tab
            label={
              <Box>
                <Typography variant="caption" style={{ margin: 10 }}>
                  en attente de confirmation
                </Typography>
                <Chip
                  size="small"
                  label={
                    store.eventRequests
                      .filter((e) => e.status === 'pending')
                      .filter((e) => {
                        return searchValue
                          ? e.name
                              .toLowerCase()
                              .includes(searchValue.toLowerCase())
                          : true;
                      }).length
                  }
                  className={classes.tabBadge}
                  color="secondary"
                />
              </Box>
            }
            {...a11yProps(4)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <EventsTab allEvent={true} search={searchValue} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EventsTab
            passedEvent={false}
            inprogressEvent={true}
            search={searchValue}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EventsTab passedEvent={false} search={searchValue} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <EventsTab passedEvent={true} search={searchValue} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <EventsTab pending={true} search={searchValue} />
        </TabPanel>
      </div>
    </div>
  );
};

export default Events;
