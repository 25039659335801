import React, { useState, useEffect, useContext } from 'react';
import firebase, { auth, db } from '../firebase';
// import {  Link } from 'react-router-dom';

import {withRouter} from "./Component/withrouter-wrapper";

import { Bars } from 'react-loader-spinner';

// Material UI Component
import { Container, Button, Typography, Box } from '@material-ui/core';

// import GitHubIcon from '@material-ui/icons/GitHub';

// Styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
import logo from '../img/bob-logo-v.png';
// import logo from '../img/bob.png';
import { useMainStyles } from './classes/main';

// Context
import { GlobalContext } from '../context/globalContext';
import { AuthContext } from '../context/authContext';
import { StoreContext } from '../context/storeContext';

const PendingConfirmation = () => {
  //Utile pour faire de la gestion de compte non Google
  /*const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");*/

  const [error, setErrors] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [isLoading, setLoading] = useState(false);

  const globalC = useContext(GlobalContext);
  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);

  // const classes = makeStyles(mainStyles)();
  const classes = useMainStyles();
  const theme = useTheme();

  useEffect(() => {
    setEmail('');
    setPassword('');
    setErrors('');
  }, []);

  useEffect(() => {
    setErrors('');
  }, [email, password]);

  return (
    <div className={classes.loginContainer}>
      {globalC.authLoading && (
        <Bars
          wrapperClass={classes.loader}
          // type="Bars"
          color={theme.palette.white}
          secondaryColor={theme.palette.secondary.main}
        />
      )}

      {!globalC.authLoading && (
        <div className={classes.loginContainer}>
          <div className={classes.bobLogo}>
            <img src={logo} alt="logo" height="81px" width="181px" />
          </div>
          <Container component="main" className={classes.login} maxWidth="xs">
            {/* <CssBaseline /> */}
            <div className={classes.inarow}>
              {authC?.userInfos?.emailVerified ? (
                <div>
                  {' '}
                  <Typography component="h5" variant="h5">
                    Votre compte est en cours d'activation par nos services.
                  </Typography>
                  <Button
                    className={classes.submit}
                    // variant="outlined"
                    onClick={() => {
                      firebase
                        .auth()
                        .signOut()
                        .then(() => {
                          authC.setIsAuth(false);
                          authC.setUsersInfos(null);
                          store.clear();
                          console.log('utilisateur déconnecté de firebase');
                        })
                        .catch((e) => console.error(e.message));
                    }}>
                    Déconnexion
                  </Button>
                </div>
              ) : (
                <div>
                  <Typography component="h5" variant="h5">
                    Veuillez confirmer votre adresse email en cliquant sur le
                    liens présent dans le mail de vérification.
                  </Typography>
                  <Box
                    display="flex"
                    width="100%"
                    mt={4}
                    justifyContent="space-around"
                    flexWrap="wrap"
                    flexDirection="row">
                    <Button
                      className={classes.providerButton}
                      variant="outlined"
                      onClick={() => {
                        var user = firebase.auth().currentUser;

                        user
                          .sendEmailVerification({
                            url:
                              process.env.REACT_APP_HOST ||
                              'http://localhost:3000',
                          })
                          .then(() => {
                            globalC.setSnack({
                              ...globalC.snack,
                              open: true,
                              message: 'Demande envoyée.',
                              color: 'success',
                            });
                            // firebase.auth().signOut();
                            // alert('Demande envoyée.');
                          })
                          .catch((err) => {
                            globalC.setSnack({
                              ...globalC.snack,
                              open: true,
                              message: err.message,
                              color: 'error',
                            });
                            // alert(err.message);
                            // firebase.auth().signOut();
                          });
                      }}>
                      Renvoyer le liens
                    </Button>
                    <Button
                      className={classes.submit}
                      // variant="outlined"
                      onClick={() => {
                        firebase
                          .auth()
                          .signOut()
                          .then(() => {
                            authC.setIsAuth(false);
                            authC.setUsersInfos(null);
                            store.clear();
                            console.log('utilisateur déconnecté de firebase');
                          })
                          .catch((e) => console.error(e.message));
                      }}>
                      Déconnexion
                    </Button>
                  </Box>
                </div>
              )}
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default withRouter(PendingConfirmation);
