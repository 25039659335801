import React, { useContext } from 'react';

import { AuthContext } from '../../context/authContext';
import { StoreContext } from '../../context/storeContext';

// Material Component
import { Box, Chip, Tabs, Tab, Typography } from '@material-ui/core';

import * as config from '../config/globalValues';

// Style
import { useMainStyles } from '../classes/main';
import clsx from 'clsx';

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const TableWithTabs = (props) => {
  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);

  const classes = useMainStyles();

  const stands = props.standFilter
    ? store.stands
        .filter((s) => s.festival === authC.selectedFestival)
        .filter(props.standFilter)
    : store.stands.filter((s) => s.festival === authC.selectedFestival);

  return (
    <Box mt={2}>
      <Tabs
        className={classes.tabsHeader}
        scrollButtons="on"
        variant="scrollable"
        indicatorColor="primary"
        textColor="primary"
        classes={{
          indicator: classes.tabIndicator,
          root: classes.tabsContent,
        }}
        value={props.selectedStand}
        onChange={(event, value) => {
          props.handleTabChange(event, value);
        }}>
        {stands?.map((stand, index) => (
          <Tab
            value={stand.id}
            key={stand.id}
            tabIndex={stand.id}
            label={
              <Box>
                <Typography variant="caption" style={{ margin: 10 }}>
                  {stand.shopName.substr(0, config.MAX_STAND_NAME)}
                </Typography>
                <Chip
                  size="small"
                  label={props.chip(stand.id)}
                  className={classes.tabBadge}
                  color="primary"
                />
              </Box>
            }
            //   label={stand.shopName}
            className={clsx(classes.tabElement, {
              [classes.tabElementSelected]: props.selectedStand === stand.id,
            })}
            {...a11yProps(stand.id)}
          />
        ))}
      </Tabs>
      {props.children}
    </Box>
  );
};
export default TableWithTabs;
