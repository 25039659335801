import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

// Initialize Firebase

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MESUREMENTID,
  });
}else {
  firebase.app(); // if already initialized, use that one
}

process.env.REACT_APP_FIRESTORE_ENV === 'development' &&
  firebase.firestore().settings({
    host: process.env.REACT_APP_FIRESTORE_HOST,
    ssl: JSON.parse(process.env.REACT_APP_FIRESTORE_SSL),
  });

  process.env.REACT_APP_FIRESTORE_ENV === 'development' && console.log("développement");
  
  const storage = firebase.storage();
  
  const db = firebase.firestore();
  // db.enablePersistence().catch((err) => console.err(err));
  const auth = firebase.auth();
  // process.env.REACT_APP_FIRESTORE_ENV === 'development' && auth.useEmulator(process.env.REACT_APP_FIREBASE_AUTH_HOST)

  const analytics = firebase.analytics();

export { auth, db, storage, analytics, firebase as default };
