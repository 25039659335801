// Material UI Component
import { Typography } from '@material-ui/core';

const Footer = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Bob'Min version {process.env.REACT_APP_VERSION}{' --- '}
      {'Copyright Bob 2019 - '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default Footer;
