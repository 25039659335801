import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { AuthContext } from '../../../context/authContext';
import { StoreContext } from '../../../context/storeContext';
import { GlobalContext } from '../../../context/globalContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import axios from 'axios';

import { useNavigate, useNavigationType, useLocation } from 'react-router-dom';

// Firebase
import { db } from '../../../firebase';

import { QRCode } from 'react-qrcode-logo';

import logo from '../../../img/bob-logo.png';

// import MaterialTable from 'material-table';
import StyledMaterialTable from '../../Component/StyledMaterialTable';

// Component
import TableWithTabs from '../../Component/TableWithTabs';
import HeaderPannel from '../../Component/HeaderPannel';

import { withRouter } from '../../Component/withrouter-wrapper';

// Material Component
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
// } from '@material-ui/core';

import {
  faNetworkWired,
  faUnlink,
  faTimes,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

library.add(faNetworkWired, faUnlink, faCheck, faTimes);
import { Delete } from '@mui/icons-material';

// Style
import { useTheme } from '@material-ui/core/styles';
import { useMainStyles } from '../../classes/main';
import PropTypes from 'prop-types';
function TabDevices(props) {
  const { children, value, index, ...other } = props;
  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);

  const classes = useMainStyles();

  const [qrcodeOpen, setQrcodeOpen] = useState(false);
  const [token, setToken] = useState('');

  const theme = useTheme();
  const getSecureToken = (uid, festival, cb) => {
    db.collection('devicesToken')
      .where('uid', '==', uid)
      .where('festival', '==', festival)
      .get()
      .then((res) => {
        if (res.docs.length > 0) {
          cb({ success: true, value: res.docs[0].id });
        } else {
          cb({
            success: true,
            err: 'No match',
          });
        }
      })
      .catch((err) => {
        cb({ success: false, error: err });
      });
  };
  const getRefreshToken = (uid, cb) => {
    db.collection('devicesToken')
      .doc(uid)
      .get()
      .then((doc) => {
        cb({ success: true, value: doc.data().refreshToken });
      })
      .catch((err) => {
        cb({ success: false, error: err });
      });
  };

  const deleteDevice = (id, cb) => {
    return axios
      .get(process.env.REACT_APP_BACKEND_URL + '/api/deleteDevice?id=' + id, {
        headers: {
          Authorization: `Bearer ${authC.token}`,
        },
      })
      .then((response) => {
        // console.log(response);
        cb(true);
      })
      .catch((err) => cb(false));
  };

  const unPairDevice = (id) => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + '/api/revokeDevice?uid=' + id, {
        headers: {
          Authorization: `Bearer ${authC.token}`,
        },
      })
      .then((response) => {
        // console.log(response);

        store.setLoading(false);
      })
      .catch((err) => {
        store.setLoading(false);
      });
  };

  const updateDevice = (id, admin, stand, name, stats) => {
    const s = store.stands.find(
      (s) => s.festival === authC.selectedFestival && s.id === stand,
    );
    db.collection('devices')
      .doc(id)
      .update({ type: admin ? 'multi' : s.type, stand, name, stats })
      .then(() => {
        store.setLoading(false);
      })
      .catch();
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      <Modal
        className={classes.modal}
        open={qrcodeOpen}
        onClose={() => setQrcodeOpen(false)}>
        <Box className={classes.modalContent}>
          <Typography>ASSOCIER UN TERMINAL</Typography>
          {token ? (
            <QRCode
              value={token}
              logoImage={logo}
              logoWidth="40"
              removeQrCodeBehindLogo={true}
              size="200"
              fgColor={theme.palette.primary.main}
              bgColor="white"
            />
          ) : (
            'No QR Code'
          )}
          <img alt="Notice" />
          <Button
            className={classes.closeModal}
            onClick={() => {
              setQrcodeOpen(false);
            }}>
            FERMER
          </Button>
        </Box>
      </Modal>
      {value === index && (
        <StyledMaterialTable
          title={null}
          style={{
            borderRadius: 15,
            boxShadow: 'none',
            border: '2px solid',
            color: theme.palette.primary.main,
            top: '-2px',
            // zIndex: 1,
          }}
          columns={[
            { title: 'ID', field: 'id', hidden: true },
            { title: 'Festival', field: 'festival', hidden: true },
            {
              title: 'Etat',
              field: 'paired',
              editable: 'never',
              render: (rowData) => (
                <FontAwesomeIcon
                  icon={rowData.paired ? 'network-wired' : 'unlink'}
                  color={
                    rowData.paired
                      ? theme.palette.success.main
                      : rowData.disabled
                      ? theme.palette.grey.main
                      : theme.palette.danger
                  }
                />
              ),
            },
            { title: 'Etat', field: 'disabled', hidden: true },
            { title: "Nom de l'appareil", field: 'name' },
            {
              title: 'Accès admin',
              field: 'type',
              type: 'boolean',
              render: (rowData) => (
                <FontAwesomeIcon
                  icon={rowData.type ? 'check' : 'times'}
                  color={
                    rowData.type
                      ? theme.palette.success.main
                      : theme.palette.danger
                  }
                />
              ),
            },
            {
              title: 'Accès aux stats',
              type: 'boolean',
              field: 'stats',
              render: (rowData) => (
                <FontAwesomeIcon
                  icon={rowData.stats ? 'check' : 'times'}
                  color={
                    rowData.stats
                      ? theme.palette.success.main
                      : theme.palette.danger
                  }
                />
              ),
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            padding: 'dense',
            pageSize: 5,
            pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
            // columnsButton: true,
            // tableLayout: 'fixed'
          }}
          data={store.devices
            .filter(
              (device) =>
                device.festival === authC.selectedFestival &&
                device.stand === value &&
                device.disabled !== true,
            )
            .map((e) => ({ ...e, type: e.type === 'multi' ? true : false }))}
          actions={[
            {
              icon: () => (
                <Box className={classes.secondaryButton}>Associer</Box>
                // <Button variant="outlined" className={classes.secondaryButton}>
                //   Associer
                // </Button>
              ),
              onClick: (event, rowData) => {
                store.setLoading(true);

                // setToken(rowData.id);
                // setQrcodeOpen(true);
                // store.setLoading(false);

                getSecureToken(rowData.id, authC.selectedFestival, (res) => {
                  if (res.success) {
                    setToken(res.value);
                    setQrcodeOpen(true);
                    store.setLoading(false);
                  } else {
                    setToken(null);
                    setQrcodeOpen(true);
                    store.setLoading(false);
                  }
                });
                // getRefreshToken(rowData.id, (res) => {
                //   // console.log(res);
                //   if (res.success) {
                //     setToken(res.value);
                //     setQrcodeOpen(true);
                //     store.setLoading(false);
                //   } else {
                //     setToken(null);
                //     setQrcodeOpen(true);
                //     store.setLoading(false);
                //   }
                // });
              },
            },
            {
              icon: () => (
                <Box className={classes.primaryButton}>Dissocier</Box>
              ),
              // <Button variant="outlined" className={classes.primaryButton}>
              //   Dissocier
              // </Button>
              onClick: (event, rowData) => {
                store.setLoading(true);
                rowData.paired === true
                  ? unPairDevice(rowData.id)
                  : store.setLoading(false);
              },
            },
            // {
            //   icon: () => <Edit />,
            //   tooltip: 'Modifier',
            //   onClick: (event, rowData) => {},
            // },
            {
              icon: () => <Delete />,
              tooltip: 'Supprimer',
              onClick: (event, rowData) => {
                store.setLoading(true);
                deleteDevice(rowData.id, () => {
                  store.setLoading(false);
                });
              },
            },
          ]}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  // console.log(newData.name, newData.stats, newData.type);
                  updateDevice(
                    newData.id,
                    newData.type,
                    oldData.stand,
                    newData.name,
                    newData.stats,
                  );
                  resolve();
                }, 1000);
              }),
          }}
        />
      )}
    </div>
  );
}

TabDevices.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Devices = (props) => {
  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);
  const globalC = useContext(GlobalContext);

  const classes = useMainStyles();

  // States
  const [name, setName] = useState(undefined);

  const [admin, setAdmin] = useState(false);
  const [statsA, setStatsA] = useState(false);

  const location = useLocation();
  const navigationType = useNavigationType();

  const [selectedStand, setSelectedStand] = useState(
    location.state && location.state.fromEvent && navigationType === 'PUSH'
      ? location.state.standId
      : store?.stands?.filter((s) => s.festival === authC.selectedFestival)[0]
          ?.id || '',
  );

  const resetSelectedStand = () => {
    setSelectedStand(
      store?.stands?.filter((s) => s.festival === authC.selectedFestival)[0]
        ?.id || '',
    );
  };

  useEffect(() => {
    const unsubscribe = store.loadStands();
    const unsubscribe2 = store.loadItems();
    const unsubscribe3 = store.loadDevices();
    globalC.setActiveMenu(3);

    return () => {
      unsubscribe && unsubscribe();
      unsubscribe2 && unsubscribe2();
      unsubscribe3 && unsubscribe3();
    };
  }, []);

  useEffect(() => {
    resetSelectedStand();
  }, [authC.selectedFestival]);

  useEffect(() => {
    if (
      location.state &&
      location.state.fromEvent &&
      navigationType === 'PUSH'
    ) {
      setSelectedStand(location.state.standId);
    } else {
      setSelectedStand(
        store?.stands?.filter((s) => s.festival === authC.selectedFestival)[0]
          ?.id || '',
      );
    }
  }, [store.stands]);

  const handleTabChange = (event, newValue) => {
    setSelectedStand(newValue);
  };

  const addDevice = (type, stand, name) => {
    store.setLoading(true);

    axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          '/api/newDevice?type=' +
          type +
          '&stand=' +
          stand +
          '&name=' +
          name +
          '&festival=' +
          authC.selectedFestival +
          '&stats=' +
          statsA,
        {
          headers: {
            Authorization: `Bearer ${authC.token}`,
          },
        },
      )
      .then((response) => {
        globalC.setSnack({
          ...globalC.snack,
          open: true,
          message: 'Terminal créé.',
          color: 'success',
        });
        setName(undefined);
        setAdmin(false);
        setStatsA(false);
        store.setLoading(false);
      })
      .catch((err) => {
        globalC.setSnack({
          ...globalC.snack,
          open: true,
          message: err.message,
          color: 'error',
        });
        store.setLoading(false);
      });
  };

  return (
    <div className={classes.main}>
      <HeaderPannel />
      <div className={classes.body}>
        <Typography variant="subtitle1" className={classes.title} mt={2}>
          AJOUTER UN APPAREIL
        </Typography>
        <Divider className={classes.horizontalDivider} />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          className={classes.addMenu}
          width="100%"
          mt={2}>
          <Box m={2}>
            <TextField
              error={name === ''}
              onChange={(e) => setName(e.target.value)}
              value={name || ''}
              // variant="outlined"
              label="Nom de l'appareil *"
              helperText="* obligatoire"
            />
          </Box>
          <Box m={2} display="flex" flexDirection="column">
            <FormControlLabel
              control={
                <Checkbox
                  checked={statsA}
                  onChange={() => setStatsA(!statsA)}
                />
              }
              label="Accès aux statistiques depuis l'application mobile"
            />
            <FormControlLabel
              control={
                <Checkbox checked={admin} onChange={() => setAdmin(!admin)} />
              }
              label="Accès aux fonctions d'administration"
            />
          </Box>
          <Box m={2}>
            <Button
              disabled={globalC.progress != 0 || !name || name === ''}
              variant="contained"
              color="secondary"
              style={{ margin: 5 }}
              onClick={() => {
                const s = store.stands.find(
                  (s) =>
                    s.festival === authC.selectedFestival &&
                    s.id === selectedStand,
                );
                addDevice(admin ? 'multi' : s.type, s.id, name);
              }}>
              Ajouter
            </Button>
          </Box>
        </Box>
        <Typography variant="subtitle1" className={classes.title} mt={2}>
          GERER MES APPAREILS
        </Typography>
        <Divider className={classes.horizontalDivider} />
        <TableWithTabs
          selectedStand={selectedStand}
          handleTabChange={handleTabChange}
          chip={(standId) => {
            return store.devices.filter(
              (device) =>
                device.festival === authC.selectedFestival &&
                device.stand === standId &&
                device.disabled != true,
            ).length;
          }}>
          <TabDevices
            value={selectedStand || ''}
            index={selectedStand || ''}
            key={selectedStand || ''}
          />
        </TableWithTabs>
      </div>
    </div>
  );
};

export default withRouter(Devices);
