import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../context/authContext';
import { StoreContext } from '../../../context/storeContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import StyledMaterialTable from '../../Component/StyledMaterialTable';

// @nivo component

import { ResponsiveBar } from '@nivo/bar';
import CanvasJSReact from '@canvasjs/react-charts';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


import { utcFormat } from 'd3-time-format';

import { ResponsivePie } from '@nivo/pie';
import moment from 'moment';
import _ from 'lodash';
import {
  Typography,
  Box,
  Switch,
  useTheme,
  ButtonGroup,
  Button,
  TextField,
} from '@material-ui/core';

const timestampFormat = utcFormat('%d-%m-%Y %H:%M:%S.%L');

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// Styles
import { useMainStyles } from '../../classes/main';
import clsx from 'clsx';
import theme from '../../themes/default';

const GenericCard = (props) => {
  const classes = useMainStyles();

  return (
    <Box
      boxShadow={2}
      width={props.width}
      height={props.height}
      className={classes.genericCard}>
      {props.children}
    </Box>
  );
};

const ByItemsCard = (props) => {
  const store = useContext(StoreContext);
  const classes = useMainStyles();

  const [checked, setChecked] = useState(false);
  const [size, setSize] = useState('md');

  return (
    <GenericCard
      width={
        size === 'sm'
          ? '400px'
          : size === 'md'
          ? '600px'
          : size === 'lg'
          ? '1200px'
          : '400px'
      }
      // height="400px"
    >
      <Box height="400px" margin="10px">
        {/* height="400px"> */}
        <Box
          display="flex"
          height="30%"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center">
          <Typography
            variant="h6"
            style={{
              // flexGrow: '1',
              textAlign: 'center',
              // paddingTop: '50px',
            }}>
            {props.standName}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between">
            <ButtonGroup style={{ flexGrow: 1 }}>
              <Button
                className={clsx(classes.buttonGroup, {
                  [classes.buttonGroupSelected]: size === 'sm',
                })}
                onClick={() => setSize('sm')}>
                sm
              </Button>
              <Button
                className={clsx(classes.buttonGroup, {
                  [classes.buttonGroupSelected]: size === 'md',
                })}
                onClick={() => setSize('md')}>
                md
              </Button>
              <Button
                className={clsx(classes.buttonGroup, {
                  [classes.buttonGroupSelected]: size === 'lg',
                })}
                onClick={() => setSize('lg')}>
                lg
              </Button>
            </ButtonGroup>
            Quantités
            <Switch
              checked={checked}
              onChange={() => setChecked(!checked)}
              name="checked"
            />
            Chiffre d'affaire (en €)
          </Box>
        </Box>
        <Box display="flex" height="70%">
          <ResponsiveBar
            data={store.byItemsCommands
              .filter((i) => {
                // console.log(i);
                return i.stand === props.standId;
              })
              .sort((a, b) => (checked ? b.ca - a.ca : b.qty - a.qty))}
            indexBy="key"
            keys={checked ? ['ca'] : ['qty']}
            animate={true}
            // motionStiffness={90}
            // motionDamping={15}
            margin={{ top: 0, right: 50, bottom: 80, left: 60 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colorBy="index"
            colors={{ scheme: 'pastel1' }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            round={false}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 30,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: checked ? "Chiffre d'affaire (en €)" : 'Quantité',
              legendPosition: 'middle',
              legendOffset: -50,
            }}
            label="value"
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            borderRadius={7}
            tooltip={(d) => {
              return (
                <Box
                  boxShadow={5}
                  style={{
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: '10px',
                    color: 'white',
                  }}
                  p={2}>
                  <Typography>
                    {d.data.key} : {d.data.qty}
                  </Typography>
                </Box>
              );
            }}
            // key={store.items
            //   .filter((i) => i.stand === globalC.selectedStand)
            //   .map((i) => i.name)}
          />
        </Box>
      </Box>
      {/* </div> */}
    </GenericCard>
  );
};

const DonutPie = (props) => {
  const theme = useTheme();

  return (
    <ResponsivePie
      theme={{
        fontSize: 15,
      }}
      data={props.data}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      innerRadius={0.8}
      padAngle={3}
      cornerRadius={10}
      colors={{ scheme: 'pastel2' }}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      enableArcLinkLabels={false}
      tooltip={(d) => {
        return (
          <Box
            boxShadow={5}
            style={{
              backgroundColor: theme.palette.primary.light,
              borderRadius: '10px',
              color: 'white',
            }}
            p={2}>
            <Typography>
              {d.datum.label} : {d.datum.value}
            </Typography>
          </Box>
        );
      }}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      legends={[
        {
          anchor: 'top',
          direction: 'row',
          translateY: -25,
          itemWidth: 150,
          itemHeight: 18,
          itemTextColor: '#999',
          symbolSize: 18,
          symbolShape: 'circle',
        },
      ]}
    />
  );
};

const StatsTab = () => {
  const authContext = useContext(AuthContext);
  const store = useContext(StoreContext);

  const classes = useMainStyles();

  const [badgeDataPie, setBadgeDataPie] = useState([]);
  // const [creditTimeline2, setCreditTimeline2] = useState([]);
  const [commandeTimeline2, setCommandeTimeline2] = useState([]);

  const [checkedMinutes, setCheckedMinutes] = useState(true);

  const [draggedItems, setDraggedItems] = useState(
    store.stands.filter((s) => s.festival === authContext.selectedFestival),
  );

  // First load
  useEffect(() => {
    if (authContext.selectedFestival) {
      const unsubscribe = store.loadBadges();
      const unsubscribe2 = store.loadOldBadges();
      const unsubscribe3 = store.loadCommandesList();
      const unsubscribe4 = store.loadCreditList();

      setDraggedItems(store.stands.filter((s) => s.festival === authContext.selectedFestival))

      return () => {
        unsubscribe && unsubscribe();
        unsubscribe2 && unsubscribe2();
        unsubscribe3 && unsubscribe3();
        unsubscribe4 && unsubscribe4();
      };
    }
  }, [authContext.selectedFestival]);

  // Update badgeDataPie when badgelist updates
  useEffect(() => {
    setBadgeDataPie([
      {
        id: 'activated',
        label: 'Badges actifs',
        value: store?.badgeList?.filter(
          (s) => s.festival === authContext.selectedFestival,
        ).length,
        color: '#00f',
      },
      {
        id: 'disabled',
        label: 'Badges désactivés',
        value: store?.oldBadgeList?.filter(
          (s) => s.festival === authContext.selectedFestival,
        ).length,
        color: '#f00',
      },
    ]);
  }, [authContext.selectedFestival, store.badgeList, store.oldBadgeList]);

  useEffect(() => {
    let groupBy = _.groupBy(
      store.commandeTimeline.filter(
        (s) => s.festival === authContext.selectedFestival,
      ),
      (result) =>
        moment(result['x'], 'DD-MM-YYYY HH:mm:ss')
          .startOf(checkedMinutes ? 'hour' : 'minute')
          .format('DD-MM-YYYY HH:mm:ss.SSS'),
    );

    const newSet = [];
    for (let [key, value] of Object.entries(groupBy)) {
      // console.log(moment(key, 'DD-MM-YYYY HH:mm:ss').unix());
      newSet.push({
        x: moment(key, 'DD-MM-YYYY HH:mm:ss').valueOf(),
        y: value.reduce((x, y) => x + y.y, 0),
      });
    }
    setCommandeTimeline2(newSet);
  }, [authContext.selectedFestival, store.commandeTimeline, checkedMinutes]);

  useEffect(() => {
    if (authContext.selectedFestival) {
      store.setFromDate(
        store.festivals
          .find((e) => e.id === authContext.selectedFestival)
          ?.start?.toDate(),
      );
      store.setToDate(
        store.festivals
          .find((e) => e.id === authContext.selectedFestival)
          ?.end?.toDate(),
      );
    }
  }, []);

  const options = {
    theme: 'light2', // "light1", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
    title: {
      text: 'Commandes',
    },
    data: [
      {
        type: 'area',
        xValueType: 'dateTime',
        dataPoints: commandeTimeline2,
      },
    ],
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      draggedItems,
      result.source.index,
      result.destination.index,
    );

    setDraggedItems(items);
  };

  return (
    <div className={classes.main}>

      <Box
        display="flex"
        flexDirection="row"
        alignSelf="flex-start"
        // width="100%"
        p={2}
        style={{
          marginTop: '2px',
          backgroundColor: theme.palette.white,
          border: '1px solid',
          borderColor: theme.palette.primary.light,
          borderRadius: 10,
        }}>
        <Box display="flex" alignSelf="center" style={{ marginRight: 50 }}>
          <Typography variant="h6">Période</Typography>
        </Box>
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignSelf="flex-end">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              style={{ margin: 5 }}
              inputVariant="outlined"
              ampm={false}
              format="yyyy/MM/dd HH:mm"
              id="date-begin-picker-inline"
              label="Début"
              value={store.fromDate}
              clearable
              onChange={(e) => store.setFromDate(e)}
            />
            <DateTimePicker
              style={{ margin: 5 }}
              inputVariant="outlined"
              format="yyyy/MM/dd HH:mm"
              id="date-end-picker-inline"
              label="Fin"
              value={store.toDate}
              onChange={(e) => store.setToDate(e)}
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <Box className={classes.eventsTab}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-1" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{ display: 'flex', flexWrap: 'wrap' }}
                {...provided.droppableProps}>
                {draggedItems.map((s, index) => {
                  return (
                    <Draggable
                      key={'draggable-' + s.id}
                      draggableId={'draggable-' + s.id}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          <ByItemsCard
                            key={s.id}
                            standId={s.id}
                            standName={s.shopName}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <GenericCard height="400px" width="400px">
          <DonutPie data={badgeDataPie} />
        </GenericCard>
      </Box>
      <div style={{ height: '500px',width: '80%' }}>
        Groupés par : Minutes
        <Switch
          checked={checkedMinutes}
          onChange={() => setCheckedMinutes(!checkedMinutes)}
          name="checkedA"
        />
        Heures
        <CanvasJSChart
          options={options}
          /* onRef={ref => this.chart = ref} */
        />
      </div>
      <StyledMaterialTable
        columns={[
          { title: 'Festival', field: 'festival', hidden: true },
          { title: 'ID', field: 'id' },
          { title: 'Date de création', field: 'creationDate' },
          { title: 'Date de Suppression', field: 'deletionDate' },
          { title: 'badge ID', field: 'badgeId' },
          { title: 'email', field: 'email' },
          { title: 'Solde', field: 'balance' },
          { title: 'Solde Dons', field: 'balance_gifts' },
          { title: 'Majeur', field: 'major' },
          { title: 'Activé', field: 'active' },
          { title: 'Type de badge', field: 'type' },
        ]}
        options={{
          exportButton: true,
          exportAllData: true,
          exportDelimiter: ';',
        }}
        data={[
          ...store.badgeList
            .filter((s) => s.festival === authContext.selectedFestival)
            .map((e) => {
              return {
                ...e,
                creationDate: timestampFormat(e.creationDate.toDate()),
                type: e.type?.type,
                active: true,
              };
            }),
          ...store.oldBadgeList
            .filter((s) => s.festival === authContext.selectedFestival)
            .map((e) => {
              return {
                ...e,
                creationDate: timestampFormat(e.creationDate.toDate()),
                deletionDate: timestampFormat(e.deletionDate.toDate()),
                type: e.type?.type,
                active: false,
              };
            }),
        ]}
        title="Badges"
      />
    </div>
  );
};

export default StatsTab;
