import React from 'react';
import Login from '../view/Login';
import Register from '../view/Register';

import { Navigate } from 'react-router-dom';
const routes = [
  {
    name: 'home',
    path: '/',
    exact: true,
    main: () => <Navigate replace to="/Events" />,
  },
  { name: 'Se connecter', path: '/Login', exact: true, main: () => <Login /> },
  {
    name: "S'enregistrer",
    path: '/Register',
    exact: true,
    main: () => <Register />,
  },
  {
    main: () => <Navigate replace to="/Login" />,
    key:'redirect'
  },
];

export default routes;
