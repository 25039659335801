import React, { useState, useEffect, useContext } from 'react';
import firebase, { analytics, auth } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';

import { Bars } from 'react-loader-spinner';
import LogRocket from 'logrocket';

// Material UI Component
import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core';

import { Box } from '@mui/material';

import GitHubIcon from '@material-ui/icons/GitHub';

// Context
import { GlobalContext } from '../context/globalContext';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import logo from '../img/bob-logo-v.png';
// import logo from '../img/bob.png';
import { useMainStyles } from './classes/main';
import { withRouter } from './Component/withrouter-wrapper';

LogRocket.init('bob/bob');

const Login = () => {
  const [error, setErrors] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const globalC = useContext(GlobalContext);

  const classes = useMainStyles();

  const theme = useTheme();

  let navigate = useNavigate();

  const signInWithLogin = () => {
    globalC.setAuthLoading(true);

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // console.log(userCredential);
        analytics.logEvent('signin_with_login');
      })
      .catch((error) => {
        console.log(error);
        // alert(error.message);
        globalC.setSnack({
          ...globalC.snack,
          open: true,
          message: 'Email inconnu ou mauvais mot de passe',
          color: 'error',
        });
        // alert('Impossible de se connecter avec ce login / password');
        setErrors(error.message);
        globalC.setAuthLoading(false);
      });
  };

  const signInWithProvider = (providerName) => {
    globalC.setAuthLoading(true);
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        const provider =
          providerName === 'google'
            ? new firebase.auth.GoogleAuthProvider()
            : providerName === 'github'
            ? new firebase.auth.GithubAuthProvider()
            : console.log('provider not available');
        // providerName === 'google' &&
        provider.setCustomParameters({ prompt: 'select_account' });

        firebase
          .auth()
          .signInWithPopup(provider)
          .then((result) => {
            // console.log('connected');
            // console.log(result);
            analytics.logEvent('signin_with_provider');

            // globalC.setAuthLoading(false);
          })
          .catch((error) => {
            console.log('signIn error');
            console.log(error);
            globalC.setSnack({
              ...globalC.snack,
              open: true,
              message: 'Impossible de se connecter avec ce fournisseur',
              color: 'error',
            });
            // alert('impossible de se connecter avec ce fournisseur');
            // alert(error.message);
            setErrors(error.message);
            globalC.setAuthLoading(false);
          });
      })
      .catch((error) => {
        console.log(error.message);
        globalC.setAuthLoading(false);
      });
  };

  useEffect(() => {
    setEmail('');
    setPassword('');
    setErrors('');
  }, []);

  useEffect(() => {
    setErrors('');
  }, [email, password]);

  return (
    <div className={classes.loginContainer}>
      {globalC.authLoading && (
        <Bars
          wrapperClass={classes.loader}
          // type="Bars"
          color={theme.palette.primary.light}
          secondaryColor={theme.palette.secondary.main}
        />
      )}

      {!globalC.authLoading && (
        <Container component="main" className={classes.login} maxWidth="xs">
          <Box>
            <Grid item xs justifyContent="flex-end" style={{ display: 'flex' }}>
              {/* <Link to="/Register" variant="body2" className={classes.link}>
                S'enregistrer
              </Link> */}
              <Button
                className={classes.smallButton}
                onClick={() => navigate('/Register')}
                fullWidth
                variant="contained">
                Sign in
              </Button>
            </Grid>
            <div className={classes.inarow}>
              <div className={classes.bobLogo}>
                <img src={logo} alt="logo" height="81px" width="181px" />
              </div>
            </div>
          </Box>
          <Box>
            <form
              // Use this for register
              // .createUserWithEmailAndPassword(email, password)
              className={classes.form}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                signInWithLogin();
              }}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="email"
                label="Email"
                name="email"
                value={email}
                autoComplete="email"
                InputLabelProps={{ className: classes.input }}
                InputProps={{ className: classes.input }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                value={password}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                InputLabelProps={{ className: classes.input }}
                InputProps={{ className: classes.input }}
                onChange={(e) => setPassword(e.target.value)}
              />

              <Grid
                item
                xs
                justifyContent="flex-end"
                style={{ display: 'flex', marginBottom: '10px' }}>
                <Link to="/Login" variant="body2" className={classes.link}>
                  Forgot password ?
                </Link>
              </Grid>
              <Button
                className={classes.submit}
                focusVisibleClassName={classes.submitOverflow}
                type="submit"
                fullWidth
                variant="contained">
                Login
              </Button>
              <Grid className={classes.error} item xs>
                {error}
              </Grid>
            </form>
            {/* <Typography
              component="h2"
              variant="h5"
              style={{
                color: theme.palette.primary.main,
                justifyContent: 'center',
                display: 'flex',
              }}>
              ou
            </Typography>{' '} */}
            <Divider className={classes.horizontalDivider} />

            <div className={classes.logoinarow}>
              <Button
                variant="outlined"
                className={classes.providerButton}
                onClick={() => signInWithProvider('google')}>
                <Avatar
                  className={classes.medium}
                  src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                  alt="Glogo"
                />
                Google login
              </Button>
              <Button
                variant="outlined"
                className={classes.providerButton}
                onClick={() => signInWithProvider('github')}>
                <GitHubIcon className={classes.medium} />
                Github login
              </Button>
            </div>
          </Box>
        </Container>
        // </div>
      )}
    </div>
  );
};

// export default withRouter(Login);
export default withRouter(Login);
