import React, { createContext, useState, useContext, useEffect } from 'react';

import { AuthContext } from './authContext';
import { GlobalContext } from './globalContext';

import { db } from '../firebase';

import { utcFormat } from 'd3-time-format';
// import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';
import { first } from 'lodash';

const timestampFormat = utcFormat('%d-%m-%Y %H:%M:%S.%L');

export const StoreContext = createContext();

const StoreContextProvider = (props) => {
  const [festivals, setFestivals] = useState([]);
  const [stands, setStands] = useState([]);
  const [devices, setDevices] = useState([]);
  const [items, setItems] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [badgeList, setBadgeList] = useState([]);
  const [oldBadgeList, setOldBadgeList] = useState([]);
  const [commandes, setCommandes] = useState([]);
  const [byItemsCommands, setByItemsCommands] = useState([]);
  const [commandeTimeline, setCommandeTimeline] = useState([]);
  const [creditTimeline, setCreditTimeline] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [requests, setRequests] = useState([]);
  const [eventRequests, setEventRequests] = useState([]);
  //const [majorDefault, setMajorDefault] = useState(false);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [firstLoad, setFirstLoad] = useState({
    events: false,
    devices: false,
    stands: false,
    badges: false,
    oldBadges: false,
    users: false,
    items: false,
    categories: false,
  });

  const authContext = useContext(AuthContext);
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    console.log('useEffect load festivals');

    if (authContext.isLoggedIn) {
      loadFestival();
    }
  }, [authContext.isLoggedIn]);

  useEffect(() => {
    console.log('useEffect set Dates');

    setFromDate(
      festivals
        .find((e) => e.id === authContext.selectedFestival)
        ?.start?.toDate(),
    );
    setToDate(
      festivals
        .find((e) => e.id === authContext.selectedFestival)
        ?.end?.toDate(),
    );

    const cleanup = () => {
      // return globalContext.setSelectedStandIndex(0);
      // return globalContext.setSelectedShop(
      //   stands.filter((s) => s.festival === authContext.selectedFestival)[0]
      //     ? stands.filter((s) => s.festival === authContext.selectedFestival)[0]
      //         .id
      //     : '',
      // );
    };
    return () => cleanup();
  }, [authContext.selectedFestival]);

  useEffect(() => {
    console.log('useEffect loadCommandList');
    const unsubscribe = loadCommandesList();

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [fromDate, toDate]);

  const setLoading = (loading) => {
    loading ? globalContext.setProgress(30) : globalContext.setProgress(100);
  };

  const loadFestival = () => {
    console.log('load festival authContext');
    if (
      (authContext.festivals && authContext.festivals.length > 0) ||
      authContext.type === 'superadmin'
    ) {
      let query = db.collection('festivals');

      if (authContext.type !== 'superadmin') {
        query = query.where('festival', 'in', authContext.festivals);
      }

      return query.onSnapshot((querySnapshot) => {
        const festivals = querySnapshot.docs
          .sort((a, b) => a.data().name.localeCompare(b.data().name))
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

        setFestivals(festivals);
        

        authContext.setSelectedFestival(
          localStorage.getItem('currentEvent') !== 'null'
            ? localStorage.getItem('currentEvent')
            : festivals[0]?.id,
        );
        setFirstLoad({ ...firstLoad, events: true });
      });
    } else return undefined;
  };

  const loadCategories = () => {
    console.log('load categories authContext');
    if (
      (authContext.festivals && authContext.festivals.length > 0) ||
      authContext.type === 'superadmin'
    ) {
      let query = db.collection('categories');
      if (authContext.type !== 'superadmin') {
        query = query.where('event', 'in', authContext.festivals);
      }

      return query.onSnapshot((querySnapshot) => {
        const categories = querySnapshot.docs
          .sort((a, b) => a.data().name.localeCompare(b.data().name))
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

        setCategories(categories);

        /*authContext.setSelectedFestival(
          localStorage.getItem('currentEvent') !== 'null'
            ? localStorage.getItem('currentEvent')
            : festivals[0]?.id,
        );*/
        setFirstLoad({ ...firstLoad, categories: true });
      });
    } else return undefined;
  };

  const loadUsers = () => {
    console.log("load users");
    setFirstLoad({ ...firstLoad, users: true });
    if (authContext.type === 'superadmin') {
      let query = db.collection('accounts');

      return query.onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((doc) => {
        });
        const users = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });

        setUsers(users);
      });
    }
  };
  const loadBadges = () => {
    setFirstLoad({ ...firstLoad, badges: true });
    if (
      (authContext.festivals && authContext.festivals.length > 0) ||
      authContext.type === 'superadmin'
    ) {
      let query = db.collection('badge');

      if (authContext.type !== 'superadmin') {
        query = query.where('festival', 'in', authContext.festivals);
      }

      return query.onSnapshot((querySnapshot) => {
        const badges = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          badgeId: doc.data().id,
        }));

        setBadgeList(badges);
      });
    }
  };
  const loadOldBadges = () => {
    setFirstLoad({ ...firstLoad, oldbadges: true });
    if (
      (authContext.festivals && authContext.festivals.length > 0) ||
      authContext.type === 'superadmin'
    ) {
      let query = db.collection('old_badge');

      if (authContext.type !== 'superadmin') {
        query = query.where('festival', 'in', authContext.festivals);
      }

      return query.onSnapshot((querySnapshot) => {
        const oldbadges = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          badgeId: doc.data().id,
        }));

        setOldBadgeList(oldbadges);
      });
    }
  };
  const loadStands = () => {
    setFirstLoad({ ...firstLoad, stands: true });
    if (
      (authContext.festivals && authContext.festivals.length > 0) ||
      authContext.type === 'superadmin'
    ) {
      let query = db.collection('stands').where('disabled', '!=', true);

      if (authContext.type !== 'superadmin') {
        query = query.where('festival', 'in', authContext.festivals);
      }

      return query.onSnapshot((querySnapshot) => {
        const stands = querySnapshot.docs
          .sort((a, b) => a.data().shopName.localeCompare(b.data().shopName))
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

        setStands(stands);
       
      });
    }
  };
  const loadCreditList = () => {
    if (
      (authContext.festivals && authContext.festivals.length > 0) ||
      authContext.type === 'superadmin'
    ) {
      let start = new Date();
      start.setHours(start.getHours() - 4);
      let query = db.collection('transactionsCredit');

      if (authContext.type !== 'superadmin') {
        query = query.where('festival', '==', authContext.selectedFestival);
      }
      query = query.where('timestamp', '>=', start).orderBy('timestamp', 'asc');

      return query.onSnapshot((querySnapshot) => {
        const credits = querySnapshot.docs.map((doc) => ({
          festival: doc.data().festival,
          x: timestampFormat(doc.data().timestamp.toDate()),
          y: isNaN(doc.data().amount) ? 0 : doc.data().amount,
        }));
        setCreditTimeline(credits);
      });
    }
  };
  const loadCommandesList = () => {
    if (
      (authContext.festivals && authContext.festivals.length > 0) ||
      authContext.type === 'superadmin'
    ) {
      let start = new Date();
      start.setHours(start.getHours() - 4);

      let query = db.collection('transactionsDebit');

      // if (authContext.type !== 'superadmin') {
      query = query.where('festival', '==', authContext.selectedFestival);
      // }
      // if (festivals.find((e) => e.id === authContext.selectedFestival)?.start) {
      //   query = query
      //     .where(
      //       'timestamp',
      //       '>=',
      //       festivals
      //         .find((e) => e.id === authContext.selectedFestival)
      //         .start?.toDate(),
      //     )
      //     .where(
      //       'timestamp',
      //       '<=',
      //       festivals
      //         .find((e) => e.id === authContext.selectedFestival)
      //         .end?.toDate(),
      //     );
      // }

      query = query.orderBy('timestamp', 'asc');

      return query.onSnapshot((querySnapshot) => {
        const commandes = querySnapshot.docs.map((doc) => ({
          festival: doc.data().festival,
          x: timestampFormat(doc.data().timestamp.toDate()),
          y: isNaN(doc.data().amount) ? 0 : doc.data().amount,
        }));

        setCommandeTimeline(commandes);

        const allCommandes = [];

        // convert commandes with multiple items to array with one row = one item
        querySnapshot.docs.forEach((doc) => {
          doc.data().items.forEach((c) => {
            allCommandes.push({
              ...doc.data(),
              items: null,
              itemId: c.id,
              qty: c.nb,
              pu: c.price,
            });
          });
        });

        setByItemsCommands(
          items.map((i) => {
            return {
              key: i.name,
              stand: i.stand,
              qty: allCommandes
                .filter(
                  (c) =>
                    c.itemId === i.id &&
                    c.timestamp.toDate() - fromDate > 0 &&
                    c.timestamp.toDate() - toDate < 0,
                )
                .reduce((a, c) => a + c.qty, 0),
              ca: allCommandes
                .filter((c) => c.itemId === i.id)
                .reduce((a, c) => a + c.pu * c.qty, 0),
              // values: allCommandes.filter((c) => c.itemId === i.id),
            };
          }),
        );
      });
    }
  };
  const loadItems = () => {
    setFirstLoad({ ...firstLoad, items: true });
    if (
      (authContext.festivals && authContext.festivals.length > 0) ||
      authContext.type === 'superadmin'
    ) {
      let query = db.collection('items');

      if (authContext.type !== 'superadmin') {
        query = query.where('festival', 'in', authContext.festivals);
      }

      globalContext.setProgress(10);

      return query.onSnapshot((querySnapshot) => {
        const items = querySnapshot.docs
          .sort((a, b) => a.data().name.localeCompare(b.data().name))
          .map((doc, index) => {
            return {
              ...doc.data(),
              pricesList: JSON.stringify(doc.data().prices),
              id: doc.id,
            };
          });

        globalContext.setProgress(100);
        setItems(items);
      });
    }
  };

  const loadDevices = () => {
    // if (!firstLoad.devices) {
    setFirstLoad({ ...firstLoad, devices: true });
    if (
      (authContext.festivals && authContext.festivals.length > 0) ||
      authContext.type === 'superadmin'
    ) {
      let query = db.collection('devices');

      if (authContext.type !== 'superadmin') {
        query = query.where('festival', 'in', authContext.festivals);
      }

      return query.onSnapshot((querySnapshot) => {
        globalContext.setProgress(10);

        const devices = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        globalContext.setProgress(100);

        setDevices(devices);
      });
    }
    // } else return undefined;
  };

  const loadRequests = () => {
    if (authContext.type === 'superadmin') {
      let query = db.collection('requests');

      return query.onSnapshot((querySnapshot) => {
        globalContext.setProgress(10);

        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setRequests(data);

        globalContext.setProgress(100);
      });
    }
  };
  const loadEventRequests = () => {
    if (authContext.type === 'superadmin') {
      let query = db.collection('event_req');
      return query.onSnapshot((querySnapshot) => {
        globalContext.setProgress(10);

        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setEventRequests(data);

        globalContext.setProgress(100);
      });
    } else {
      let query = db
        .collection('event_req')
        .where('uidrequester', '==', authContext.userInfos.uid);

      return query.onSnapshot(
        (querySnapshot) => {
          globalContext.setProgress(10);

          const data = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));

          setEventRequests(data);

          globalContext.setProgress(100);
        },
        (error) => {
          console.log(error);
          globalContext.setSnack({
            message: error.message,
            color: 'error',
            open: true,
          });
        },
      );
    }
  };

  const clear = () => {
    console.log('Clearing store context');
    setFestivals([]);
    setStands([]);
    setDevices([]);
    setItems([]);
    setCategories([]);
  };
  return (
    <StoreContext.Provider
      value={{
        festivals,
        stands,
        devices,
        items,
        // loading,
        clear,
        categories,
        users,
        selectedUsers,
        badgeList,
        creditTimeline,
        commandeTimeline,
        oldBadgeList,
        requests,
        firstLoad,
        commandes,
        byItemsCommands,
        eventRequests,
        fromDate,
        toDate,
        setFromDate,
        setToDate,
        setFirstLoad,
        setBadgeList,
        setOldBadgeList,
        setCreditTimeline,
        setCommandes,
        setByItemsCommands,
        setCommandeTimeline,
        setDevices,
        setFestivals,
        setStands,
        setItems,
        setCategories,
        setLoading,
        setUsers,
        setSelectedUsers,
        setRequests,
        setEventRequests,
        loadFestival,
        loadDevices,
        loadCommandesList,
        loadCreditList,
        loadUsers,
        loadBadges,
        loadOldBadges,
        loadStands,
        loadItems,
        loadCategories,
        loadRequests,
        loadEventRequests,
      }}>
      {props.children}
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
