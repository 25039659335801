import React, { useContext, useEffect } from 'react';

import { StoreContext } from '../../../context/storeContext';
import { GlobalContext } from '../../../context/globalContext';

import StandContextProvider from '../../../context/standContext';

import { withRouter } from '../../Component/withrouter-wrapper';

import TabItemByStand from './TabItemsByStand';
import AddItemPannel from './AddItemPannel';
import HeaderPannel from '../../Component/HeaderPannel';

// Style
import { useMainStyles } from '../../classes/main';

const Stands = (props) => {
  const store = useContext(StoreContext);
  const globalC = useContext(GlobalContext);

  const classes = useMainStyles();

  useEffect(() => {
    const unsubscribe = store.loadStands();
    const unsubscribe2 = store.loadItems();
    const unsubscribe3 = store.loadCategories();
    globalC.setActiveMenu(2);

    return () => {
      unsubscribe && unsubscribe();
      unsubscribe2 && unsubscribe2();
      unsubscribe3 && unsubscribe3();
    };
  }, []);

  return (
    <StandContextProvider {...props}>
      <div className={classes.main}>
        <HeaderPannel />
        <div className={classes.body}>
          <AddItemPannel {...props} />
          <TabItemByStand {...props} />
        </div>
      </div>
    </StandContextProvider>
  );
};

export default withRouter(Stands);
