import Picker from 'emoji-picker-react';
import { Box, Button, IconButton } from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Clear,
} from '@mui/icons-material';

import { useMainStyles } from '../classes/main';

import { useState } from 'react';

const CustomEmojiPickjer = (props) => {
  const classes = useMainStyles();

  const [chosenEmoji, setChosenEmoji] = useState(props.defaultEmoji);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const onEmojiClick = (emojiObject, event) => {
    setChosenEmoji(emojiObject.emoji);
    props.setChosenEmoji && props.setChosenEmoji(emojiObject.emoji);
    setShowEmojiPicker(!showEmojiPicker);
  };

  return (
    <Box>
      <Button
        variant="outlined"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
        {chosenEmoji ? <span>{chosenEmoji}</span> : <span>Logo</span>}
      </Button>
      <IconButton
        variant="outlined"
        onClick={() => {
          setChosenEmoji('');
          props.setChosenEmoji && props.setChosenEmoji('');
        }}>
        <Clear />
      </IconButton>
      {showEmojiPicker && (
        <Picker
          className={classes.paper}
          onEmojiClick={onEmojiClick}
          groupVisibility={{
            smileys_people: false,
            flags: false,
            animals_nature: false,
            symbols: false,
            travel_places: false,
          }}
        />
      )}
    </Box>
  );
};

export default CustomEmojiPickjer;
