import React, { useState, useContext, useEffect } from 'react';

import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

import { AuthContext } from '../../context/authContext';
import { StoreContext } from '../../context/storeContext';

import { Delete } from '@mui/icons-material';
import { Button } from '@mui/material';

import { db } from '../../firebase';

import { useMainStyles } from '../classes/main';
import { GlobalContext } from '../../context/globalContext';

const createOption = (label, value) => ({
  label,
  value: value,
});

const SelectCategories = (props) => {
  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);
  const global = useContext(GlobalContext);

  const classes = useMainStyles();

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [selectValue, setSelectValue] = useState(
    createOption(props.category || '', props.categoryID || ''),
  );

  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        {children}
        {props.selectProps.deleteEnabled && (
          <Button>
            <Delete
              onClick={() => {
                console.log('delete category - ' + props.value);
                setLoading(true);
                deleteCategory(props.value, () => {
                  setLoading(false);
                  setSelectValue('');
                });
              }}
            />
          </Button>
        )}
      </components.Option>
    );
  };

  const addCategorie = (Name, Event, cb) => {
    //console.log(name);
    db.collection('categories')
      .add({
        name: Name,
        event: Event,
      })
      .then((doc) => {
        global.setSnack({
          ...global.snack,
          open: true,
          message: 'La catégorie a été créée.',
        });
        cb(doc.id);

        store.setLoading(false);
      })
      .catch((err) => console.log(err.message));
  };

  const deleteCategory = (id, cb) => {
    // Remove category from all items
    db.collection('items')
      .where('festival', '==', authC.selectedFestival)
      .where('categoryID', '==', id)
      .get()
      .then((itemsToUpdate) => {
        itemsToUpdate.docs.forEach((item) => {
          db.collection('items').doc(item.id).update({ categoryID: '' });
        });
        // Delete category
        db.collection('categories')
          .doc(id)
          .delete()
          .then(() => {
            console.log('deletion ended');
            cb();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleCreate = (inputValue) => {
    setLoading(true);
    console.group('Option created');
    console.log('Wait a moment...');
    addCategorie(inputValue, authC.selectedFestival, (categoryID) => {
      setTimeout(() => {
        setCategoryOptions([
          ...categoryOptions,
          createOption(inputValue, categoryID),
        ]);
        setSelectValue(createOption(inputValue, categoryID));
        props.onCreateOption && props.onCreateOption(categoryID);
        setLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    console.log('useEffect refresh categories - editable');
    setCategoryOptions(
      store.categories
        ?.filter((category) => category.event === authC.selectedFestival)
        .map((categorie) => {
          return createOption(categorie.name, categorie.id);
        }),
    );
  }, [store.categories, authC.selectedFestival]);

  return (
    <CreatableSelect
      {...props}
      formatCreateLabel={(input) => {
        return 'Créer : ' + input;
      }}
      placeholder="Catégorie de produit"
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={(value) => {
        props.onChange && props.onChange(value);
        setSelectValue(value);
      }}
      onCreateOption={handleCreate}
      options={categoryOptions}
      value={selectValue}
      styles={{
        menu: (base) => ({ ...base, zIndex: 2 }),
        option: (provided) => ({
          ...provided,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }),
      }}
      components={{ Option }}
    />
  );
};

export default SelectCategories;
