import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../../context/authContext';
import { StoreContext } from '../../../context/storeContext';
import { db } from '../../../firebase';
import { saveAs } from 'file-saver';

import * as XLSX from 'xlsx';

import { Button } from '@material-ui/core';

const ExportsTab = () => {
  const authContext = useContext(AuthContext);
  const store = useContext(StoreContext);

  const [isDisabled, setIsDisabled] = useState(false);

  // First load
  useEffect(() => {
    // Va être appelé à chaque fois qu'on change d'event
    setIsDisabled(false);
  }, [authContext.selectedFestival]);

  const reloadData = () => {
    const allBadges = [];
    const stands = [];
    const devices = [];
    const promiseArray = [];
    const credits = [];
    const debits = [];
    const items = [];
    const badgesObj = [];

    const itemsConso = []; // Une entrée correspond à un item acheté
    const badgeConso = []; // Une entrée correspond à un debit userID

    const preLoadPromises = [];

    // Get badges
    preLoadPromises.push(
      new Promise((resolve, reject) => {
        db.collection('badge')
          .where('festival', '==', authContext.selectedFestival)
          .get()
          .then(function (querySnapshot) {
            if (querySnapshot) {
              querySnapshot.forEach((doc) => {
                badgesObj.push({ ...doc.data(), _id: doc.id });
                allBadges.push([
                  doc.id,
                  doc.data().creationDate.toDate(),
                  null,
                  doc.data().balance,
                  doc.data().balance_gifts,
                  doc.data().email,
                  doc.data().id,
                  doc.data().major,
                  doc.data().type?.name,
                  doc.data().scanBillet,
                  true,
                ]);
              });
            }
            resolve();
          })
          .catch((err) => reject(err));
      }),
    );

    preLoadPromises.push(
      new Promise((resolve, reject) => {
        db.collection('old_badge')
          .where('festival', '==', authContext.selectedFestival)
          .get()
          .then(function (querySnapshot2) {
            if (querySnapshot2) {
              querySnapshot2.forEach((inactiveBadge) => {
                console.log('push inactive badge');
                badgesObj.push({
                  ...inactiveBadge.data(),
                  _id: inactiveBadge.id,
                });
                allBadges.push([
                  inactiveBadge.id,
                  inactiveBadge.data().creationDate.toDate(),
                  inactiveBadge.data().deletionDate.toDate(),
                  inactiveBadge.data().balance,
                  inactiveBadge.data().balance_gifts,
                  inactiveBadge.data().email,
                  inactiveBadge.data().id,
                  inactiveBadge.data().major,
                  inactiveBadge.data().type?.name,
                  inactiveBadge.data().scanBillet,
                  false,
                ]);
              });
            }
            console.log('resolve promise push inactive badge');
            resolve();
          })
          .catch((err) => reject(err));
      }),
    );

    // Get Stand

    preLoadPromises.push(
      new Promise((resolve, reject) => {
        db.collection('stands')
          .where('festival', '==', authContext.selectedFestival)
          .get()
          .then((s) => {
            if (s) {
              s.forEach((stand) => {
                stands.push({ ...stand.data(), _id: stand.id });
              });
            }
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }),
    );

    // Get Items

    preLoadPromises.push(
      new Promise((resolve, reject) => {
        db.collection('items')
          .where('festival', '==', authContext.selectedFestival)
          .get()
          .then((i) => {
            if (i) {
              i.forEach((item) => {
                items.push({ ...item.data(), _id: item.id });
              });
            }
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }),
    );

    // Get devices

    preLoadPromises.push(
      new Promise((resolve, reject) => {
        db.collection('devices')
          .where('festival', '==', authContext.selectedFestival)
          .get()
          .then((d) => {
            if (d) {
              d.forEach((device) => {
                devices.push({ ...device.data(), _id: device.id });
              });
            }
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }),
    );

    // THEN (promise.all)

    Promise.all(preLoadPromises)
      .then(() => {
        console.log('preload finished');

        // Get transactionsDebit
        promiseArray.push(
          new Promise((resolve, reject) => {
            db.collection('transactionsDebit')
              .where('festival', '==', authContext.selectedFestival)
              .get()
              .then(function (querySnapshot) {
                querySnapshot.forEach((doc) => {
                  console.log('forEach transaction debit');
                  // 'brut' commande list
                  debits.push([
                    doc.id,
                    doc.data().userID && Array.isArray(doc.data().userID)
                      ? doc
                          .data()
                          .userID.map(
                            (e) =>
                              `${e.id}-debit:${e.debit}-debit_gifts:${e.debit_gifts}`,
                          )
                          .join(',')
                      : doc.data().userID,
                    doc.data().amount,
                    doc.data().device,
                    devices.find((d) => d._id === doc.data().device)?.name, //deviceName
                    doc.data().timestamp.toDate(),
                    doc.data().stand,
                    stands.find((s) => s._id === doc.data().stand)?.shopName, //standname
                    doc
                      .data()
                      .items?.map((e) => `${e.id}:${e.nb}:${e.price}`)
                      .join(','),
                  ]);

                  // per badge commande list

                  Array.isArray(doc.data().userID)
                    ? doc.data().userID.forEach((user) => {
                        // db.collection('badge')
                        //   .doc(user.id)
                        //   .get()
                        //   .then((b) => {
                        badgeConso.push([
                          doc.id, //transactionId
                          doc.data().timestamp.toDate(), //timestamp
                          doc.data().stand, //stand
                          stands.find((s) => s._id === doc.data().stand)
                            ?.shopName, //standname
                          doc.data().device, //device
                          devices.find((d) => d._id === doc.data().device)
                            ?.name, //deviceName
                          user.id, //userId
                          user.badgeId, //badgeId
                          badgesObj.find((b) => b._id === user.id)?.email, //email
                          badgesObj.find((b) => b._id === user.id)?.scanBillet, //Ticket number
                          user.debit, //debit
                          user.debit_gifts, //debit_gifts
                        ]);
                        // })
                        // .catch((err) => console.log(err));
                      })
                    : // db
                      //     .collection('badge')
                      //     .doc(doc.data().userID)
                      //     .get()
                      //     .then((b) => {
                      badgeConso.push([
                        doc.id, //transactionId
                        doc.data().timestamp.toDate(), //timestamp
                        doc.data().stand, //stand
                        stands.find((s) => s._id === doc.data().stand)
                          ?.shopName, //standname
                        doc.data().device, //device
                        devices.find((d) => d._id === doc.data().device)?.name, //deviceName
                        doc.data().userID, //userId
                        badgesObj.find((b) => b._id === doc.data().userID)?.id, //email
                        badgesObj.find((b) => b._id === doc.data().userID)
                          ?.email, //email
                        doc.data().amount, //debit
                        0, //debit_gifts
                      ]);
                  // })
                  // .catch((err) => console.log(err));

                  // Per item commande List

                  doc.data().items.forEach((item) => {
                    // db.collection('items')
                    //   .doc(item.id)
                    //   .get()
                    //   .then((i) => {
                    itemsConso.push([
                      doc.id, //transactionId
                      doc.data().timestamp.toDate(), //timestamp
                      doc.data().stand, //stand
                      stands.find((s) => s._id === doc.data().stand)?.shopName, //standname
                      doc.data().device, //device
                      devices.find((d) => d._id === doc.data().device)?.name, //deviceName
                      item.id, //itemId
                      items.find((i) => i._id === item.id)?.name, //itemName
                      item.price, // itemPrice
                      // i.exists ? parseFloat(i.data().prices.festivalier) : null, // itemPrice
                      item.nb, //qty
                      item.price ? item.price * item.nb : 0,
                    ]);
                    // })
                    // .catch((err) => console.log(err));
                  });
                });

                console.log('for each ended');
                resolve();
              })
              .catch((err) => reject(err));
          }),
        );

        // Get transactionsCredit
        promiseArray.push(
          new Promise((resolve, reject) => {
            db.collection('transactionsCredit')
              .where('festival', '==', authContext.selectedFestival)
              .get()
              .then(function (querySnapshot) {
                querySnapshot.forEach((doc) => {
                  credits.push([
                    doc.id,
                    doc.data().userID,
                    doc.data().amount,
                    doc.data().device,
                    devices.find((d) => d._id === doc.data().device)?.name, //deviceName
                    doc.data().timestamp.toDate(),
                    doc.data().type,
                  ]);
                });
                console.log(credits);
                resolve();
              })
              .catch((err) => reject(err));
          }),
        );

        Promise.all(promiseArray)
          .then((results) => {
            console.log('loading finished');
            store.setLoading(false);
            downloadExcel(allBadges, debits, credits, itemsConso, badgeConso);
            setIsDisabled(false);
          })
          .catch((err) => {
            console.log('loading finished with error');
            store.setLoading(false);
          });
      })
      .catch((err) => console.log(err));
  };

  const downloadExcel = (
    badges,
    transactionsDebits,
    transactionsCredits,
    perItemsTransactions,
    perBadgeTransactions,
  ) => {
    let ws_data = [
      [
        'id',
        'creationDate',
        'deletionDate',
        'balance',
        'balance_gifts',
        'email',
        'badgeId',
        'major',
        'type',
        'scanBillet',
        'active',
      ],
      ...badges,
    ];

    let tr_debits_data = [
      [
        'id',
        'badgeId',
        'amount',
        'device',
        'deviceName',
        'timestamp',
        'stand',
        'standname',
        'items',
      ],
      ...transactionsDebits,
    ];
    let tr_credits_data = [
      ['id', 'badgeId', 'amount', 'device', 'deviceName', 'timestamp', 'type'],
      ...transactionsCredits,
    ];

    let item_cons_data = [
      [
        'transactionId',
        'timestamp',
        'stand',
        'standname',
        'device',
        'deviceName',
        'itemId',
        'itemName',
        'itemPrice',
        'qty',
        'price',
      ],
      ...perItemsTransactions,
    ];
    let badge_cons_data = [
      [
        'transactionId',
        'timestamp',
        'stand',
        'standname',
        'device',
        'deviceName',
        'userId',
        'badgeId',
        'email',
        'scanBillet',
        'debit',
        'debit_gifts',
      ],
      ...perBadgeTransactions,
    ];

    var worksheet = XLSX.utils.aoa_to_sheet(ws_data);
    var worksheet2 = XLSX.utils.aoa_to_sheet(tr_debits_data);
    var worksheet3 = XLSX.utils.aoa_to_sheet(tr_credits_data);
    var worksheet4 = XLSX.utils.aoa_to_sheet(item_cons_data);
    var worksheet5 = XLSX.utils.aoa_to_sheet(badge_cons_data);

    var workbookTemplate = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbookTemplate, worksheet, 'badges');
    XLSX.utils.book_append_sheet(workbookTemplate, worksheet2, 'commandes');
    XLSX.utils.book_append_sheet(workbookTemplate, worksheet3, 'credits');
    XLSX.utils.book_append_sheet(
      workbookTemplate,
      worksheet4,
      'per_items_conso',
    );
    XLSX.utils.book_append_sheet(
      workbookTemplate,
      worksheet5,
      'per_badge_conso',
    );

    var wopts = {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellDates: true,
    };
    var wbout = XLSX.write(workbookTemplate, wopts);

    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      'bob-' + authContext.selectedFestival + '.xlsx',
    );
  };

  return (
    <div>
      <Button
        disabled={isDisabled}
        variant="contained"
        onClick={() => {
          setIsDisabled(true);
          store.setLoading(true);
          reloadData();
        }}>
        Télécharger le rapport en XLSX
      </Button>
    </div>
  );
};

export default ExportsTab;
