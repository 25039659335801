import { useContext, useState, useRef, useEffect } from 'react';
import { StoreContext } from '../../../context/storeContext';
import { AuthContext } from '../../../context/authContext';
import { useMainStyles } from '../../classes/main';
import { GlobalContext } from '../../../context/globalContext';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import firebase, { db } from '../../../firebase';

import { MoreVert, Close as CloseIcon } from '@mui/icons-material';

// import {
//   MuiPickersUtilsProvider,
//   KeyboardDateTimePicker,
// } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// Material Component
import {
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  TextField,
  Menu,
  MenuItem,
  Select,
  Typography,
  Switch,
  FormControlLabel,
} from '@material-ui/core';

import EditableNumeric from '../../Component/EditableNumeric';
import EditableText from '../../Component/EditableText';

const EventCard = (props) => {
  const authContext = useContext(AuthContext);
  const globalC = useContext(GlobalContext);
  const classes = useMainStyles();
  const store = useContext(StoreContext);
  let navigate = useNavigate();

  const [updating, setUpdating] = useState(false);
  const [name, setName] = useState(props.event.name);
  const [eventType, setEventType] = useState(
    props.event.eventType || 'Festival',
  );
  const [location, setLocation] = useState(props.event.location || '-');
  const [start, setStart] = useState(props.event.start?.toDate());
  const [end, setEnd] = useState(props.event.end?.toDate());
  const [anchorEl, setAnchorEl] = useState(null);
  const [capacity, setCapacity] = useState(props.event.capacity || 0);
  const [nbPtVente, setNbPtVente] = useState(props.event.nbPtVente || 0);
  const [nbPtRechargement, setnbPtRechargement] = useState(
    props.event.nbPtRechargement || 0,
  );
  const [majorDefault, setMajorDefault] = useState(
    props.event.majorDefault || false,
  );

  const [forceLogin, setForceLogin] = useState(props.event.forceLogin || false);

  const [donationEnabled, setDonationEnabled] = useState(
    props.event.donationEnabled || false,
  );
  const [specialBadgeRestriction, setSpecialBadgeRestriction] = useState(
    props.event.specialBadgeRestriction || false,
  );

  const [initselectedUsers, setInitSelectedUsers] = useState(
    store.users.filter((u) => {
      return u.festivals
        ? u.festivals.length > 0 && u.festivals?.indexOf(props.event.id) !== -1
        : false;
    }),
  );
  const [selectedUsers, setSelectedUsers] = useState(initselectedUsers);

  const open = Boolean(anchorEl);

  const options = ['modifier', 'archiver', 'supprimer'];
  const updateOptions = ['annuler'];

  const typeChoices = ['Soirée', 'Festival', 'Brocante'];

  const innerRef = useRef(null);

  const [focused, setFocused] = useState(false);

  const updateEvent = async () => {
    // console.log(name, eventType, location, start, end, capacity);
    setUpdating(false);
    // setIsUpdating({ ...isUpdating, name: false });
    try {
      await db.collection('festivals').doc(props.event.id).update({
        name,
        eventType,
        location,
        start,
        end,
        capacity,
        nbPtVente,
        nbPtRechargement,
        majorDefault,
        forceLogin,
        donationEnabled,
        specialBadgeRestriction,
      });

      if (
        initselectedUsers.filter(
          (x) => !selectedUsers.some((e) => e.id === x.id),
        ).length > 0
      ) {
        let accountsToRemove = await db
          .collection('accounts')
          .where(
            firebase.firestore.FieldPath.documentId(),
            'in',
            initselectedUsers
              .filter((x) => !selectedUsers.some((e) => e.id === x.id))
              .map((u) => u.id),
          )
          .get();

        for (const doc of accountsToRemove.docs) {
          // console.log(doc.data());

          // Add event id in selected user festivals
          doc.ref.update(
            'festivals',
            firebase.firestore.FieldValue.arrayRemove(props.event.id),
          );
        }
      }

      // console.log('toAdd');
      // console.log(
      //   selectedUsers.filter(
      //     (x) => !initselectedUsers.some((e) => e.id === x.id),
      //   ),
      // );

      if (
        selectedUsers.filter(
          (x) => !initselectedUsers.some((e) => e.id === x.id),
        ).length > 0
      ) {
        let accountsToAdd = await db
          .collection('accounts')
          .where(
            firebase.firestore.FieldPath.documentId(),
            'in',
            selectedUsers
              .filter((x) => !initselectedUsers.some((e) => e.id === x.id))
              .map((u) => u.id),
          )
          .get();

        for (const doc of accountsToAdd.docs) {
          // console.log(doc.data());

          // Add event id in selected user festivals
          doc.ref.update(
            'festivals',
            firebase.firestore.FieldValue.arrayUnion(props.event.id),
          );
        }
      }

      globalC.setSnack({
        ...globalC.snack,
        open: true,
        message: "L'évènement a été modifié.",
        color: 'success',
      });
    } catch (err) {
      globalC.setSnack({
        ...globalC.snack,
        open: true,
        message: err.message,
        color: 'error',
      });
    }
  };

  const deleteEvent = (eventId) => {
    console.log('delete event');
    console.log(eventId);
  };

  const disableEvent = async (eventId) => {
    try {
      let festivalsRef = db.collection('festivals');
      let event = await festivalsRef.doc(eventId).get();

      // console.log(event.data());
      await db
        .collection('old_events')
        .doc(eventId)
        .set({ ...event.data(), deletionDate: new Date() });

      // remove events id from accounts festivals
      let accountsWithEventRef = db
        .collection('accounts')
        .where('festivals', 'array-contains', eventId);

      let accounts = await accountsWithEventRef.get();

      for (const doc of accounts.docs) {
        await doc.ref.update(
          'festivals',
          firebase.firestore.FieldValue.arrayRemove(eventId),
        );
      }

      // disable stands with this festival id
      let standsRef = db.collection('stands').where('festival', '==', eventId);
      let stands = await standsRef.get();

      for (const doc of stands.docs) {
        await doc.ref.update('disabled', true);
      }

      // console.log('before delete event');
      await db.collection('festivals').doc(eventId).delete();

      globalC.setSnack({
        ...globalC.snack,
        open: true,
        message: "L'évènement a été archivé.",
        color: 'success',
      });
    } catch (err) {
      console.error('disable Event error', err);
      globalC.setSnack({
        ...globalC.snack,
        open: true,
        message: err.message,
        color: 'error',
      });
    }
  };

  const reinitField = () => {
    setName(props.event.name);
    setEventType(props.event.eventType);
    setLocation(props.event.location);
    setCapacity(props.event.capacity);
    setStart(props.event.start?.toDate());
    setEnd(props.event.end?.toDate());
    setNbPtVente(props.event.nbPtVente || 0);
    setnbPtRechargement(props.event.nbPtRechargement || 0);
  };

  const handleKeyDown = (ev) => {
    ev.key === 'Escape' ? cancelUpdate() : ev.key === 'Enter' && updateEvent();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const cancelUpdate = () => {
    setUpdating(false);
    reinitField();
    setSelectedUsers(initselectedUsers);
  };

  const handleChoice = (event) => {
    event.target.value && event.target.value === 2
      ? deleteEvent(props.event.id)
      : event.target.value === 1
      ? disableEvent(props.event.id)
      : event.target.value === 0
      ? setUpdating(true)
      : '';

    setAnchorEl(null);
  };

  const handleUpdateChoice = (event) => {
    event.target.value && event.target.value === 1
      ? cancelUpdate()
      : event.target.value === 0
      ? updateStand()
      : '';
    // console.log(event.target.value);
    setAnchorEl(null);
  };

  useEffect(() => {
    const div = innerRef.current;

    if (updating) {
      div.addEventListener('keydown', handleKeyDown, false);
      return () => {
        div.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [
    updating,
    name,
    eventType,
    location,
    start,
    end,
    capacity,
    nbPtVente,
    nbPtRechargement,
  ]);

  useEffect(() => {
    setInitSelectedUsers(
      store.users.filter((u) => {
        return u.festivals
          ? u.festivals.length > 0 &&
              u.festivals?.indexOf(props.event.id) !== -1
          : false;
      }),
    );
  }, [store.users]);

  useEffect(() => {
    setSelectedUsers(initselectedUsers);
    updating && reinitField();
  }, [updating]);

  return (
    <Box
      onFocus={() => updating && setFocused(true)}
      onBlur={() => setFocused(false)}
      ref={innerRef}
      boxShadow={2}
      className={clsx(classes.editableCard, {
        [classes.updatingEditableCard]: updating,
        [classes.updatingEditableCardFocused]: focused,
        [classes.largerCard]: updating,
      })}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ paddingTop: '15px' }}>
        <Avatar
          alt="logo"
          src={
            props.event.logo ||
            'https://firebasestorage.googleapis.com/v0/b/bopob-ec9dd.appspot.com/o/logo-festival%2Fweb_hi_res_512.png?alt=media&token=24cb045e-0cce-4258-87da-2addc8340f01'
          }
          style={{ marginRight: 5 }}>
          Bob
        </Avatar>
        <Box
          flexGrow="1"
          display="flex"
          flexDirection="column"
          style={{ paddingLeft: '15px' }}>
          {updating ? (
            <EditableText
              id="name"
              value={name}
              disabled={!updating}
              onChange={(e) => setName(e.target.value)}
            />
          ) : (
            <Typography variant="subtitle1" align="left">
              {props.event.name}
            </Typography>
          )}
          {updating ? (
            <Select
              className={classes.select}
              value={eventType}
              onChange={(e) => {
                setEventType(e.target.value);
              }}>
              {typeChoices.map((e, i) => (
                <MenuItem key={i} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Typography variant="caption">
              type: {props.event.eventType || 'Festival'}
            </Typography>
          )}
        </Box>
        {authContext.type === 'superadmin' && (
          <Box alignSelf="flex-start">
            {updating ? (
              <IconButton
                className={classes.optionIcon}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={() => cancelUpdate()}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                className={classes.optionIcon}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <MoreVert />
              </IconButton>
            )}
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleChoice}>
              {!updating
                ? options.map((option, index) => (
                    <MenuItem value={index} key={index} onClick={handleChoice}>
                      {option}
                    </MenuItem>
                  ))
                : updateOptions.map((option, index) => (
                    <MenuItem
                      value={index}
                      key={index}
                      onClick={handleUpdateChoice}>
                      {option}
                    </MenuItem>
                  ))}
            </Menu>
          </Box>
        )}
      </Box>
      {!updating && (
        <Box display="flex" flexDirection="column" alignItems="flex-end" m={1}>
          <Box m={1}>
            <Typography variant="caption">
              Evènement{' '}
              {props.inDays > 0 && props.endInDays > 0
                ? 'dans '
                : props.inDays < 0 && props.endInDays < 0
                ? 'terminé'
                : 'en cours'}
              {props.inDays > 0 &&
                Math.ceil(props.inDays) +
                  (props.inDays > 1 ? ' jours' : ' jour')}
            </Typography>
          </Box>
          {/* <Box width="100%" borderRadius={10}>
            <LinearProgress
              variant="determinate"
              value={100 - 100 * (props.inDays / 160)}
              color="primary"
              className={classes.eventProgressBar}
            />
          </Box> */}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        flexWrap="wrap"
        justifyContent="space-between">
        <Box
          width="250px"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          m={1}>
          <Box width="50%">
            <Typography variant="caption" className={classes.textDisabled}>
              Localisation
            </Typography>
          </Box>
          <Box width="50%">
            {updating ? (
              <EditableText
                id="location"
                value={location}
                disabled={!updating}
                onChange={(e) => setLocation(e.target.value)}
              />
            ) : (
              <Typography>{props.event.location || 'Paris'}</Typography>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="250px"
          m={1}>
          <Box width="70%">
            <Typography variant="caption" className={classes.textDisabled}>
              Nombre de participants
            </Typography>
          </Box>
          <Box width="30%">
            {updating ? (
              <EditableNumeric
                id="capacity"
                value={capacity}
                disabled={!updating}
                onChange={(e) => {
                  setCapacity(e.target.value);
                }}
              />
            ) : (
              <Typography>{capacity}</Typography>
            )}
          </Box>
        </Box>
      </Box>
      {updating && (
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          flexWrap="wrap"
          justifyContent="space-between">
          <FormControl style={{ width: '30%' }}>
            <InputLabel id="demo-simple-select-label">Organisateurs</InputLabel>
            <Select
              id="chip-users-select"
              multiple
              variant="outlined"
              value={selectedUsers}
              onChange={(e) => setSelectedUsers(e.target.value)}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <Typography noWrap>Organisateurs</Typography>
                // <Chip key="selected-users" label={selectedUsers.length} />
              )}>
              {store.users.map((user) => (
                <MenuItem key={user.id} value={user}>
                  {user.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box alignSelf="center" width="70%" maxHeight="100px" overflow="auto">
            {/* <GridList cols={2} cellHeight={30} style={{height:"50%"}}> */}
            {selectedUsers.map((value) => (
              // <GridListTile key={value.id}>
              <Chip
                key={value.id}
                label={value.email}
                className={classes.chip}
                onDelete={() =>
                  setSelectedUsers(
                    selectedUsers.filter((e) => e.id !== value.id),
                  )
                }
              />
            ))}
          </Box>
          <Box
            width="250px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            m={1}>
            <Box width="50%">
              <Typography variant="caption" className={classes.textDisabled}>
                Nombre de points de vente max
              </Typography>
            </Box>
            <Box width="50%">
              <EditableNumeric
                id="ptvente"
                value={nbPtVente}
                disabled={!updating}
                onChange={(e) => setNbPtVente(e.target.value)}
              />
            </Box>
          </Box>
          <Box
            width="250px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            m={1}>
            <Box width="50%">
              <Typography variant="caption" className={classes.textDisabled}>
                Nombre de points de rechargement max
              </Typography>
            </Box>
            <Box width="50%">
              <EditableNumeric
                id="ptrechargement"
                value={nbPtRechargement}
                disabled={!updating}
                onChange={(e) => setnbPtRechargement(e.target.value)}
              />
            </Box>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={majorDefault}
                  onChange={(e) => setMajorDefault(e.target.checked)}
                />
              }
              labelPlacement="start"
              label="Options majeurs par défaut"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={forceLogin}
                  onChange={(e) => setForceLogin(e.target.checked)}
                />
              }
              labelPlacement="start"
              label="Obliger de renseigner n° tel / QR Code"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={donationEnabled}
                  onChange={(e) => setDonationEnabled(e.target.checked)}
                />
              }
              labelPlacement="start"
              label="Possibilité de dons aux participants"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={specialBadgeRestriction}
                  onChange={(e) => setSpecialBadgeRestriction(e.target.checked)}
                />
              }
              labelPlacement="start"
              label="Restriction création de badges"
            />
          </Box>
        </Box>
      )}

      {!updating && (
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            m={1}>
            <Typography variant="caption" className={classes.textDisabled}>
              Début
            </Typography>

            <Typography variant="caption">
              {props.event.start?.toDate().toLocaleString() ||
                '12/03/2021, 10:00:00'}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            m={1}>
            <Typography variant="caption" className={classes.textDisabled}>
              Fin
            </Typography>
            <Typography variant="caption">
              {props.event.end?.toDate().toLocaleString() ||
                '14/03/2021, 18:00:00'}
            </Typography>
          </Box>
        </Box>
      )}

      {updating && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          m={1}>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              // variant="inline"
              style={{ margin: '10px', width: '45%' }}
              inputVariant="outlined"
              ampm={false}
              format="dd/MM/yyyy HH:mm"
              id="date-begin-picker-inline"
              label="Début"
              value={start}
              clearable
              onChange={(e) => setStart(e)}
            />
            <DateTimePicker
              // variant="inline"
              style={{ margin: '10px', width: '45%' }}
              inputVariant="outlined"
              ampm={false}
              format="dd/MM/yyyy HH:mm"
              id="date-end-picker-inline"
              label="Fin"
              value={end}
              onChange={(e) => setEnd(e)}
            />
          </LocalizationProvider>
        </Box>
      )}

      {updating ? (
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => {
            updateEvent();
          }}>
          Sauvegarder
        </Button>
      ) : !props.pending ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            authContext.setSelectedFestival(props.event.id);
            // store.loadStands();
            store.loadItems();
            navigate('/event');
          }}>
          Accéder
        </Button>
      ) : (
        authContext.type === 'superadmin' && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => {
              // store.loadStands();
              navigate('/requests');
            }}>
            Voir la demande
          </Button>
        )
      )}

      {/* </Box> */}
    </Box>
  );
};

export default EventCard;
