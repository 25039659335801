import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

const GlobalContextProvider = (props) => {
  const [progress, setProgress] = useState(0);
  const [selectedShop, setSelectedShop] = useState('');
  const [authLoading, setAuthLoading] = useState(true);
  const [inRegister, setInRegister] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    message: '',
    color: 'success',
  });
  const [activeMenu, setActiveMenu] = useState(0);

  return (
    <GlobalContext.Provider
      value={{
        progress,
        setProgress,
        authLoading,
        setAuthLoading,
        selectedShop,
        setSelectedShop,
        inRegister,
        setInRegister,
        snack,
        setSnack,
        activeMenu,
        setActiveMenu,
      }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
