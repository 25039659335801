import { useContext } from "react";
import { StoreContext } from "../../../context/storeContext";
import { AuthContext } from "../../../context/authContext";
import { useMainStyles } from "../../classes/main";

import EventCard from "./EventCard";

const EventsTab = (props) => {
  const classes = useMainStyles();
  const store = useContext(StoreContext);
  const authContext = useContext(AuthContext);

  return (
    <div className={classes.eventsTab}>
      {!props.pending &&
        store.festivals
          .filter((e) => {
            return props.search
              ? e.name.toLowerCase().includes(props.search.toLowerCase())
              : true;
          })
          .map((event, index) => {
            const inDays = event.start
              ? (event.start?.toDate() - new Date()) / (1000 * 60 * 60 * 24)
              : 10;

            const endInDays = event.end
              ? (event.end?.toDate() - new Date()) / (1000 * 60 * 60 * 24)
              : 20;

            return (
              <EventCard
                event={event}
                key={index}
                inDays={inDays}
                endInDays={endInDays}
              />
            );
          })
          .filter((e) =>
            props.allEvent
              ? true
              : props.inprogressEvent
              ? e.props.inDays <= 0 && e.props.endInDays > 0
              : props.passedEvent
              ? e.props.inDays < 0 && e.props.endInDays <= 0
              : e.props.inDays > 0 && e.props.endInDays > 0,
          )}

      {props.pending &&
        store.eventRequests
          .filter(
            (r) =>
              r.uidrequester === authContext.userInfos.uid ||
              authContext.type === 'superadmin',
          )
          .filter((r) => r.status === 'pending')
          .map((event, index) => {
            const inDays = event.start
              ? (event.start?.toDate() - new Date()) / (1000 * 60 * 60 * 24)
              : 10;

            const endInDays = event.end
              ? (event.end?.toDate() - new Date()) / (1000 * 60 * 60 * 24)
              : 20;

            return (
              <EventCard
                event={event}
                key={index}
                inDays={inDays}
                endInDays={endInDays}
                pending={true}
              />
            );
          })}
    </div>
  );
};
export default EventsTab;
