import React, { useState, useContext, useEffect } from 'react';

import { AuthContext } from '../../../context/authContext';
import { StoreContext } from '../../../context/storeContext';
import { GlobalContext } from '../../../context/globalContext';
import { StandContext } from '../../../context/standContext';

import { useLocation } from 'react-router-dom';

// Firebase
import { db } from '../../../firebase';

import CustomEmojiPickjer from '../../Component/emojiPicker';
import CategorySelect from '../../Component/SelectCategories';

// Material Component
import {
  Box,
  Button,
  Checkbox,
  Divider,
  TextField,
  Typography,
} from '@mui/material';

// Style

import { useMainStyles } from '../../classes/main';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

const AddItemPannel = (props) => {
  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);
  const global = useContext(GlobalContext);
  const standC = useContext(StandContext);

  const location = useLocation();

  const classes = useMainStyles();

  const [pricesTypes, setPricesTypes] = useState([]);

  const setEmoji = (value) => {
    setItem({ ...item, logo: value });
  };

  const [item, setItem] = useState({
    name: undefined,
    prices: [],
    isAlcool: false,
    isFavorite: false,
    logo: '',
    categorieID: '',
  });

  //fin des composants de selection/création de catégories
  //Etat création catégories
  const handleChange = (newValue, actionMeta) => {
    if (newValue === null) {
      setItem({ ...item, categorieID: '' });
    } else if (newValue !== null) {
      setItem({
        ...item,
        categorieID: newValue.value,
      });
    }
  };

  const resetItem = () => {
    setItem({
      ...item,
      name: undefined,
      prices: [],
      isAlcool: false,
      isFavorite: false,
      logo: '',
    });
  };

  //Fonction d'ajout d'item
  const addItem = (name, prices, isAlcool, isFavorite, logo, categorieID) => {
    if (item.prices.length < pricesTypes.length) {
      global.setSnack({
        ...global.snack,
        open: true,
        color: 'error',
        message: 'Veuillez définir tous les prix',
      });
    } else {
      db.collection('items')
        .add({
          logo,
          name,
          prices: prices.reduce(function (result, item, index, array) {
            result[pricesTypes[index]] = item;
            return result;
          }, {}),
          isAlcool,
          isFavorite,
          festival: authC.selectedFestival,
          stand: standC.selectedStand,
          categoryID: categorieID,
        })
        .then(() => {
          global.setSnack({
            ...global.snack,
            open: true,
            color: 'success',
            message: 'Le produit a été créé.',
          });

          resetItem();

          store.setLoading(false);
        })
        .catch((err) => console.log(err.message));
    }
  };

  useEffect(() => {
    console.log('useEffect pricesTypes');
    authC.selectedFestival &&
      store.festivals &&
      setPricesTypes(
        store.festivals
          ?.find((f) => f.id === authC.selectedFestival)
          ?.badgeType?.map((e) => e.type)
          ?.filter(onlyUnique),
      );
  }, [authC.selectedFestival, store.festivals]);

  useEffect(() => {
    if (location?.state && location?.state.fromEvent) {
      standC.setSelectedStand(location.state.standId);
    } else {
      standC.setSelectedStand(
        store.stands.filter(
          (s) => s.festival === authC.selectedFestival && s.type === 'debit',
        )[0]?.id,
      );
    }
  }, [store.stands]);

  return (
    <div>
      <Typography variant="subtitle1" className={classes.title} mt={1}>
        AJOUTER UN PRODUIT
      </Typography>
      <Divider className={classes.horizontalDivider} />
      <Box
        mt={2}
        width="80%"
        alignItems="flex-start"
        alignSelf="left"
        display="flex"
        flexDirection="row"
        className={classes.addMenu}>
        <Box>
          <Box m={1} minWidth="200px" style={{ zIndex: 2 }}>
            <CategorySelect
              deleteEnabled={true}
              initialValue=""
              onChange={handleChange}
            />
          </Box>
          <Box m={1} minWidth="300px">
            <CustomEmojiPickjer setChosenEmoji={setEmoji} />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }}>
          <Box display="flex" flexDirection="row">
            <Box m={1}>
              <TextField
                error={item.name === ''}
                onChange={(e) => setItem({ ...item, name: e.target.value })}
                value={item.name || ''}
                variant="outlined"
                label="Nom du produit"
                // helperText="* obligatoire"
              />
            </Box>
            <Box m={1}>
              <Button
                onClick={() => {
                  setItem({ ...item, isFavorite: !item.isFavorite });
                }}>
                <Checkbox
                  checked={item.isFavorite}
                  onChange={() => {
                    setItem({ ...item, isFavorite: !item.isFavorite });
                  }}
                />
                <label id="isFavorite">Produit best-seller</label>
              </Button>
            </Box>
            <Box m={1}>
              <Button
                onClick={() => {
                  setItem({ ...item, isAlcool: !item.isAlcool });
                }}>
                <Checkbox
                  checked={item.isAlcool}
                  onChange={() => {
                    setItem({ ...item, isAlcool: !item.isAlcool });
                  }}
                />
                <label id="isFavorite">Produit interdit aux mineurs</label>
              </Button>
            </Box>
          </Box>

          <Box minWidth="200px" display="flex" flexDirection="row">
            {pricesTypes?.map((e, i) => {
              return (
                <Box m={1} key={i}>
                  <TextField
                    // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    type="number"
                    // error={isNaN(prices[i]) !== false}
                    label={`Tarif ${e}`}
                    onChange={(event) => {
                      const newPrices = [...item.prices];
                      newPrices[i] = event.target.value;
                      setItem({ ...item, prices: newPrices });
                      // console.log(store);
                    }}
                    value={item.prices[i] || ''}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.addItemButton}>
          <Button
            disabled={global.progress != 0 || !item.name || item.name === ''}
            variant="contained"
            color="secondary"
            style={{ margin: 5 }}
            onClick={() => {
              addItem(
                item.name,
                item.prices,
                item.isAlcool,
                item.isFavorite,
                item.logo,
                item.categorieID,
              );
            }}>
            Ajouter
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default AddItemPannel;
