import React from 'react';

import PendingConfirmation from '../view/PendingConfirmation';

import { Navigate } from 'react-router-dom';
const routes = [
  {
    name: 'Pending',
    path: '/pendingConfirmation',
    exact: true,
    main: () => <PendingConfirmation />,
  },
  {
    main: () => <Navigate replace to="/pendingConfirmation" />,
    key: 'redirect',
  },
];

export default routes;
