import React, { useState, useEffect, useContext } from 'react';
import firebase, { auth, db } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';

import { Bars } from 'react-loader-spinner';

import PasswordStrengthBar from 'react-password-strength-bar';

import { withRouter } from './Component/withrouter-wrapper';

// Material UI Component
import {
  Grid,
  Avatar,
  Container,
  Button,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core';
import { Box } from '@mui/material';

import GitHubIcon from '@material-ui/icons/GitHub';

// Context
import { GlobalContext } from '../context/globalContext';

// Styles
import { makeStyles, useTheme } from '@material-ui/core/styles';
import logo from '../img/bob-logo-v.png';

import { useMainStyles } from './classes/main';

const emailValidation = (email) => {
  if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
    return null;
  }
  if (email && email.trim() === '') {
    return true; // 'Email is required';
  }
  return true; // 'Please enter a valid email';
};

const pwdValidation = (pwd, pwdConfirm) => {
  return pwd !== pwdConfirm;
};

const Register = () => {
  const [error, setErrors] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState(undefined);
  const [pwd, setPwd] = useState(undefined);
  const [pwdConfirm, setPwdConfirm] = useState(undefined);
  const [pwdError, setPwdError] = useState(false);
  const [pwdScore, setPwdScore] = useState(0);

  const [confirmation, setConfirmation] = useState('');

  const globalC = useContext(GlobalContext);

  let navigate = useNavigate();

  // const classes = makeStyles(mainStyles)();
  const classes = useMainStyles();
  const theme = useTheme();

  const signUpWithEmail = (cb) => {
    globalC.setAuthLoading(true);

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, pwd)
      .then((userCredential) => {
        cb(userCredential, null);
      })
      .catch((error) => {
        globalC.setSnack({
          ...globalC.snack,
          open: true,
          message: error.message,
          color: 'error',
        });
        // alert(error.message);
        globalC.setAuthLoading(false);
        cb(null, error);
      });
  };

  const signInWithProvider = (providerName) => {
    globalC.setAuthLoading(true);
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        const provider =
          providerName === 'google'
            ? new firebase.auth.GoogleAuthProvider()
            : providerName === 'github'
            ? new firebase.auth.GithubAuthProvider()
            : console.log('provider not available');
        // providerName === 'google' &&
        provider.setCustomParameters({ prompt: 'select_account' });

        firebase
          .auth()
          .signInWithPopup(provider)
          .then((result) => {
            console.log('connected');
            // console.log(result);
          })
          .catch((error) => {
            console.log('signIn error');
            console.log(error.message);
            setErrors(error.message);
            globalC.setAuthLoading(false);
          });
      })
      .catch((error) => {
        console.log(error.message);
        setErrors(error.message);
        globalC.setAuthLoading(false);
      });
  };

  useEffect(() => {
    globalC.setInRegister(true);
  }, []);

  useEffect(() => {
    if (email) {
      setEmailError(emailValidation(email));
    }
  }, [email]);

  return (
    <div className={classes.loginContainer}>
      {globalC.authLoading && (
        <Bars
          wrapperClass={classes.loader}
          // type="Bars"
          color={theme.palette.primary.light}
          secondaryColor={theme.palette.secondary.main}
        />
      )}

      {!globalC.authLoading && (
        <Container component="main" className={classes.login} maxWidth="xs">
          <Box>
            <Grid item xs justifyContent="flex-end" style={{ display: 'flex' }}>
              {/* <Link to="/Login" variant="body2" className={classes.link}>
                {"J'ai déjà un compte"}
              </Link> */}
              <Button
                className={classes.smallButton}
                onClick={() => navigate('/Login')}
                fullWidth
                variant="contained">
                Login
              </Button>
            </Grid>
            <div className={classes.inarow}>
              <div className={classes.bobLogo}>
                <img src={logo} alt="logo" height="81px" width="181px" />
              </div>
            </div>
            <Grid className={classes.error} item xs>
              {error}
            </Grid>
          </Box>
          <Box>
            <TextField
              variant="outlined"
              margin="normal"
              error={emailError}
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={email || ''}
              autoComplete="email"
              InputLabelProps={{ className: classes.input }}
              InputProps={{ className: classes.input }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="pwd"
              label="Password"
              name="pwd"
              value={pwd || ''}
              type="password"
              autoComplete="new-password"
              InputLabelProps={{ className: classes.input }}
              InputProps={{ className: classes.input }}
              onChange={(e) => {
                setPwd(e.target.value);
              }}
            />
            <PasswordStrengthBar
              onChangeScore={(score) => {
                setPwdScore(score);
              }}
              password={pwd}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="pwdConfirm"
              label="Confirm password"
              name="pwdConfirm"
              value={pwdConfirm || ''}
              type="password"
              InputLabelProps={{ className: classes.input }}
              InputProps={{ className: classes.input }}
              onChange={(e) => {
                setPwdConfirm(e.target.value);
              }}
            />
            <Button
              onClick={(event) => {
                event.preventDefault();
                if (
                  // !messageValidation(message) &&
                  !emailValidation(email) &&
                  !pwdValidation(pwd, pwdConfirm) &&
                  pwdScore >= 2
                ) {
                  console.log('no error in form');
                  signUpWithEmail((res, err) => {
                    if (!err) {
                      res.user
                        .sendEmailVerification({
                          url:
                            process.env.REACT_APP_HOST ||
                            'http://localhost:3000',
                        })
                        .then(() => {
                          globalC.setSnack({
                            ...globalC.snack,
                            open: true,
                            message:
                              'Votre demande a été envoyée. Pensez à valider votre adresse email.',
                            color: 'success',
                          });
                          // alert(
                          //   'Votre demande a été envoyée. Pensez à valider votre adresse email.',
                          // );
                          firebase.auth().signOut();
                        })
                        .catch((err) => {
                          globalC.setSnack({
                            ...globalC.snack,
                            open: true,
                            message: err.message,
                            color: 'error',
                          });
                          // alert(err.message);
                          firebase.auth().signOut();
                        });
                    } else {
                      console.log(err);
                      globalC.setSnack({
                        ...globalC.snack,
                        open: true,
                        message: err.message,
                        color: 'error',
                      });
                      // alert(err.message);
                      setErrors(err.message);
                    }
                  });
                } else {
                  setEmailError(emailValidation(email));
                  // setMessageError(messageValidation(message));
                  setPwdError(pwdValidation(pwd, pwdConfirm));
                  if (pwdScore < 2)
                    setErrors("Le mot de passe n'est pas assez robuste.");
                }
              }}
              className={classes.submit}
              fullWidth
              variant="contained">
              Sign in
            </Button>
          </Box>
          <Divider className={classes.horizontalDivider} />
          {/* <Typography
            component="h2"
            variant="h5"
            style={{
              color: theme.palette.primary.main,
              justifyContent: 'center',
              display: 'flex',
            }}>
            ou
          </Typography> */}
          <div className={classes.logoinarow}>
            <Button
              variant="outlined"
              className={classes.providerButton}
              onClick={(event) => {
                event.preventDefault();
                signInWithProvider('google');
              }}>
              <Avatar
                className={classes.medium}
                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                alt="Glogo"
              />
              Google sign in
            </Button>
            <Button
              variant="outlined"
              className={classes.providerButton}
              onClick={(event) => {
                event.preventDefault();
                signInWithProvider('github');
              }}>
              <GitHubIcon className={classes.medium} />
              Github sign in
            </Button>
          </div>

          {confirmation && (
            <div className={classes.confirmation}>
              <Typography component="h2" variant="h4">
                {confirmation}
              </Typography>
            </div>
          )}
        </Container>
        // </form>
      )}
    </div>
  );
};

export default withRouter(Register);
