import React, { useState, useContext, useEffect, forwardRef } from 'react';
import { Link } from 'react-router-dom';

// Style
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useMainStyles } from '../classes/main';

// Material UI Icons
import {
  MobileFriendly,
  HowToReg,
  ChevronLeft,
  ChevronRight,
  Event,
  Equalizer,
  CloudDownload,
  ShoppingCart,
  AssignmentLate,
  EuroSymbol,
  Menu as MenuIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
// } from '@material-ui/icons';

// import  from '@material-ui/icons/Menu';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import {
  Avatar,
  Box,
  AppBar,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Toolbar,
} from '@material-ui/core';
// } from '@material-ui/core';

import { Badge } from '@mui/material';

import logo from '../../img/bob.png';

import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

// Context
import { AuthContext } from '../../context/authContext';
import { StoreContext } from '../../context/storeContext';
import { GlobalContext } from '../../context/globalContext';

// Others
import { jwtDecode } from 'jwt-decode';

// Firebase
import firebase, { analytics, db } from '../../firebase';

function generateRandomString(length) {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  // console.log(text);
  return text;
}
function generateCodeVerifier() {
  return generateRandomString(128);
}
function generateCodeChallenge(code_verifier) {
  return base64URL(sha256(code_verifier));
}

function base64URL(string) {
  return string
    .toString(Base64)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

const DrawerComponent = () => {
  const [selectedIndex, setSelectedIndex] = useState();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // const classes = makeStyles(mainStyles)();
  const classes = useMainStyles();
  const theme = useTheme();

  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);
  const globalC = useContext(GlobalContext);

  const handleLogout = () => {
    analytics.logEvent('signout');

    firebase
      .auth()
      .signOut()
      .then(() => {
        authC.logout();
        store.clear();
        window.localStorage.clear();
        console.log('utilisateur déconnecté de firebase');
      })
      .catch((e) => console.error(e.message));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleListItemClick = (event, index) => {
    // globalC.setActiveMenu(index);
    // setSelectedIndex(index);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const helloAssoAuthorization = (event) => {
    event.preventDefault(); // Prevent to refresh browser

    console.log('start HelloAssoAuthorization Workflow');

    // First, generate PKCE

    const pkce_code_verifier = generateCodeVerifier();
    const pkce_code_challenge = generateCodeChallenge(pkce_code_verifier);

    console.log('PKCE verifier code : ', pkce_code_verifier);

    // Store PKCE associated to the user
    db.collection('accountsMetadata')
      .doc(authC.userInfos.uid)
      .set({ pkce_code_verifier: pkce_code_verifier }, { merge: true })
      .then(() => {})
      .catch((err) => console.log(err));

    window.open(
      'https://auth.helloasso.com/authorize?client_id="' +
        process.env.REACT_APP_HELLOASSO_CLIENT_ID +
        '"&redirect_uri="' +
        process.env.REACT_APP_BACKEND_URL +
        '/helloasso/authresult"&code_challenge="' +
        pkce_code_challenge +
        '"&code_challenge_method="S256"&state="' +
        authC.userInfos.uid +
        '"',
      'popup',
      'width=600,height=600,scrollbars=no,resizable=no',
    );
  };

  // Get Data (subscribe to changes only when rendered)
  useEffect(() => {
    const unsubscribe = store.loadFestival();

    return () => {
      unsubscribe && unsubscribe();
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (authC.token) {
        var time = jwtDecode(authC.token).exp - Date.now() / 1000;
        var minutes = Math.floor(time / 60);
        var seconds = Math.floor(time - minutes * 60);
      } else {
        console.log(authC.token);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [authC.token]);

  return (
    <Box
      id="maindrawer"
      // className={clsx(classes.drawer, {
      //   [classes.drawerOpen]: open,
      //   [classes.drawerClose]: !open,
      // })}
    >
      <AppBar
        // position="fixed"
        // className={clsx(classes.appBar, {
        //   [classes.appBarShift]: open,
        // })}
        classes={{ root: classes.appBar2 }}>
        <Toolbar disableGutters={true} variant="dense">
          <Box className={classes.toolbar}>
            <Box p={1}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}>
                <MenuIcon />
              </IconButton>
            </Box>
            <img src={logo} className={classes.headerLogo} />
            {/* <Box display="flex" justifyContent="center" flexGrow={1}>
              <Typography variant="h6" style={{ marginRight: 10 }}>
                Expiration du token d'authentification dans {authC.expToken}{' '}
                min.
              </Typography>
            </Box> */}
            <Box
              flexGrow={1}
              p={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="center">
              <Button
                className={classes.userMenu}
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleClick}>
                <Avatar
                  className={classes.small}
                  alt="idick"
                  src={authC.userInfos.photoURL}
                  style={{ marginRight: '10px' }}
                />
                <Typography variant="caption" noWrap>
                  {authC.userInfos?.displayName}
                </Typography>
                {/* <KeyboardArrowDownIcon /> */}
              </Button>
            </Box>
          </Box>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem onClick={helloAssoAuthorization}>
              Connecter Hello Asso
            </MenuItem>
            <MenuItem onClick={handleClose}>Mon profil</MenuItem>
            <MenuItem onClick={handleLogout}>Déconnecter</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        open={open}
        variant={open ? 'temporary' : 'permanent'}
        onClick={() => setOpen(false)}
        onClose={() => setOpen(false)}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}>
        <div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRight style={{ color: 'white' }} />
            ) : (
              <ChevronLeft style={{ color: 'white' }} />
            )}
          </IconButton>
        </div>
        <List>
          <ListItem
            button
            component={Link}
            to="/events"
            selected={globalC.activeMenu === 0}
            // onClick={(event) => handleListItemClick(event, 0)}
            key="Dashboard">
            <ListItemIcon>
              {<Equalizer style={{ color: theme.palette.white }} />}
            </ListItemIcon>
            <ListItemText
              primary="Dashboard"
              style={{ color: theme.palette.white }}
            />
          </ListItem>
          {authC.festivals.length > 0 && (
            <ListItem
              button
              component={Link}
              to="/event"
              selected={globalC.activeMenu === 1}
              // onClick={(event) => handleListItemClick(event, 1)}
              key="event">
              <ListItemIcon>
                {<Event style={{ color: theme.palette.white }} />}
              </ListItemIcon>
              <ListItemText
                primary="Evènement"
                style={{ color: theme.palette.white }}
              />
            </ListItem>
          )}
          {authC.festivals.length > 0 && (
            <ListItem
              button
              component={Link}
              to="/stands"
              selected={globalC.activeMenu === 2}
              // onClick={(event) => handleListItemClick(event, 2)}
              key="Stands">
              <ListItemIcon>
                {<ShoppingCart style={{ color: theme.palette.white }} />}
              </ListItemIcon>
              <ListItemText
                primary="Stands"
                style={{ color: theme.palette.white }}
              />
            </ListItem>
          )}
          {authC.festivals.length > 0 && (
            <ListItem
              button
              component={Link} // On dit que le ListItem hérite du composant Link
              to="/devices" // Props qui sera donc envoyée au composant Link (comme les autres props)
              selected={globalC.activeMenu === 3}
              // onClick={(event) => handleListItemClick(event, 3)}
              key="Devices">
              <ListItemIcon>
                <MobileFriendly style={{ color: theme.palette.white }} />
              </ListItemIcon>
              <ListItemText
                primary="Terminaux"
                style={{ color: theme.palette.white }}
              />
            </ListItem>
          )}

          {authC.type === 'superadmin' && (
            <ListItem
              button
              component={Link}
              to="/users"
              selected={globalC.activeMenu === 4}
              // onClick={(event) => handleListItemClick(event, 4)}
              key="Users">
              <ListItemIcon>
                {<HowToReg style={{ color: theme.palette.white }} />}
              </ListItemIcon>
              <ListItemText
                primary="Utilisateurs"
                style={{ color: theme.palette.white }}
              />
            </ListItem>
          )}
          {authC.type === 'superadmin' && (
            <ListItem
              button
              component={Link}
              to="/Requests"
              selected={globalC.activeMenu === 5}
              // onClick={(event) => handleListItemClick(event, 5)}
              key="Requests">
              <ListItemIcon>
                <Badge
                  badgeContent={
                    store.requests.filter((r) => r.status == 'pending').length +
                    store.eventRequests.filter((e) => e.status == 'pending')
                      .length
                  }
                  color="primary">
                  {<AssignmentLate style={{ color: theme.palette.white }} />}
                </Badge>
              </ListItemIcon>
              <ListItemText
                primary="Demandes"
                style={{ color: theme.palette.white }}
              />
            </ListItem>
          )}
          {authC.type === 'superadmin' && (
            <ListItem
              button
              component={Link}
              to="/ComptaDevis"
              selected={globalC.activeMenu === 6}
              // onClick={(event) => handleListItemClick(event, 6)}
              key="ComptaDevis">
              <ListItemIcon>
                {<EuroSymbol style={{ color: theme.palette.white }} />}
              </ListItemIcon>
              <ListItemText
                primary="Comptabilité et Devis"
                style={{ color: theme.palette.white }}
              />
            </ListItem>
          )}
        </List>
      </Drawer>
    </Box>
  );
};

export default DrawerComponent;
