import { useContext } from 'react';

import { StoreContext } from '../../context/storeContext';

import StyledMaterialTable from './StyledMaterialTable';

function SelectUsers(props) {
  const store = useContext(StoreContext);

  return (
    <StyledMaterialTable
      columns={[
        { title: 'ID', field: 'id', hidden: true },
        { title: 'Nom', field: 'displayName' },
        { title: 'Type', field: 'type' },
        { title: 'Email', field: 'email' },
        {
          title: 'Evènements autorisés',
          field: 'festivals',
          render: (rowData) => {
            return rowData.festivals?.map((event) => {
              return (
                <div key={event}>
                  {store.festivals.find((f) => f.id === event) &&
                    store.festivals.find((f) => f.id === event).name}
                </div>
              );
            });
          },
        },
      ]}
      options={{
        selection: true,
        showSelectAllCheckbox: false,
        padding: 'dense',
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
      }}
      onSelectionChange={(rows) => {
        console.log(rows);
        store.setSelectedUsers(rows.map((u) => u.id));
      }}
      data={store.users}
      title="Sélectionnez les organisateurs"
    />
  );
}

export default SelectUsers;
