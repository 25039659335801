// React
import React, { useState, useEffect, useContext } from 'react';
import {
  Routes,
  BrowserRouter as Router,
  Route,
  Navigate,
} from 'react-router-dom';

// Router
import routes from '../routes/routes.js';
import protectedRoutes from '../routes/protectedRoutes';
import protectedRoutesNoEvent from '../routes/protectedRoutesNoEvent.js';
import ProtectedRoute from '../routes/ProtectedRouteHoc';
import othersRoutes from '../routes/othersRoutes';
import adminRoutes from '../routes/adminRoutes';

import { Bars } from 'react-loader-spinner';

// Context
import { GlobalContext } from '../context/globalContext';
import { AuthContext } from '../context/authContext';

// Material UI
import { Box, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

// Component
import DrawerComponent from './Component/Drawer';
import CustomLoader from './Component/Loader';
import Footer from './Component/Footer';

import LogRocket from 'logrocket';

import PendingConfirmation from './PendingConfirmation';

// Firebase
import { auth, db } from '../firebase';

// Others imports
import { jwtDecode } from 'jwt-decode';

// Style
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMainStyles } from './classes/main';
import clsx from 'clsx';

export default function Main(props) {
  const authC = useContext(AuthContext);
  const globalC = useContext(GlobalContext);

  // const classes = makeStyles(mainStyles)();
  const classes = useMainStyles();

  const theme = useTheme();

  function readSession() {
    console.log('start loading readSession');

    globalC.setAuthLoading(true);
    return auth.onIdTokenChanged(function (user) {
      if (user) {
        console.log(user);
        console.log(user.emailVerified);
        if (user.emailVerified) {
          // User is signed in.
          console.log('logged');

          db.collection('accounts')
            .doc(user.uid)
            .get()
            .then((userDB) => {
              if (userDB.data()) {
                // authC.setFestival(userDB.data().festival);
                authC.setFestivals(userDB.data().festivals);

                authC.setType(userDB.data().type);
                authC.setUsersInfos(user);
                authC.setLoggedIn(true);

                // console.log("logrocket", user);
                LogRocket.identify(user.uid, {
                  name: user.displayName,
                  email: user.email,
                });

                auth.currentUser
                  .getIdToken(/* forceRefresh */ false)
                  .then(function (idToken) {
                    // Send token to your backend via HTTPS
                    // ...
                    console.log(
                      Math.floor(
                        (jwtDecode(idToken).exp - Date.now() / 1000) / 60,
                      ),
                    );

                    authC.setExpToken(
                      Math.floor(
                        (jwtDecode(idToken).exp - Date.now() / 1000) / 60,
                      ) - 2,
                    );

                    authC.setToken(idToken);
                    globalC.setAuthLoading(false);
                  })
                  .catch(function (error) {
                    // Handle error
                    globalC.setAuthLoading(false);
                  });
              } else {
                auth
                  .signOut()
                  .then(() => {
                    authC.logout();
                    globalC.setAuthLoading(false);

                    console.log('utilisateur déconnecté de firebase');
                  })
                  .catch((e) => {
                    console.log(e.message);
                    globalC.setAuthLoading(false);
                  });
              }
            })
            .catch((e) => {
              console.log(e.message);
              console.log('get accounts error');
              if (e.message === 'Missing or insufficient permissions.') {
                // Redirect to specific page, 'votre compte est en cours d'activation pas nos services'
                // navigate('/pendingConfirmation');

                authC.setIsAuth(true);
                globalC.setAuthLoading(false);
                authC.setUsersInfos(user);
                console.log('Login with provider ok, account is not created');
              }
              globalC.setAuthLoading(false);
            });
        } else {
          console.log('email not verified');
          authC.setIsAuth(true);
          authC.setUsersInfos(user);
          globalC.setAuthLoading(false);
        }
      } else {
        // No user is signed in.
        console.log('logged out');
        globalC.setAuthLoading(false);
      }
    });
  }

  useEffect(() => {
    const unsubscribe = readSession();

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (authC.expToken) {
      console.log('Next token update in ' + authC.expToken + 'minutes');
      const timer = setTimeout(() => {
        auth.currentUser &&
          auth.currentUser
            .getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
              authC.setExpToken(
                Math.floor((jwtDecode(idToken).exp - Date.now() / 1000) / 60) -
                  2,
              );

              authC.setToken(idToken);
            })
            .catch(function (error) {
              // Handle error
            });
      }, authC.expToken * 60 * 1000);
      return () => clearTimeout(timer);
    }
  }, [authC.expToken]);

  const AuthContent = (props) => {};

  const Content = (props) => {};

  return (
    <div
      className={clsx(
        // classes.main,
        classes.loginMain,
        {
          // deepcode ignore OperatorPrecedence: <please specify a reason of ignoring this>
          [classes.main]: !authC.isLoggedIn === true && !authC.isAuth,
          [classes.authMain]: authC.isLoggedIn === true,
          [classes.loginMainLoading]: globalC.authLoading,
        },
        // [classes.loginMain]:
        //   globalC.authLoading || (!authC.isLoggedIn === true && !authC.isAuth),
        // [classes.loginMainLoading]: globalC.authLoading,
      )}>
      <Snackbar
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={globalC.snack.open}
        onClose={() => globalC.setSnack({ ...globalC.snack, open: false })}>
        <Alert
          onClose={() => globalC.setSnack({ ...globalC.snack, open: false })}
          severity={globalC.snack.color}
          color={globalC.snack.color}>
          {globalC.snack.message}
        </Alert>
      </Snackbar>
      {globalC.authLoading && (
        <Bars
          wrapperClass={classes.loader}
          color={theme.palette.primary.dark}
          secondaryColor={theme.palette.secondary.main}
        />
      )}
      <CustomLoader />
      <Router>
        <div className={classes.root}>
          {/* if loading */}

          {/* Authenticated */}
          {authC.isLoggedIn === true && <DrawerComponent />}
          {authC.isLoggedIn === true && (
            <div className={classes.authContent}>
              {!globalC.authLoading && authC.isLoggedIn === true && (
                <div className={classes.appBar2} />
              )}
              <Routes>
                {!globalC.authLoading &&
                  authC.isLoggedIn === true &&
                  authC.type == 'superadmin' &&
                  adminRoutes
                    .concat({
                      main: () => <Navigate replace to="/events" />,
                      key: 'redirect',
                    })
                    .map((route) => {
                      return (
                        <Route
                          path={route.path}
                          key={route.path || route.key}
                          exact={route.exact}
                          public={route.public}
                          element={
                            <ProtectedRoute isLoggedIn={authC.isLoggedIn}>
                              {route.main()}
                            </ProtectedRoute>
                          }
                        />
                      );
                    })}
                {!globalC.authLoading &&
                  authC.isLoggedIn === true &&
                  authC.festivals.length > 0 &&
                  protectedRoutes
                    .concat({
                      main: () => <Navigate replace to="/events" />,
                      path: '*',
                      key: 'redirect',
                    })
                    .map((route) => (
                      <Route
                        path={route.path}
                        key={route.path || route.key}
                        exact={route.exact}
                        public={route.public}
                        element={
                          <ProtectedRoute isLoggedIn={authC.isLoggedIn}>
                            {route.main()}
                          </ProtectedRoute>
                        }
                      />
                    ))}
                {!globalC.authLoading &&
                  authC.isLoggedIn === true &&
                  authC.festivals.length == 0 &&
                  protectedRoutesNoEvent
                    .concat({
                      main: () => <Navigate replace to="/events" />,
                      path: '*',
                      key: 'redirect',
                    })
                    .map((route) => (
                      <Route
                        path={route.path}
                        key={route.path || route.key}
                        exact={route.exact}
                        public={route.public}
                        element={
                          <ProtectedRoute isLoggedIn={authC.isLoggedIn}>
                            {route.main()}
                          </ProtectedRoute>
                        }
                      />
                    ))}
              </Routes>
            </div>
          )}
          {/* Not authenticated */}
          {!authC.isLoggedIn === true && (
            <div className={classes.content}>
              <Routes>
                {!globalC.authLoading &&
                  // file deepcode ignore OperatorPrecedence: <please specify a reason of ignoring this>
                  !authC.isLoggedIn === true &&
                  authC.isAuth &&
                  othersRoutes
                    .concat({
                      main: () => (
                        <Navigate replace to="/pendingConfirmation" />
                      ),
                      path: '*',
                      key: 'redirect',
                    })
                    .map((route) => {
                      console.log('others route');
                      return (
                        <Route
                          key={route.path || route.key}
                          path={route.path}
                          exact={route.exact}
                          element={route.main()}
                        />
                      );
                    })}

                {!globalC.authLoading &&
                  !authC.isLoggedIn === true &&
                  !authC.isAuth &&
                  routes
                    .concat({
                      main: () => <Navigate replace to="/Login" />,
                      path: '*',
                      key: 'redirect',
                    })
                    .map((route) => (
                      <Route
                        key={route.path || route.key}
                        path={route.path}
                        exact={route.exact}
                        element={route.main()}
                      />
                    ))}
              </Routes>
            </div>
          )}
        </div>
      </Router>
      <Box className={classes.footer}>
        <Footer />
      </Box>
    </div>
  );
}
