import React, { createContext, useState, useEffect, useContext } from 'react';

export const EventStepperContext = createContext();

const EventStepperContextProvider = (props) => {
  // Context States
  const [selectedEventType, setSelectedEventType] = useState('Festival');
  const [inputEventname, setInputEventname] = useState('');
  const [selectedCapacity, setSelectedCapacity] = useState(1000);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedBeginDate, setSelectedBeginDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [imageAsFile, setImageAsFile] = useState('');
  const [logo, setLogo] = useState('');
  const [nbTel, setnbTel] = useState(10);
  const [nbSupports, setnbSupports] = useState(1);
  const [nbPtVente, setnbPtVente] = useState(10);
  const [nbPtRechargement, setnbPtRechargement] = useState(2);
  const [inputErrors, setInputErrors] = useState({
    location: false,
    name: false,
    capacity: false,
  });
  const [majorDefault, setMajorDefault] = useState(false);
  const [forceLogin, setForceLogin] = useState(false);
  const [donationEnabled, setDonationEnabled] = useState(false);
  const [specialBadgeRestriction, setSpecialBadgeRestriction] = useState(false);

  const [badgesTypes, setBadgesTypes] = useState({
    festivalier: true,
    Organisateur: false,
    Bénévole: false,
    Artiste: false,
    Prestataire: false,
  });

  const reset = () => {
    setSelectedEventType('Festival');
    setInputEventname('');
    setSelectedLocation('');
    setImageAsFile('');
    setSelectedCapacity(1000);
    setnbTel(10);
    setnbSupports(1);
    setnbPtVente(10);
    setnbPtRechargement(2);
    setSelectedBeginDate(new Date());
    setSelectedEndDate(new Date());
    setInputErrors({ location: false, name: false, capacity: false });
    setMajorDefault(false);
    setForceLogin(false);
    setDonationEnabled(false);
    setSpecialBadgeRestriction(false);
    setBadgesTypes({
      festivalier: true,
      Organisateur: false,
      Bénévole: false,
      Artiste: false,
      Prestataire: false,
    });
    setLogo('');
  };

  return (
    <EventStepperContext.Provider
      value={{
        nbTel,
        setnbTel,
        nbSupports,
        setnbSupports,
        selectedEventType,
        setSelectedEventType,
        inputEventname,
        setInputEventname,
        selectedCapacity,
        setSelectedCapacity,
        selectedLocation,
        setSelectedLocation,
        selectedBeginDate,
        setSelectedBeginDate,
        selectedEndDate,
        setSelectedEndDate,
        imageAsFile,
        setImageAsFile,
        nbPtVente,
        setnbPtVente,
        nbPtRechargement,
        setnbPtRechargement,
        inputErrors,
        setInputErrors,
        majorDefault,
        setMajorDefault,
        forceLogin, // Set to true to force phone number / qrcode scanning for badge creation
        setForceLogin,
        donationEnabled,
        setDonationEnabled,
        badgesTypes,
        setBadgesTypes,
        logo,
        setLogo,
        specialBadgeRestriction,
        setSpecialBadgeRestriction,
        reset,
      }}>
      {props.children}
    </EventStepperContext.Provider>
  );
};

export default EventStepperContextProvider;
