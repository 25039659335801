import React, { createContext, useState } from 'react';

export const StandContext = createContext();

const StandContextProvider = (props) => {
  const [selectedStand, setSelectedStand] = useState(
    props.router.location?.state && props.router.location?.state.fromEvent
      ? props.router.location.state.standId
      : '',
  );

  return (
    <StandContext.Provider
      value={{
        selectedStand,
        setSelectedStand,
      }}>
      {props.children}
    </StandContext.Provider>
  );
};

export default StandContextProvider;
