import { useMainStyles } from "../classes/main";
import { TextField } from "@mui/material";

  const EditableText = (props) => {
    const classes = useMainStyles();
  
    return (
      <TextField
        autoFocus={true}
        InputProps={{
          // disableUnderline: true,
          classes: {
            root: classes.standCardInput,
            disabled: classes.standCardInputDisabled,
          },
        }}
        id={props.id}
        type="string"
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
      />
    );
  };

  export default EditableText;