import React from 'react';
import { Navigate } from 'react-router-dom';

import {withRouter} from '../view/Component/withrouter-wrapper';

const ProtectedRoute = ({
  isLoggedIn,
  children,
  ...rest
}) => {
  const authed = isLoggedIn || rest.public;

  if (!authed) {
    return <Navigate replace to={{ pathname: '/Login' }} />;
  }

  return children;
};

export default withRouter(ProtectedRoute);
