import LoadingBar from 'react-top-loading-bar';

import { GlobalContext } from '../../context/globalContext';

import React, { useState, useEffect, useContext } from 'react';
const Loader = () => {
  const globalContext = useContext(GlobalContext);

  return (
    <LoadingBar
      height={5}
      color="rgb(255, 20, 0)"
      progress={globalContext.progress}
      onLoaderFinished={() => globalContext.setProgress(0)}
    />
  );
};

export default Loader;
