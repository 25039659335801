import React, { useState, useEffect, useContext, createContext } from 'react';

import SelectUsers from '../../Component/SelectUsers';
import EventFormInfos from '../../Component/EventFormInfos';

// Material UI

import { makeStyles } from '@material-ui/core/styles';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import 'date-fns';

import firebase, { db, storage } from '../../../firebase';

// Context

import { AuthContext } from '../../../context/authContext';
import { StoreContext } from '../../../context/storeContext';
import { GlobalContext } from '../../../context/globalContext';
import { EventStepperContext } from '../../../context/newEventContext';

// import { createLanguageService } from 'typescript';
import { isNull } from 'lodash';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    minHeight: '92vh',
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonForm: {
    margin: theme.spacing(2),
  },
  boxFlex: {
    display: 'flex',
  },
  boxFirstColumn: {
    display: 'flex',
    width: '30%',
    flexDirection: 'column',
    padding: 20,
    // backgroundColor: 'green',
  },
  boxSecondColumn: {
    display: 'flex',
    width: '30%',
    flexDirection: 'column',
    // backgroundColor: 'red',
    padding: 20,
  },
  boxThirdColumn: {
    display: 'flex',
    width: '30%',
    flexDirection: 'column',
    // backgroundColor: 'blue',
    padding: 20,
  },
  selectProfils: {
    marginLeft: '20%',
    marginBottom: theme.spacing(1),
  },
  instructions: {
    margin: theme.spacing(2),
  },
  formInput: {
    // margin: theme.spacing(2),
    width: '25ch',
  },
  form: {
    padding: theme.spacing(1),
    borderRadius: '10px',
    alignSelf: 'center',
  },
  divstandard: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function NewEventView() {
  const classes = useStyles();
  const store = useContext(StoreContext);
  const authContext = useContext(AuthContext);
  const globalContext = useContext(GlobalContext);
  const newEventContext = useContext(EventStepperContext);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();

  const handleChange = (event) => {
    newEventContext.setBadgesTypes({
      ...newEventContext.badgesTypes,
      [event.target.name]: event.target.checked,
    });
  };

  let navigate = useNavigate();
  useEffect(
    () => () => {
      newEventContext.reset();
    },
    [],
  );

  function getSteps() {
    if (authContext.type === 'superadmin') {
      return [
        'Définir un organisateur',
        'Définir un évènement',
        'Gérer les profils',
      ];
    } else if (authContext.type === 'admin') {
      return ['Définir un évènement', 'Gérer les profils'];
    }
  }

  const handleNext = () => {
    newEventContext.setInputErrors({
      name: false,
      capacity: false,
      location: false,
    });
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleEnd = () => {
    // console.log(orgaselectionne);
    db.collection('festivals')
      .add({
        creationDate: new Date(),
        name: newEventContext.inputEventname,
        badgeType: Object.entries(newEventContext.badgesTypes)
          .filter((e) => e[1] === true)
          .map((e) => {
            return { name: e[0], type: e[0] };
          }),
        logo: newEventContext.imageAsFile || '',
        start: newEventContext.selectedBeginDate,
        end: newEventContext.selectedEndDate,
        capacity: newEventContext.selectedCapacity,
        eventType: newEventContext.selectedEventType,
        location: newEventContext.selectedLocation,
        PtRecharge: newEventContext.nbPtRechargement,
        PtVente: newEventContext.nbPtVente,
        Nbsupport: newEventContext.nbSupports,
        NbTerminaux: newEventContext.nbTel,
        majorDefault: newEventContext.majorDefault,
        forceLogin: newEventContext.forceLogin,
        donationEnabled: newEventContext.donationEnabled,
        specialBadgeRestriction: newEventContext.specialBadgeRestriction,
      })
      .then((docRef) => {
        db.collection('festivals')
          .doc(docRef.id)
          .update({
            festival: docRef.id,
          })
          .then(() => {
            store.setLoading(false);
            //alert("Votre festival a été créé vous le retrouverez dans le dashboard");
            db.collection('accounts')
              .where(
                firebase.firestore.FieldPath.documentId(),
                'in',
                store.selectedUsers,
              )
              .get()
              .then((snapshot) => {
                snapshot.docs.forEach((doc) => {
                  // console.log(doc.data());

                  // Add event id in selected user festivals
                  doc.ref.update(
                    'festivals',
                    firebase.firestore.FieldValue.arrayUnion(docRef.id),
                  );
                });
              })
              .catch((err) => {
                console.error('Error getting accounts to update', err);
              });
            globalContext.setSnack({
              message:
                'Votre festival a été créé vous le retrouverez dans le dashboard',
              color: 'success',
              open: true,
            });
            //db.collection('users').doc.
            navigate('/events');
          })
          .catch((err) => {
            store.setLoading(false);
            console.log(err);
          });
      })
      .catch((err) => {
        store.setLoading(false);
        console.log(err);
      });
  };

  const handleFireBaseUpload = (cb) => {
    // console.log('start of upload');
    // async magic goes here...
    // console.log(newEventContext.imageAsFile);
    if (newEventContext.logo === '' || !newEventContext.logo) {
      console.error(
        `not an image, the image file is a ${typeof newEventContext.logo}`,
      );
      cb();
      //cb(undefined);
    } else {
      const uploadTask = storage
        .ref(`/logo-festival/${newEventContext.logo.name}`)
        .put(newEventContext.logo);
      uploadTask.on(
        'state_changed',
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          //console.log(snapShot);
        },
        (err) => {
          //catches the errors
          console.log(err);
          cb();
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          storage
            .ref('logo-festival')
            .child(newEventContext.logo.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              // console.log(fireBaseUrl);
              newEventContext.setImageAsFile(fireBaseUrl);
              cb();
            });
        },
      );
    }
  };

  const handleOrgaEnd = () => {
    // console.log('On entre dans handleOrgaEnd');
    db.collection('event_req')
      .add({
        creationDate: new Date(),
        emailrequester: authContext.userInfos.email,
        uidrequester: authContext.userInfos.uid,
        name: newEventContext.inputEventname,
        badgeType: Object.entries(newEventContext.badgesTypes)
          .filter((e) => e[1] === true)
          .map((e) => {
            return { name: e[0], type: e[0] };
          }),
        logo: newEventContext.imageAsFile || '',
        start: newEventContext.selectedBeginDate,
        end: newEventContext.selectedEndDate,
        capacity: newEventContext.selectedCapacity,
        eventType: newEventContext.selectedEventType,
        location: newEventContext.selectedLocation,
        PtRecharge: newEventContext.nbPtRechargement,
        PtVente: newEventContext.nbPtVente,
        updateDate: new Date(),
        status: 'pending',
      })
      .then(() => {
        store.setLoading(false);
        //alert("Votre festival a été créé vous le retrouverez dans le dashboard");
        globalContext.setSnack({
          message:
            "Votre demande de création d'évènement a été envoyé à nos équipes. Il sera validé prochainement et vous le retrouverez dans votre dashboard",
          color: 'success',
          open: true,
        });
        //db.collection('users').doc.
        navigate('/events');
      })
      .catch((err) => {
        store.setLoading(false);
        console.log(err);
      })
      .catch((err) => {
        store.setLoading(false);
        console.log(err);
      });
  };

  const orgaFormCheckInput = () => {
    let date = new Date();
    // console.log(activeStep);
    // console.log(authContext.type);
    if (authContext.type === 'superadmin' && activeStep === 0) {
      if (store.selectedUsers.length == '0') {
        // console.log(authContext.type);
        alert('Veuillez selectionner au moins un organisateur');
      } else {
        handleNext();
      }
    } else if (
      (activeStep === 1 && authContext.type === 'superadmin') ||
      (activeStep === 0 && authContext.type === 'admin')
    ) {
      if (newEventContext.selectedEndDate < newEventContext.selectedBeginDate) {
        alert('La date de fin ne peut pas être inférieure à la date de début');
      } else if (
        newEventContext.selectedEndDate < date ||
        newEventContext.selectedBeginDate < date
      ) {
        alert("Les dates d'évènement ne peuvent être choisies dans le passé");
        // console.log(date);
      } else if (newEventContext.inputEventname === '') {
        newEventContext.setInputErrors({
          ...newEventContext.inputErrors,
          name: true,
        });
        // handleStateEventname(true);
      } else if (
        isNaN(newEventContext.selectedCapacity) ||
        isNull(newEventContext.selectedCapacity) ||
        newEventContext.selectedCapacity === ''
      ) {
        newEventContext.setInputErrors({
          ...newEventContext.inputErrors,
          capacity: true,
        });
        alert('La capacité doit être un nombre entier !');
      } else if (newEventContext.selectedLocation === '') {
        newEventContext.setInputErrors({
          ...newEventContext.inputErrors,
          location: true,
        });
        // alert('Veuillez définir une localisation pour cet évènement');
      } else {
        handleFireBaseUpload(() => {
          handleNext();
        });
      }
    } else if (
      (activeStep === 2 && authContext.type === 'superadmin') ||
      (activeStep === 1 && authContext.type === 'admin')
    ) {
      if (authContext.type === 'superadmin') {
        handleEnd();
      } else {
        handleOrgaEnd();
      }
    }
  };

  const isStepOptional = (step) => {
    return step === 3;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step, isOrga) => {
    switch (step) {
      // Select orga
      case !isOrga ? 0 : 999:
        //console.log("Etape 1");
        return (
          <Box
            display="flex"
            alignSelf="center"
            justifyContent="center"
            flexWrap="wrap"
            width="100%">
            <Box display="flex" minWidth="50%">
              <SelectUsers />
            </Box>
          </Box>
        );

      // Event infos form
      case !isOrga ? 1 : 0:
        //console.log("Etape 2");
        return <EventFormInfos />;

      case !isOrga ? 2 : 1:
        return (
          <Box className={classes.form} bgcolor="white">
            <Typography className={classes.instructions}>
              Sélectionner les profils de consommateurs <br></br>
              (variante de prix par profil)
            </Typography>
            <FormGroup className={classes.selectProfils}>
              <FormControlLabel
                disabled
                control={
                  <Checkbox
                    checked={newEventContext.badgesTypes.festivalier}
                    name="Festivalier"
                  />
                }
                label="Festivalier"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEventContext.badgesTypes.Organisateur}
                    onChange={handleChange}
                    name="Organisateur"
                    color="primary"
                  />
                }
                label="Organisateur"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEventContext.badgesTypes.Bénévole}
                    onChange={handleChange}
                    name="Bénévole"
                    color="primary"
                  />
                }
                label="Bénévole"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEventContext.badgesTypes.Artiste}
                    onChange={handleChange}
                    name="Artiste"
                    color="primary"
                  />
                }
                label="Artiste"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newEventContext.badgesTypes.Prestataire}
                    onChange={handleChange}
                    name="Prestataire"
                    color="primary"
                  />
                }
                label="Prestataire"
              />
            </FormGroup>
          </Box>
        );
      default:
        return 'Etape inconnue';
    }
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Facultatif</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              C'est tout bon. Nous allons créer cet évènement
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Remise à zéro
            </Button>
          </div>
        ) : (
          <Box className={classes.divstandard}>
            <Box
              className={classes.boxFlex}
              display="flex"
              width="100%"
              flexDirection="row"
              alignItems="center">
              <Box p={1} flexGrow={1}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}>
                  Back
                </Button>
              </Box>
              {isStepOptional(activeStep) && (
                <Box p={1} flexGrow={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}>
                    passer
                  </Button>
                </Box>
              )}
              <Box p={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={/*handleNext*/ orgaFormCheckInput}
                  className={classes.button}>
                  {activeStep === steps.length - 1 ? 'Terminer' : 'Suivant'}
                </Button>
              </Box>
            </Box>
            {authContext.type === 'superadmin'
              ? getStepContent(activeStep, false)
              : getStepContent(activeStep, true)}
            {/* <Typography className={classes.instructions}></Typography> */}
          </Box>
        )}
      </div>
    </div>
  );
}
