import { createStyles, makeStyles } from '@material-ui/styles';

// Global Constant
const drawerWidth = 240;
const reduceDrawer = 70;

const leftBorder = '36px';
const rightBorder = '36px';

import theme from '../themes/default';

export const mainStyles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.grey.light,
    // backgroundColor: theme.palette.primary.light,
    // minHeight: '92vh',
    width: '100%',
  },
  authMain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    backgroundColor: theme.palette.grey.light,
    minHeight: '92vh',
    width: '100%',
  },
  loginMain: {
    minHeight: '100vh',
    width: '100%',
    justifyContent: 'space-between',
  },
  loginMainLoading: {
    backgroundColor: 'theme.palette.primary.light',
  },
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minHeight: '92vh',
    // paddingLeft: reduceDrawer,
  },
  authContent: {
    flexGrow: 1,
    width: '100%',
    paddingLeft: reduceDrawer,
    // alignSelf:'flex-start',
    // padding: theme.spacing(3),
  },
  content: {
    // flexGrow: 1,
    width: '100%',
    height: '100%',
    // background: 'red',
    // alignSelf:'flex-start',
    // padding: theme.spacing(3),
  },
  footer: {
    width: '100%',
    alignSelf: 'center',
    backgroundColor: theme.palette.grey.light,
  },
  header: {
    backgroundColor: theme.palette.grey.main,
    color: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'row',
    width:"100%",
    alignItems: 'center',
    justifyContent: 'space-between',
    // flexWrap: 'wrap',
    paddingLeft: leftBorder,
    paddingRight: rightBorder,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  body: {
    width: '100%',
    display: 'flex',
    paddingLeft: leftBorder,
    paddingRight: rightBorder,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  editableCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minHeight: '200px',
    width: '250px',
    margin: 10,
    padding: '5px 10px 10px 10px',
    borderRadius: 10,
    backgroundColor: theme.palette.white,
    boxShadow: 4,
  },
  largerCard: {
    width: '600px',
  },
  updatingEditableCard: {
    // width: '600px',
    border: '2px solid transparent',
  },
  updatingEditableCardFocused: {
    borderColor: theme.palette.primary.light,
  },
  genericCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: 10,
    borderRadius: 10,
    backgroundColor: theme.palette.white,
  },
  dashCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '400px',
    width: '800px',
    margin: 10,
    padding: '5px 10px 10px 10px',
    borderRadius: 10,
    backgroundColor: theme.palette.white,
    boxShadow: 4,
  },
  eventsTab: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textDisabled: {
    color: theme.palette.grey.dark,
  },
  standCardInput: {
    // color:theme.palette.primary.light,
    color: 'black',
    fontWeight: 'bold',
    border: '2px 2px 2px 2px solid',
    backgroundColor: theme.palette.primary.light + '30',
    borderColor: theme.palette.primary.dark,
    borderRadius: 10,
    paddingLeft: 10,
  },
  standCardInputDisabled: {
    color: 'black',
    fontWeight: 'normal',
    backgroundColor: 'transparent',
    paddingLeft: 0,
  },
  eventProgressBar: {
    backgroundColor: theme.palette.grey.light,
    width: '100%',
    borderRadius: 10,
  },
  userMenu: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    borderRadius: 20,
    backgroundColor: theme.palette.surface,
    color: theme.palette.primary.dark,
  },
  customPrimaryButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
    },
    padding: 0,
    textTransform: 'none',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey.light,
    borderRadius: 30,
  },
  button: {
    textTransform: 'none',
    borderRadius: 20,
    alignSelf: 'center',
    width: '50%',
    fontSize: 12,
  },
  buttonGroup: {
    backgroundColor: theme.palette.grey.main,
    color: theme.palette.primary.main,
  },
  buttonGroupSelected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  newEventButton: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    borderRadius: 20,
    width: '100%',
    color: theme.palette.primary.main,
  },

  search: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    borderRadius: 5,
    paddingLeft: 10,
    height: '80%',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: { width: '200px' },
    [theme.breakpoints.up('sm')]: { width: '250px' },
    [theme.breakpoints.up('lg')]: { width: '400px' },
  },
  searchInput: {
    [theme.breakpoints.down('xs')]: { width: '100px' },
    [theme.breakpoints.up('sm')]: { width: '150px' },
    [theme.breakpoints.up('lg')]: { width: '300px' },
    alignSelf: 'center',
  },
  searchIcon: {
    alignSelf: 'center',
    padding: 1,
  },
  optionIcon: {
    alignSelf: 'center',
    padding: 1,
  },
  cancelIcon: {
    alignSelf: 'center',
    padding: 1,
  },
  dashTitle: {
    [theme.breakpoints.down('md')]: { display: 'none' },
  },
  title: {
    color: theme.palette.grey.dark,
    marginTop: 20,
  },
  horizontalDivider: {
    marginTop: 10,
    marginBottom: 10,
  },
  selectEvent: {
    backgroundColor: 'white',
    borderRadius: 10,
    margin: 10,
    height: '80%',
    borderColor: 'white',
    [theme.breakpoints.down('md')]: { maxWidth: '200px' },
  },
  labels: {
    width: '30%',
  },
  addMenu: {
    backgroundColor: 'white',
    elevation: 1,
    borderColor: theme.palette.grey.main,
    border: '1px solid',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 10,
  },
  select: {
    color: theme.palette.secondary.main,
    marginLeft: 10,
    // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
  },
  categorySelectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  addStandButton: {
    margin: 10,
    width: '400px',
    [theme.breakpoints.down('md')]: { width: '200px' },
  },
  tabs: {
    borderBottom: '1px solid #e8e8e8',
    // width:'100%',
    // display: 'flex',
  },
  tabLabel: {
    // padding: 5,
  },
  tabsHeader: {
    // borderBottom: '1px solid #e8e8e8',
    width: '100%',
    // display:'flex',
    flexGrow: 1,
    // alignSelf:'center'
  },
  tabsHeaderContent: {
    // backgroundColor: 'blue',
  },

  tabElement: {
    backgroundColor: theme.palette.grey.light,
    borderRadius: '10px 10px 0px 0px',
    border: '2px solid',
    borderBottom: '0',
    borderColor: theme.palette.grey.main,
  },
  tabElementSelected: {
    borderBottom: '2px solid',
    backgroundColor: 'white',
    borderColor: theme.palette.primary.main,
    borderBottomColor: theme.palette.white,
    zIndex: 1,
    // border: '0px solid',
    // height:'100%',
    // borderRadius: '10px 10px 0px 0px',
  },
  eventsTabIndicator: {
    height: '5px',
  },
  tabIndicator: {
    display: 'none',
    height: '5px',
  },
  modal: {
    '&:focus': {
      outline: 'none',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '400px',
    minHeight: '300px',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.white,
    borderRadius: 15,
  },

  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: rightBorder,
  },
  appBar2: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.primary.light,
    height: '50px',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  menuButton: {
    // marginRight: 36,
    // padding: 0,
  },
  hide: {
    display: 'none',
  },
  headerLogo: {
    height: '56px',
    padding: '10px',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: theme.palette.primary.light,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: theme.palette.primary.light,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    // width: theme.spacing(7) + 1,
    width: reduceDrawer,
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9) + 1,
    // },
  },

  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '90vh',
    width: '100%',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '100%',
  },
  bobLogo: {
    alignSelf: 'center',
    padding: theme.spacing(2),
    // paddingTop: theme.spacing(10),
  },
  emoji: {
    padding: theme.spacing(2),
  },
  login: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
    marginTop: 30,
    padding: 15,
    // border: '0px solid',
    boxShadow: '10px 10px 10px 10px ' + theme.palette.primary.dark,
    borderColor: theme.palette.primary.main,
  },
  register: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 30,
    display: 'flex',
    flexDirection: 'row',
  },
  inarow: {
    // margin: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
  },
  logoinarow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    alignSelf: 'center',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    // borderRadius: 10,
    // padding: 10,
    // marginTop: theme.spacing(1),
    // color: 'white',
  },
  formInput: {
    margin: theme.spacing(2),
    width: '25ch',
  },
  input: {
    // color: 'white',
    color: theme.palette.primary.main,
    borderColor: 'white',
  },
  inputEmail: {
    width: theme.spacing(35),
    color: theme.palette.primary.main,
  },
  submit: {
    // margin: theme.spacing(1, 0, 2),
    color: 'white',
    fontSize: 20,
    // fontWeight: 'bold',
    width: '100%',
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  smallButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    // margin: theme.spacing(1, 0, 2),
    color: theme.palette.primary.main,
    fontSize: 14,
    // fontWeight: 'bold',
    borderRadius: 5,
    width: '25%',
    backgroundColor: 'transparent',
  },
  providerButton: {
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    // margin: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 15,
    // borderRadius: 5,
    // backgroundColor: theme.palette.primary.light,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  error: {
    color: theme.palette.danger,
  },
  confirmation: {
    display: 'flex',
    color: theme.palette.success.main,
    backgroundColor: 'white',
    borderRadius: 10,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  errorMessage: {
    display: 'flex',
    color: theme.palette.danger,
    backgroundColor: 'white',
    borderRadius: 10,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  primaryButton: {
    whiteSpace: 'nowrap',
    textTransform: 'none',
    borderRadius: 30,
    padding: 5,
    border: 0,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
  },
  secondaryButton: {
    border: 0,
    whiteSpace: 'nowrap',
    textTransform: 'none',
    borderRadius: 30,
    padding: 5,
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
  },
  addItemButton: {
    display: 'flex',
    marginTop: theme.spacing(8),
  },
  closeModal: {
    borderTop: '1px solid',
    borderColor: theme.palette.grey.main,
    color: theme.palette.primary.main,
    width: '100%',
  },
  boxColumn: {
    display: 'flex',
    // width: '30%',
    flexDirection: 'column',
    padding: 20,
  },
};

const tooltipStyles = {
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    margin: 0,
    padding: 0,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
};

export const useTootipStyles = makeStyles(
  (theme) => createStyles(tooltipStyles, { defaultTheme: theme }),
  { index: 2 },
);

export const useMainStyles = makeStyles(
  (theme) => createStyles(mainStyles, { defaultTheme: theme }),
  { index: 1 },
);

export default mainStyles;
