import React from 'react';
import Requests from '../view/subViews/Requests/Requests';
import Users from '../view/subViews/Users/Users';
import ComptaDevis from '../view/subViews/Financials/ComptaDevis';

import protectedRoutes from './protectedRoutes';

const adminRoutes = protectedRoutes.concat([
  {
    name: 'Users',
    exact: true,
    path: '/users',
    main: (props) => <Users {...props} />,
    public: false,
  },
  {
    name: 'Requests',
    exact: true,
    path: '/requests',
    main: (props) => <Requests {...props} />,
    public: false,
  },
  {
    name: 'ComptaDevis',
    exact: true,
    path: '/comptadevis',
    main: (props) => <ComptaDevis {...props} />,
    public: false,
  },
]);

export default adminRoutes;
