import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../../context/authContext';
import { StoreContext } from '../../../context/storeContext';
import { db } from '../../../firebase';
import axios from 'axios';

import StyledMaterialTable from '../../Component/StyledMaterialTable';

import {
  Box,
  MenuItem,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
  Chip,
  TextField,
} from '@mui/material';
// } from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

import { useMainStyles } from '../../classes/main';
import { GlobalContext } from '../../../context/globalContext';

const UsersView = () => {
  const authContext = useContext(AuthContext);
  const store = useContext(StoreContext);
  const globalC = useContext(GlobalContext);

  const classes = useMainStyles();

  const updateUser = (id, newData, cb) => {
    db.collection('accounts')
      .doc(id)
      .update({
        type: newData.type,
        displayName: newData.displayName,
        festivals: newData.festivals,
      })
      .then(() => {
        store.setLoading(false);
        cb();
      })
      .catch((err) => cb(err));
  };
  const deleteUser = (id, cb) => {
    return axios
      .get(process.env.REACT_APP_BACKEND_URL + '/api/deleteUser?id=' + id, {
        headers: {
          Authorization: `Bearer ${authContext.token}`,
        },
      })
      .then((response) => {
        console.log(response);
        cb();
      })
      .catch((err) => cb(err));
  };

  useEffect(() => {
    const unsubscribe = store.loadUsers();
    globalC.setActiveMenu(4);
    
    return () => unsubscribe && unsubscribe();
  }, []);

  return (
    <div>
      {authContext.type === 'superadmin' && (
        <Box className={classes.main} mt={2}>
          <div className={classes.main}>
            <div className={classes.body}>
              <StyledMaterialTable
                columns={[
                  { title: 'ID', field: 'id', hidden: true },
                  {
                    title: 'Nom',
                    field: 'displayName',
                    editComponent: (props) => (
                      <TextField
                        defaultValue={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                        label="Nom"
                        variant="outlined"
                      />
                    ),
                  },
                  {
                    title: 'Profil',
                    field: 'type',
                    editComponent: (props) => (
                      <Select
                        id="select-type"
                        value={props.value}
                        onChange={(e) => props.onChange(e.target.value)}
                        style={{ flex: 1 }}>
                        <MenuItem value={'superadmin'}>Super admin</MenuItem>
                        <MenuItem value={'admin'}>
                          Organisateur de festival
                        </MenuItem>
                      </Select>
                    ),
                  },
                  {
                    title: 'Festivals',
                    field: 'festivals',
                    editComponent: (props) => (
                      <FormControl fullWidth={true}>
                        <InputLabel>Evènements</InputLabel>
                        <Select
                          id="users-multiple-select-users"
                          multiple
                          value={props.value}
                          onChange={(event) => {
                            props.onChange(
                              typeof event.target.value === 'string'
                                ? event.target.value.split(',')
                                : event.target.value,
                            );
                          }}
                          input={
                            <OutlinedInput
                              id="select-multiple-chip"
                              label="Chip"
                            />
                          }
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={
                                    store.festivals.find((f) => f.id === value)
                                      ?.name
                                  }
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}>
                          {store.festivals.map((event) => (
                            <MenuItem key={event.id} value={event.id}>
                              {event.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ),
                    // hidden: true,
                    render: (rowData) => (
                      <div>
                        {rowData.festivals?.map((festival) => {
                          return (
                            store.festivals.find((f) => f.id === festival)
                              ?.name + ' ; '
                          );
                        })}
                      </div>
                    ),
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
                }}
                data={store.users}
                title="Gestion des utilisateurs"
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        console.log(newData);
                        updateUser(oldData.id, newData, (err) => {
                          if (err) reject(err);
                          else resolve();
                        });
                      }, 1000);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        deleteUser(oldData.id, (err) => {
                          store.setLoading(false);
                          if (err) reject(err);
                          else resolve();
                        });
                      }, 1000);
                    }),
                }}
              />
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};

export default UsersView;
