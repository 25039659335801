import React, { createContext, useState, useEffect, useContext } from 'react';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [userInfos, setUsersInfos] = useState();
  const [token, setToken] = useState();
  const [expToken, setExpToken] = useState();
  const [festival, setFestival] = useState();
  const [selectedFestival, setSelectedFestival] = useState(localStorage.getItem('currentEvent'));
  const [festivals, setFestivals] = useState([]);
  const [type, setType] = useState();

  const logout = () => {
    console.log("clearing auth context");
    setLoggedIn(false);
    setIsAuth(false);
    setUsersInfos(null);
    setToken(null);
    setExpToken(null);
    setFestival(null);
    setSelectedFestival(null);
    setFestivals(null);
    setType(null);
  };

  useEffect(() => {
    console.log("useEffect localStorage");
    // Sauvegarde du selectedFestival dans le localStorage pour les prochaines connexions
    localStorage.setItem('currentEvent', selectedFestival);
  }, [selectedFestival]);
  
  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
        isLoggedIn,
        setLoggedIn,
        userInfos,
        setUsersInfos,
        token,
        setToken,
        expToken,
        setExpToken,
        festival,
        setFestival,
        selectedFestival,
        setSelectedFestival,
        festivals,
        setFestivals,
        type,
        setType,
        logout,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
