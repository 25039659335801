import React, { useState, useContext, useEffect, useRef } from 'react';

import {
  Badge,
  Chip,
  Typography,
  Box,
  Button,
  Select,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  TextField,
} from '@material-ui/core';

import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../../context/authContext';
import { GlobalContext } from '../../../context/globalContext';
import { StoreContext } from '../../../context/storeContext';

import { db } from '../../../firebase';

import {
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  ShoppingCart as ShoppingCartIcon,
  Euro as EuroIcon,
} from '@mui/icons-material';

// Style
import { useTheme } from '@material-ui/core/styles';
import { useMainStyles } from '../../classes/main';
import clsx from 'clsx';
import * as config from '../../config/globalValues';

const StandCard = (props) => {
  const classes = useMainStyles();
  const theme = useTheme();

  const store = useContext(StoreContext);
  const globalC = useContext(GlobalContext);

  const [updating, setUpdating] = useState(false);
  const [name, setName] = useState(props.stand.shopName);
  const [type, setType] = useState(props.stand.type);
  const [presta, setPresta] = useState(props.stand.presta || '-');

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  let navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChoice = (event) => {
    event.target.value && event.target.value === 2
      ? disableStand()
      : event.target.value === 1
      ? duplicateStand()
      : event.target.value === 0
      ? setUpdating(true)
      : '';
    // console.log(event.target.value);
    setAnchorEl(null);
  };
  const cancelUpdate = () => {
    setUpdating(false);
    setName(props.stand.shopName);
    setType(props.stand.type);
    setPresta(props.stand.presta);
  };
  const handleUpdateChoice = (event) => {
    event.target.value && event.target.value === 1
      ? cancelUpdate()
      : event.target.value === 0
      ? updateStand()
      : '';
    // console.log(event.target.value);
    setAnchorEl(null);
  };

  const updateOptions = ['sauvegarder', 'annuler'];
  const options = ['modifier', 'dupliquer', 'supprimer'];

  const duplicateStand = () => {
    try {
      db.collection('stands')
        .add({
          disabled: false,
          shopName: props.stand.shopName,
          presta: props.stand.presta || '',
          festival: props.stand.festival,
          type: props.stand.type,
        })
        .then((doc) => {
          // console.log(doc);

          // Duplicates items
          store.items
            .filter((i) => i.stand === props.stand.id)
            .forEach((i) => {
              const { id, stand, ...item } = i;
              // console.log({ ...item, stand: doc.id });
              db.collection('items').add({ ...item, stand: doc.id });
            });
          globalC.setSnack({
            open: true,
            message: 'Le point de vente a été dupliqué.',
            color: 'success',
          });
        });
    } catch (err) {
      globalC.setSnack({
        ...globalC.snack,
        open: true,
        message: err.message,
        color: 'error',
      });
    }
  };

  const disableStand = () => {
    db.collection('stands')
      .doc(props.stand.id)
      .update({ disabled: true })
      .then(() => {
        globalC.setSnack({
          ...globalC.snack,
          open: true,
          message: 'Le point de vente a été supprimé.',
        });
      })
      .catch((err) => console.log(err.message));
  };

  const updateStand = () => {
    setUpdating(false);
    // setIsUpdating({ ...isUpdating, name: false });
    db.collection('stands')
      .doc(props.stand.id)
      .update({
        shopName: name,
        presta,
        type,
      })
      .then(() => {
        globalC.setSnack({
          ...globalC.snack,
          open: true,
          message: 'Le point de vente a été modifié.',
        });
      })
      .catch((err) =>
        globalC.setSnack({
          ...globalC.snack,
          open: true,
          message: err.message,
          color: 'error',
        }),
      );
  };

  useEffect(() => {
    setName(props.stand.shopName);
    setPresta(props.stand.presta);
    setType(props.stand.type);
  }, [props]);

  const innerRef = useRef(null);
  const shopNameRef = useRef(null);

  const [focused, setFocused] = useState(false);

  const handleKeyDown = (ev) => {
    // console.log(ev);
    ev.key === 'Escape' ? cancelUpdate() : ev.key === 'Enter' && updateStand();
  };

  useEffect(() => {
    if (updating) {
      shopNameRef.current.focus();
    }
  }, [updating]);

  useEffect(() => {
    const div = innerRef.current;

    if (updating) {
      div.addEventListener('keydown', handleKeyDown, false);
      return () => {
        div.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [updating, name, presta]);

  return (
    <Box
      onFocus={() => updating && setFocused(true)}
      onBlur={() => setFocused(false)}
      ref={innerRef}
      boxShadow={2}
      className={clsx(classes.editableCard, {
        [classes.updatingEditableCard]: updating,
        [classes.updatingEditableCardFocused]: focused,
      })}>
      <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        badgeContent={
          props.stand.type === 'debit' ? (
            <ShoppingCartIcon style={{ fontSize: 15 }} color="primary" />
          ) : (
            <EuroIcon style={{ fontSize: 15 }} color="primary" />
          )
        }
        color="secondary">
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between">
              <TextField
                ref={shopNameRef}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.standCardInput,
                    disabled: classes.standCardInputDisabled,
                  },
                }}
                style={{ padding: 8 }}
                id="name"
                type="string"
                disabled={!updating}
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
              {/* {!updating && (
                <Typography variant="caption" style={{padding:8}}>
                  {type == 'credit' ? 'Point de rechargement' : 'Point de vente'}
                </Typography>
              )} */}
              <Box display="flex" style={{ padding: 8, alignItems: 'center' }}>
                <Typography variant="caption" style={{ paddingRight: 10 }}>
                  Prestataire:{' '}
                </Typography>

                {updating ? (
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.standCardInput,
                        disabled: classes.standCardInputDisabled,
                      },
                    }}
                    id="presta"
                    type="string"
                    disabled={!updating}
                    value={presta}
                    onChange={(event) => {
                      setPresta(event.target.value);
                    }}
                  />
                ) : (
                  <Typography variant="caption">{presta || '-'}</Typography>
                )}
                {/* {presta || '-'} */}
              </Box>
            </Box>
            <Box alignSelf="flex-start">
              {updating ? (
                <IconButton
                  className={classes.optionIcon}
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={() => cancelUpdate()}>
                  <CloseIcon />
                </IconButton>
              ) : (
                <IconButton
                  className={classes.optionIcon}
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
              )}
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleChoice}>
                {!updating
                  ? options.map((option, index) => (
                      <MenuItem
                        value={index}
                        key={index}
                        onClick={handleChoice}>
                        {option}
                      </MenuItem>
                    ))
                  : updateOptions.map((option, index) => (
                      <MenuItem
                        value={index}
                        key={index}
                        onClick={handleUpdateChoice}>
                        {option}
                      </MenuItem>
                    ))}
              </Menu>
            </Box>
          </Box>
          <Divider />

          {props.stand.type === 'debit' && (
            <Box width="100%" p={1}>
              <Button
                onClick={() => {
                  // globalC.setSelectedStand(props.stand.id);
                  // globalC.setSelectedStandIndex(
                  //   store.stands
                  //     .filter((e) => e.type === 'debit')
                  //     .filter((e) => e.festival === authC.selectedFestival)
                  //     .findIndex((e) => e.id === props.stand.id),
                  // );

                  navigate('/stands', {
                    state: { fromEvent: true, standId: props.stand.id },
                  });
                }}
                className={classes.customPrimaryButton}>
                <Typography variant="subtitle1">
                  {props.nbItems || '0'} produit{props.nbItems > 1 ? 's' : ''}{' '}
                  défini{props.nbItems > 1 ? 's' : ''}
                </Typography>
                {/* <Chip className={classes.labels} label={props.nbItems || '0'} /> */}
              </Button>
            </Box>
          )}
          {/* </Box> */}
          <Box width="100%" p={1}>
            <Button
              onClick={() => {
                // globalC.setSelectedStand(props.stand.id);
                // globalC.setSelectedStandIndex(
                //   store.stands
                //     .filter((e) => e.festival === authC.selectedFestival)
                //     .findIndex((e) => e.id === props.stand.id),
                // );

                navigate('/devices', {
                  state: { fromEvent: true, standId: props.stand.id },
                });
              }}
              className={classes.customPrimaryButton}>
              <Typography variant="subtitle1">
                {props.nbPairedDevices || '0'}/{props.nbDevices || '0'} appareil
                {props.nbPairedDevices > 1 ? 's' : ''} associé
                {props.nbPairedDevices > 1 ? 's' : ''}
              </Typography>
              {/* <Chip className={classes.labels} label={props.nbDevices || '0'} />{' '} */}
            </Button>
          </Box>
          {/* 
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            m={1}>
            <Typography variant="caption" className={classes.textDisabled}>
              Nb d'appareils
            </Typography>
            <Chip className={classes.labels} label={props.nbDevices || '0'} />
          </Box> */}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            m={1}>
            <Typography variant="caption" className={classes.textDisabled}>
              Etat
            </Typography>
            <Chip
              className={classes.labels}
              style={{
                color:
                  props.nbDevices > 0 &&
                  (props.type === 'debit' ? props.nbItems > 0 : true)
                    ? theme.palette.success.dark
                    : theme.palette.secondary.main,
                backgroundColor:
                  props.nbDevices > 0 &&
                  (props.type === 'debit' ? props.nbItems > 0 : true)
                    ? theme.palette.success.light
                    : theme.palette.secondary.light,
              }}
              // color={
              //   props.nbDevices > 0 && props.nbItems > 0 ? 'success' : 'secondary'
              // }
              label={
                props.nbDevices > 0 && props.nbItems > 0 ? 'Actif' : 'Inactif'
              }
            />
          </Box>
          {
            updating && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => {
                  updateStand();
                }}>
                Sauvegarder
              </Button>
            )
            // : (
            //   <Button
            //     variant="contained"
            //     color="primary"
            //     className={classes.button}
            //     onClick={() => {
            //       console.log('setting global selectedstand');
            //       console.log(props.stand.id);
            //       globalC.setSelectedStand(props.stand.id);
            //       globalC.setSelectedStandIndex(
            //         store.stands
            //           .filter((e) => e.type === 'debit')
            //           .filter((e) => e.festival === authC.selectedFestival)
            //           .findIndex((e) => e.id === props.stand.id),
            //       );

            //       navigate({
            //         pathname: '/stands',
            //         state: { fromEvent: true },
            //       });
            //     }}>
            //     Accéder
            //   </Button>
            // )
          }
        </Box>
      </Badge>
    </Box>
  );
};

const AddStand = (props) => {
  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);
  const globalC = useContext(GlobalContext);

  const classes = useMainStyles();
  const [choice, setChoice] = useState(0);

  const [text, setText] = useState(undefined);
  const [text2, setText2] = useState(undefined);

  const choices = ['point de vente', 'point de rechargement'];

  const createDebitStand = (shopName, presta) => {
    store.setLoading(true);

    if (shopName != '' && presta != '') {
      db.collection('stands')
        .add({
          festival: authC.selectedFestival,
          shopName,
          presta,
          type: 'debit',
          disabled: false,
        })
        .then(() => {
          store.setLoading(false);
          globalC.setSnack({
            ...globalC.snack,
            open: true,
            message: 'Point de vente ajouté',
            color: 'success',
          });
          setText(undefined);
          setText2(undefined);
        })
        .catch((err) => {
          globalC.setSnack({
            ...globalC.snack,
            open: true,
            message: err.message,
            color: 'error',
          });
          console.log(err);
        });
    } else {
      globalC.setSnack({
        ...globalC.snack,
        open: true,
        message: 'Les champs ne doivent pas être vides',
        color: 'error',
      });
    }
  };

  const createRefillStand = (shopName) => {
    store.setLoading(true);
    db.collection('stands')
      .add({
        festival: authC.selectedFestival,
        shopName,
        type: 'credit',
        disabled: false,
      })
      .then(() => {
        store.setLoading(false);
        globalC.setSnack({
          ...globalC.snack,
          open: true,
          message: 'Point de rechargement ajouté',
          color: 'success',
        });
        setText(undefined);
      })
      .catch((err) => {
        globalC.setSnack({
          ...globalC.snack,
          open: true,
          message: err.message,
          color: 'error',
        });
        console.log(err);
      });
  };

  return (
    <Box className={classes.addMenu}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        m={1}>
        <Typography variant="body1">J'ajoute un</Typography>
        <Select
          className={classes.select}
          value={choice}
          onChange={(e) => {
            // console.log(e.target.value);
            setChoice(e.target.value);
          }}>
          {choices.map((e, i) => (
            <MenuItem key={i} value={i}>
              {e}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        flexDirection="row"
        width="100%"
        alignItems="center"
        display={choice === 1 ? 'none' : 'flex'}>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <TextField
            error={text === '' || text?.length > config.MAX_STAND_NAME}
            value={text || ''}
            onChange={(e) => setText(e.target.value)}
            className={classes.addStandButton}
            variant="outlined"
            label="Nom du point de vente *"
            helperText={
              '* obligatoire, ' + config.MAX_STAND_NAME + ' caractères maximum'
            }></TextField>
          <TextField
            error={text2 === '' || text2?.length > config.MAX_STAND_NAME}
            value={text2 || ''}
            onChange={(e) => setText2(e.target.value)}
            className={classes.addStandButton}
            variant="outlined"
            label="Nom du prestataire *"></TextField>
        </Box>
        <Box display="flex" flexDirection="column" ml={5}>
          <Button
            disabled={
              !text ||
              text === '' ||
              !text2 ||
              text2 === '' ||
              text?.length > config.MAX_STAND_NAME ||
              text2?.length > config.MAX_STAND_NAME
            }
            variant="contained"
            color="secondary"
            style={{ margin: 5 }}
            onClick={() => createDebitStand(text, text2)}>
            Ajouter
          </Button>
        </Box>
      </Box>
      <Box
        // display="flex"
        flexDirection="row"
        width="100%"
        alignItems="center"
        display={choice === 0 ? 'none' : 'flex'}>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <TextField
            error={text === ''}
            value={text || ''}
            onChange={(e) => setText(e.target.value)}
            className={classes.addStandButton}
            variant="outlined"
            label="Nom du point de rechargement *"
            helperText="* obligatoire"></TextField>
        </Box>
        <Box display="flex" flexDirection="column" ml={5}>
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: 5 }}
            disabled={!text || text === ''}
            onClick={() => createRefillStand(text)}>
            Ajouter
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const ConfigTab = (props) => {
  const classes = useMainStyles();

  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);

  return (
    <div>
      <Typography variant="subtitle1" className={classes.title}>
        AJOUTER
      </Typography>
      <Divider className={classes.horizontalDivider} />
      <AddStand />
      <Typography variant="subtitle1" className={classes.title}>
        POINTS DE VENTES
      </Typography>
      <Divider className={classes.horizontalDivider} />
      <div className={classes.eventsTab}>
        {store.stands
          .filter(
            (s) => s.festival === authC.selectedFestival && s.type === 'debit',
          )
          .map((stand, index) => {
            return (
              <StandCard
                stand={stand}
                key={index}
                nbDevices={
                  store.devices.filter((d) => {
                    return d.stand === stand.id && d.disabled === false;
                  }).length
                }
                nbPairedDevices={
                  store.devices.filter((d) => {
                    return (
                      d.stand === stand.id &&
                      d.paired === true &&
                      d.disabled === false
                    );
                  }).length
                }
                nbItems={store.items.filter((i) => i.stand === stand.id).length}
              />
            );
          })}
      </div>
      <Typography variant="subtitle1" className={classes.title}>
        POINTS DE RECHARGEMENT
      </Typography>
      <Divider className={classes.horizontalDivider} />
      <div className={classes.eventsTab}>
        {store.stands
          .filter(
            (s) => s.festival === authC.selectedFestival && s.type === 'credit',
          )
          .map((stand, index) => {
            return (
              <StandCard
                stand={stand}
                key={index}
                nbDevices={
                  store.devices.filter(
                    (d) => d.stand === stand.id && d.disabled === false,
                  ).length
                }
                nbPairedDevices={
                  store.devices.filter(
                    (d) =>
                      d.stand === stand.id &&
                      d.disabled === false &&
                      d.paired === true,
                  ).length
                }
              />
            );
          })}
      </div>
    </div>
  );
};

export default ConfigTab;
