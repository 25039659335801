import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { AuthContext } from '../../../context/authContext';
import { StoreContext } from '../../../context/storeContext';
import { GlobalContext } from '../../../context/globalContext';
import { StandContext } from '../../../context/standContext';

import StyledMaterialTable from '../../Component/StyledMaterialTable';
import CustomEmojiPickjer from '../../Component/emojiPicker';
import TableWithTabs from '../../Component/TableWithTabs';

import CategorySelect from '../../Component/SelectCategories';

// Firebase
import { db } from '../../../firebase';

// Style
import { useTheme } from '@material-ui/core/styles';
import { useMainStyles } from '../../classes/main';
import PropTypes from 'prop-types';

// Material Component

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
// } from '@material-ui/core';

import { Delete } from '@mui/icons-material';
// } from '@material-ui/icons';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function TabStand(props) {
  const { children, value, index, ...other } = props;
  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);
  const globalC = useContext(GlobalContext);

  const [open, setOpen] = useState(false);
  const [pricesTypes, setPricesTypes] = useState([]);

  const [dialogConfirm, setDialogConfirm] = useState({ message: '' });

  const [standIDs, setStandIDs] = useState([]);

  const theme = useTheme();

  const basesColumns = [
    { title: 'ID', field: 'id', hidden: true },
    { title: 'Nom du produit', field: 'name' },
    { title: 'Boutique associée', field: 'stand', hidden: true },
    { title: 'Festival', field: 'festival', hidden: true },
    {
      title: 'Logo',
      field: 'logo',
      type: 'string',
      editComponent: (props) => (
        <CustomEmojiPickjer
          defaultEmoji={props.value}
          setChosenEmoji={(value) => {
            props.onChange(value);
          }}
        />
      ),
    },
    {
      title: 'Catégorie',
      field: 'categoryID',
      editComponent: (props) => {
        return (
          <CategorySelect
            categoryID={props.value}
            category={store.categories.find((e) => e.id == props.value)?.name}
            onChange={(value) => {
              if (value === null) {
                props.onChange('');
              } else {
                props.onChange(value.value);
              }
            }}
            onCreateOption={(inputValue) => {
              console.log(inputValue);
              props.onChange(inputValue);
            }}
          />
        );
      },
      render: (rowData) => {
        return (
          <Typography>
            {store.categories.find((e) => e.id == rowData.categoryID)?.name}
          </Typography>
        );
      },
    },
    { title: 'Alcoolisé', field: 'isAlcool', type: 'boolean' },
    { title: 'Favoris', field: 'isFavorite', type: 'boolean' },
    // { title: 'Prix', field: 'pricesList' },
    //{ title: 'PrixListe', field: 'prices', hidden: true },
  ];

  const [columns, setColumns] = useState(basesColumns);

  const handleClose = () => {
    setOpen(false);
  };

  const updateItem = (
    id,
    name,
    logo,
    prices,
    isAlcool,
    isFavorite,
    categoryID,
    cb,
  ) => {
    store.setLoading(true);
    try {
      db.collection('items')
        .doc(id)
        .update({
          logo,
          name,
          prices,
          isAlcool,
          isFavorite,
          categoryID,
        })
        .then((res) => {
          store.setLoading(false);
          cb();
        })
        .catch((err) => {
          console.log(err);
          store.setLoading(false);
          cb(err);
        });
    } catch (err) {
      console.log(err);
      store.setLoading(false);
      cb(err);
    }
  };

  const deleteItem = (id, cb) => {
    db.collection('items')
      .doc(id)
      .delete()
      .then(() => {
        cb();
      })
      .catch((err) => cb(err));
  };

  useEffect(() => {
    console.log('useeffect pricesTypes - tabitems');

    const pricesTypesConst =
      authC.selectedFestival &&
      store.festivals
        ?.find((f) => f.id === authC.selectedFestival)
        ?.badgeType?.map((e) => e.type)
        ?.filter(onlyUnique);

    authC.selectedFestival && setPricesTypes(pricesTypesConst);

    pricesTypesConst &&
      setColumns([
        ...basesColumns,
        ...pricesTypesConst.map((e) => {
          //console.log(e);
          return {
            title: `Prix ${e}`,
            field: e,
            type: 'numeric',
            editComponent: (props) => {
              // console.log(props);
              return (
                <TextField
                  type="number"
                  value={
                    props.value === ''
                      ? ''
                      : props.value !== undefined
                      ? props.value
                      : props.rowData.prices[e]
                  }
                  onChange={(i) => props.onChange(i.target.value)}
                />
              );
            },
            render: (rowData) => {
              return (
                <Typography>{JSON.parse(rowData.pricesList)[e]}</Typography>
              );
            },
          };
        }),
      ]);
  }, [authC.selectedFestival, store.festivals, store.categories]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {dialogConfirm.message}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Refuser
          </Button>
          <Button
            onClick={() => {
              store.setLoading(true);
              handleClose();
              const promises = standIDs.map((s) => {
                return new Promise((resolve, reject) => {
                  deleteItem(s, (err) => {
                    if (err) reject(err);
                    else resolve();
                  });
                });
              });
              Promise.all(promises)
                .then(() => {
                  globalC.setSnack({
                    ...globalC.snack,
                    message: 'Produits supprimés',
                    color: 'success',
                    open: true,
                  });
                })
                .catch((err) => {
                  console.log(err);
                  globalC.setSnack({
                    ...globalC.snack,
                    message: err.message,
                    color: 'error',
                    open: true,
                  });
                });
            }}
            color="primary"
            autoFocus>
            Accepter
          </Button>
        </DialogActions>
      </Dialog>
      {value === index && (
        <StyledMaterialTable
          title={null}
          style={{
            borderRadius: 15,
            boxShadow: 'none',
            border: '2px solid',
            color: theme.palette.primary.main,
            top: '-2px',
          }}
          columns={columns}
          options={{
            selection: true,
            actionsColumnIndex: -1,
            padding: 'dense',
            pageSize: 7,
            pageSizeOptions: [7, 10, 20, 30, 50, 100],
            // columnsButton: true,
            // tableLayout: 'fixed'
          }}
          data={store.items.filter((item) => item.stand === value)}
          // title="Produits"
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const prices = pricesTypes
                    .map((priceType) => {
                      console.log('on row update');

                      if (typeof newData[priceType] == 'string') {
                        return newData[priceType];
                      } else {
                        return oldData.prices[priceType];
                      }
                    })
                    .reduce(function (result, item, index, array) {
                      result[pricesTypes[index]] = item;
                      return result;
                    }, {});

                  updateItem(
                    newData.id,
                    newData.name,
                    newData.logo,
                    prices,
                    newData.isAlcool,
                    newData.isFavorite,
                    newData.categoryID,
                    (err) => {
                      if (err) {
                        globalC.setSnack({
                          message: err.message,
                          color: 'error',
                          open: true,
                        });
                        reject(err);
                      } else resolve();
                    },
                  );
                  // resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  deleteItem(oldData.id, (err) => {
                    if (err) reject(err);
                    else resolve();
                  });
                }, 1000);
              }),
          }}
          actions={[
            {
              tooltip: 'Supprimer les produits sélectionnés',
              icon: () => <Delete />,
              onClick: (evt, data) => {
                // console.log(data);
                setDialogConfirm({
                  ...dialogConfirm,
                  message: `Confirmez la suppression de ${data.length} produits`,
                });
                setStandIDs(data.map((d) => d.id));
                setOpen(true);
              },
            },
          ]}
        />
      )}
    </div>
  );
}

TabStand.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const TabItemByStand = (props) => {
  const authC = useContext(AuthContext);
  const store = useContext(StoreContext);
  const standC = useContext(StandContext);

  const classes = useMainStyles();

  const handleTabChange = (event, newValue) => {
    standC.setSelectedStand(newValue);
  };

  const resetSelectedStand = () => {
    standC.setSelectedStand(
      store?.stands?.filter(
        (s) => s.festival === authC.selectedFestival && s.type === 'debit',
      )[0]?.id,
    );
  };
  useEffect(() => {
    resetSelectedStand();
  }, [authC.selectedFestival]);

  return (
    <div>
      <Typography variant="subtitle1" className={classes.title} mt={1}>
        LISTE DES PRODUITS
      </Typography>
      <Divider className={classes.horizontalDivider} />
      <TableWithTabs
        standFilter={(stand) => stand.type === 'debit'}
        handleTabChange={handleTabChange}
        selectedStand={standC.selectedStand}
        chip={(standId) => {
          return store.items.filter(
            (device) =>
              device.festival === authC.selectedFestival &&
              device.stand === standId,
          ).length;
        }}>
        <TabStand
          value={standC.selectedStand || ''}
          index={standC.selectedStand || ''}
          key={standC.selectedStand || ''}
        />
      </TableWithTabs>
    </div>
  );
};

export default TabItemByStand;
