// React
import React from 'react';

// Component
import Main from './Main';

// Context
import StoreContextProvider from '../context/storeContext';
import AuthContextProvider from '../context/authContext';
import GlobalContextProvider from '../context/globalContext';
import EventStepperContextProvider from '../context/newEventContext';

// Theme
import { ThemeProvider } from '@material-ui/core/styles';

import { CssBaseline } from '@material-ui/core';

import theme from './themes/default';

export default function MainProvider() {
  return (
    <GlobalContextProvider>
      <AuthContextProvider>
        <StoreContextProvider>
          <ThemeProvider theme={theme}>
            <EventStepperContextProvider>
              <CssBaseline />
              <Main />
            </EventStepperContextProvider>
          </ThemeProvider>
        </StoreContextProvider>
      </AuthContextProvider>
    </GlobalContextProvider>
  );
}
