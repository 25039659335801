import React, { useState, useContext } from 'react';

import { EventStepperContext } from '../../context/newEventContext';

import { DropzoneArea } from 'material-ui-dropzone';
import {
  Box,
  Zoom,
  Typography,
  TextField,
  Tooltip,
  Slider,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

// import {
//   MuiPickersUtilsProvider,
//   KeyboardDateTimePicker,
// } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
// import 'date-fns';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';



import { useMainStyles, useTootipStyles } from '../classes/main';

const EventFormInfos = (props) => {
  const newEventContext = useContext(EventStepperContext);

  const handleLoadLogo = (e) => {
    console.log(e);
    //const image = e.target.files[0];
    newEventContext.setLogo(e[0] || '');
    // newEventContext.setImageAsFile((imageFile) => e[0]);
    //handleFireBaseUpload(e[0]);
  };

  const marks = {
    ptVente: [
      {
        value: 0,
        label: '0',
      },
      {
        value: 5,
        label: '5',
      },
      {
        value: 10,
        label: '10',
      },
      {
        value: 15,
        label: '15',
      },
      {
        value: 20,
        label: '20',
      },
    ],
    supports: [
      {
        value: 0,
        label: '0',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 6,
        label: '6',
      },
      {
        value: 10,
        label: '10',
      },
    ],
    ptRechargement: [
      {
        value: 0,
        label: '0',
      },
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
    ],
    nbTel: [
      {
        value: 10,
        label: '10',
      },
      {
        value: 60,
        label: '60',
      },
      {
        value: 120,
        label: '120',
      },
    ],
  };

  const TypesEvents = [
    {
      value: 'FESTIVAL',
      label: 'Festival',
    },
    {
      value: 'SOIREE',
      label: 'Soirée',
    },
    {
      value: 'BROCANTE',
      label: 'Brocante',
    },
    {
      value: 'DEMOEVENT',
      label: 'Evènement de démonstration',
    },
  ];

  const classes = useMainStyles();
  const tooltipClasse = useTootipStyles();

  return (
    <Box className={classes.form} bgcolor="white" width="90%">
      <Typography variant="h6" className={classes.instructions}>
        Renseignez les informations concernant l'évènement
      </Typography>
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        flexWrap="wrap"
        flexDirection="row">
        <Box className={classes.boxColumn}>
          <Box display="flex" flexWrap="wrap">
            <Tooltip
              classes={tooltipClasse}
              TransitionComponent={Zoom}
              title={
                <div>
                  <Typography color="inherit">
                    Je nomme mon évènement
                  </Typography>
                  <em>{'Renseignez ici le nom de votre évènement'}</em>{' '}
                  <br></br>{' '}
                  <u>{'Il vous sera possible de le modifier à postériori'}</u>.
                </div>
              }>
              <TextField
                className={classes.formInput}
                error={newEventContext.inputErrors.name}
                id="eventName"
                label="Nom"
                value={newEventContext.inputEventname}
                onChange={(e) =>
                  newEventContext.setInputEventname(e.target.value)
                }
              />
            </Tooltip>
            <TextField
              className={classes.formInput}
              id="filled-select-event-native"
              select
              label="Type d'évènement"
              value={newEventContext.selectedEventType}
              onChange={(e) =>
                newEventContext.setSelectedEventType(e.target.value)
              }
              SelectProps={{
                native: true,
              }}
              helperText="Selectionner le type d'évènement">
              {TypesEvents.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box display="flex" flexWrap="wrap">
            <TextField
              className={classes.formInput}
              id="standard-number"
              label="Capacité"
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              error={newEventContext.inputErrors.capacity}
              value={newEventContext.selectedCapacity}
              onChange={(e) =>
                newEventContext.setSelectedCapacity(e.target.value)
              }
              inputProps={{ min: 0, step: 100 }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className={classes.formInput}
              id="location"
              label="Localisation"
              error={newEventContext.inputErrors.location}
              value={newEventContext.selectedLocation}
              onChange={(e) =>
                newEventContext.setSelectedLocation(e.target.value)
              }
            />
          </Box>

          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" p={2}>
              <DateTimePicker
                // margin="small"
                // variant="inline"
                inputVariant="outlined"
                ampm={false}
                format="dd/MM/yyyy HH:mm"
                id="date-begin-picker-inline"
                label="Début de l'évènement"
                value={newEventContext.selectedBeginDate}
                onChange={(d) => newEventContext.setSelectedBeginDate(d)}
              />
            </Box>
          </LocalizationProvider>
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" p={2}>
              <DateTimePicker
                inputVariant="outlined"
                ampm={false}
                format="dd/MM/yyyy HH:mm"
                id="date-end-picker-inline"
                label="Fin de l'évènement"
                value={newEventContext.selectedEndDate}
                onChange={(d) => newEventContext.setSelectedEndDate(d)}
              />
            </Box>
          </LocalizationProvider>
        </Box>
        <Box className={classes.boxColumn}>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={newEventContext.majorDefault}
                  onChange={(e) =>
                    newEventContext.setMajorDefault(e.target.checked)
                  }
                />
              }
              labelPlacement="start"
              label="Options majeurs par défaut"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={newEventContext.forceLogin}
                  onChange={(e) =>
                    newEventContext.setForceLogin(e.target.checked)
                  }
                />
              }
              labelPlacement="start"
              label="Obliger de renseigner n° tel / QR Code"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={newEventContext.donationEnabled}
                  onChange={(e) =>
                    newEventContext.setDonationEnabled(e.target.checked)
                  }
                />
              }
              labelPlacement="start"
              label="Possibilité de dons aux participants"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={newEventContext.specialBadgeRestriction}
                  onChange={(e) =>
                    newEventContext.setSpecialBadgeRestriction(e.target.checked)
                  }
                />
              }
              labelPlacement="start"
              label="Restreindre la création des badges autres que festivaliers aux administrateurs"
            />
          </Box>
          <Box dispay="flex" alignSelf="center">
            <DropzoneArea
              initialFiles={newEventContext.logo ? [newEventContext.logo] : []}
              clearOnUnmount={false}
              // acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
              onChange={handleLoadLogo}
              showPreviews={false}
              acceptedFiles={['image/*']}
              filesLimit={1}
              maxFileSize={1000000}
              dropzoneText={
                'Glisser le fichier ou cliquer pour charger un logo'
              }
            />
          </Box>
        </Box>
        <Box className={classes.boxColumn}>
          <Box display="flex" p={2}>
            <Box width="50%">
              <Typography id="discrete-slider-custom">
                Nombre de points de vente
              </Typography>
            </Box>
            <Box width="50%">
              <Slider
                style={{ marginLeft: 20 }}
                //defaultValue={nbMoyGuests}
                getAriaValueText={(v) => v}
                aria-labelledby="discrete-slider-custom"
                step={1}
                min={0}
                max={20}
                valueLabelDisplay="auto"
                marks={marks.ptVente}
                value={newEventContext.nbPtVente}
                onChange={(e, value) => newEventContext.setnbPtVente(value)}
              />
            </Box>
          </Box>
          <Box display="flex" p={2}>
            <Box width="50%">
              <Typography id="discrete-slider-custom">
                Nombre de points de rechargement
              </Typography>
            </Box>
            <Box width="50%">
              <Slider
                style={{ marginLeft: 20 }}
                //defaultValue={nbMoyGuests}
                getAriaValueText={(v) => v}
                aria-labelledby="discrete-slider-custom"
                step={1}
                min={0}
                max={5}
                valueLabelDisplay="auto"
                marks={marks.ptRechargement}
                value={newEventContext.nbPtRechargement}
                onChange={(e, value) =>
                  newEventContext.setnbPtRechargement(value)
                }
              />
            </Box>
          </Box>
          <Box display="flex" p={2}>
            <Box width="50%">
              <Typography id="discrete-slider-custom">
                Nombre de personnes en supports
              </Typography>
            </Box>
            <Box width="50%">
              <Slider
                //defaultValue={nbMoyGuests}.
                style={{ marginLeft: 20 }}
                getAriaValueText={(v) => v}
                aria-labelledby="discrete-slider-custom"
                step={1}
                min={0}
                max={10}
                valueLabelDisplay="auto"
                marks={marks.supports}
                value={newEventContext.nbSupports}
                onChange={(e, value) => newEventContext.setnbSupports(value)}
              />
            </Box>
          </Box>
          <Box display="flex" p={2}>
            <Box width="50%">
              <Typography id="discrete-slider-custom">
                Nombre de terminaux
              </Typography>
            </Box>
            <Box width="50%">
              <Slider
                style={{ marginLeft: 20 }}
                //defaultValue={nbMoyGuests}
                getAriaValueText={(v) => v}
                aria-labelledby="discrete-slider-custom"
                step={1}
                min={0}
                max={120}
                valueLabelDisplay="auto"
                marks={marks.nbTel}
                value={newEventContext.nbTel}
                onChange={(e, value) => newEventContext.setnbTel(value)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* </form> */}
    </Box>
  );
};

export default EventFormInfos;
