import React from 'react';
import Events from '../view/subViews/Dashboard/Events';
import Event from '../view/subViews/Event/Event';
import Devices from '../view/subViews/Devices/Devices';
import Stands from '../view/subViews/Stands/Stands';
import NewEventView from '../view/subViews/Dashboard/NewEvent';

const protectedRoutes = [
  {
    name: 'Events',
    exact: true,
    path: '/events',
    main: (props) => <Events {...props} />,
    public: false,
  },
  {
    name: 'Event',
    exact: true,
    path: '/event',
    main: (props) => <Event {...props} />,
    public: false,
  },
  {
    name: 'Devices',
    exact: true,
    path: '/devices',
    main: (props) => <Devices {...props} />,
    public: false,
  },
  {
    name: 'Stands',
    exact: true,
    path: '/stands',
    main: (props) => <Stands {...props} />,
    public: false,
  },
  {
    name: 'New Event',
    exact: true,
    path: '/newevent',
    main: (props) => <NewEventView {...props} />,
    public: false,
  }
];

export default protectedRoutes;
