import React, { useState, useContext, useEffect, forwardRef } from 'react';

import { AuthContext } from '../../../context/authContext';
import { StoreContext } from '../../../context/storeContext';
import { GlobalContext } from '../../../context/globalContext';

import firebase from 'firebase/compat/app';
import { db } from '../../../firebase';

import { MTableToolbar } from '@material-table/core';
import StyledMaterialTable from '../../Component/StyledMaterialTable';
// import MaterialTable, { MTableToolbar } from 'material-table';
import { Box, Switch, Typography } from '@material-ui/core';

import { Delete, VerifiedUser } from '@material-ui/icons';

const RequestView = () => {
  const authContext = useContext(AuthContext);
  const store = useContext(StoreContext);
  const globalC = useContext(GlobalContext);

  const [showAll, setShowAll] = useState(false);
  const [showAll2, setShowAll2] = useState(false);

  const createOrga = (requestId) => {
    const request = store.requests.find((e) => e.id === requestId);

    db.collection('accounts')
      .doc(request.newAccount.firebaseID)
      .set({
        creationDate: new Date(),
        type: 'admin',
        festivals: [],
        displayName: request.newAccount.name || request.newAccount.email,
        email: request.newAccount.email,
        creationRequestId: request.id,
      })
      .then(() => {
        console.log('account created');
        updateRequest(request.id, { status: 'processed' });
        store.setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        updateRequest(request.id, { status: 'onError' });
        store.setLoading(false);
      });
  };

  const updateEventRequest = (id, update) => {
    db.collection('event_req')
      .doc(id)
      .update({ ...update, updateDate: new Date() })
      .then(() => {
        store.setLoading(false);
        console.log('request updated');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateRequest = (id, update) => {
    db.collection('requests')
      .doc(id)
      .update({ ...update, updateDate: new Date() })
      .then(() => {
        store.setLoading(false);
        console.log('request updated');
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const unsubscribe = store.loadRequests();
    const unsubscribe2 = store.loadEventRequests();
    globalC.setActiveMenu(5);

    return () => {
      unsubscribe && unsubscribe();
      unsubscribe2 && unsubscribe2();
    };
  }, []);

  return (
    <div>
      {authContext.type === 'superadmin' && (
        <Box>
          <Box p={3}>
            <StyledMaterialTable
              columns={[
                { title: 'ID', field: 'id', hidden: true },
                { title: 'Type', field: 'type' },
                {
                  title: 'Date',
                  // field: 'creationDate',
                  render: (rowData) => (
                    <div>{rowData.creationDate.toDate().toLocaleString()}</div>
                  ),
                },
                { title: 'Email', field: 'newAccount.email' },
                { title: 'Firebase ID', field: 'newAccount.firebaseID' },
                { title: 'Auth provider', field: 'newAccount.provider' },
                { title: 'Message', field: 'newAccount.message' },
                { title: 'Statut', field: 'status' },
              ]}
              options={{
                actionsColumnIndex: -1,
              }}
              data={
                showAll
                  ? store.requests
                  : store.requests.filter((e) => e.status === 'pending')
              }
              title="Demande de création d'utilisateur"
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />
                    <Box
                      paddingLeft={3}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      flexDirection="row">
                      <Typography>Voir toutes les demandes</Typography>
                      <Switch
                        checked={showAll}
                        onChange={() => setShowAll(!showAll)}
                      />
                    </Box>
                  </div>
                ),
              }}
              actions={[
                (rowData) => ({
                  disabled: rowData.status !== 'pending' ? true : false,
                  icon: () => <VerifiedUser />,
                  tooltip: 'Traiter',
                  onClick: (event, rowData) => {
                    // Pour l'instant, le traitement d'une demande revient uniquement à accepter l'utilisateur en tant qu'orga de festival.

                    store.setLoading(true);
                    createOrga(rowData.id);
                  },
                }),
                (rowData) => ({
                  disabled: rowData.status !== 'pending' ? true : false,
                  icon: () => <Delete />,
                  tooltip: 'Rejeter',
                  onClick: (event, rowData) => {
                    // Rejeter une demande revient uniquement à la marquer comme 'rejected' en base

                    store.setLoading(true);

                    //1. créer un user dans account
                    //2. déclencher envoi d'un mail
                    //3. passer la request en processed
                    updateRequest(rowData.id, {
                      status: 'rejected',
                    });
                  },
                }),
              ]}
            />
          </Box>
          <Box p={3}>
            <StyledMaterialTable
              columns={[
                { title: 'ID', field: 'id', hidden: true },
                { title: 'Type', field: 'eventType' },
                {
                  title: 'Date de la demande',
                  // field: 'creationDate',
                  render: (rowData) => (
                    <div>{rowData.creationDate.toDate().toLocaleString()}</div>
                  ),
                },
                {
                  title: 'Début',
                  // field: 'creationDate',
                  render: (rowData) => (
                    <div>{rowData.start.toDate().toLocaleString()}</div>
                  ),
                },
                {
                  title: 'Fin',
                  // field: 'creationDate',
                  render: (rowData) => (
                    <div>{rowData.end.toDate().toLocaleString()}</div>
                  ),
                },
                { title: 'Nom', field: 'name' },
                { title: 'Localisation', field: 'location' },
                { title: 'ID du demandeur', field: 'uidrequester' },
                { title: 'Email du demandeur', field: 'emailrequester' },
                { title: 'Capacité', field: 'capacity' },
                {
                  title: 'Statut',
                  field: 'status',
                },
                { title: 'Logo', field: 'logo' },
                {
                  title: 'Types de tarifs',
                  field: 'badgeType',
                  render: (rowData) => {
                    return <div>{JSON.stringify(rowData.badgeType)}</div>;
                  },
                },
              ]}
              components={{
                Toolbar: (props) => (
                  <div>
                    <MTableToolbar {...props} />
                    <Box
                      paddingLeft={3}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      flexDirection="row">
                      <Typography>Voir toutes les demandes</Typography>
                      <Switch
                        checked={showAll2}
                        onChange={() => setShowAll2(!showAll2)}
                      />
                    </Box>
                  </div>
                ),
              }}
              options={{
                actionsColumnIndex: -1,
                // filtering: true
              }}
              data={
                showAll2
                  ? store.eventRequests
                  : store.eventRequests.filter((e) => e.status === 'pending')
              }
              title="Demandes de création d'évènement"
              actions={[
                (rowData) => ({
                  disabled: rowData.status !== 'pending' ? true : false,
                  icon: () => <VerifiedUser />,
                  tooltip: 'Accepter',
                  onClick: (event, rowData) => {
                    store.setLoading(true);
                    db.collection('festivals')
                      .add({
                        creationDate: new Date(),
                        name: rowData.name,
                        badgeType: rowData.badgeType,
                        logo: rowData.logo,
                        start: rowData.start,
                        end: rowData.end,
                        capacity: rowData.capacity,
                        eventType: rowData.eventType,
                        location: rowData.location,
                      })
                      .then((docRef) => {
                        db.collection('festivals')
                          .doc(docRef.id)
                          .update({
                            festival: docRef.id,
                          })
                          .then(() => {
                            // Attach event to the user
                            db.collection('accounts')
                              .doc(rowData.uidrequester)
                              .update({
                                festivals:
                                  firebase.firestore.FieldValue.arrayUnion(
                                    docRef.id,
                                  ),
                              })
                              .then(() => {
                                store.setLoading(false);
                                //alert("Votre festival a été créé vous le retrouverez dans le dashboard");
                                globalC.setSnack({
                                  message:
                                    'Le festival a été créé vous le retrouverez dans le dashboard',
                                  color: 'success',
                                  open: true,
                                });
                                db.collection('event_req')
                                  .doc(rowData.id)
                                  .update({
                                    updateDate: new Date(),
                                    status: 'accepted',
                                  });
                              })
                              .catch((err) => {
                                store.setLoading(false);
                                console.log(err);
                              });

                            //db.collection('users').doc.
                          })
                          .catch((err) => {
                            store.setLoading(false);
                            console.log(err);
                          });
                      })
                      .catch((err) => {
                        store.setLoading(false);
                        console.log(err);
                      });
                  },
                }),
                (rowData) => ({
                  disabled: rowData.status !== 'pending' ? true : false,
                  icon: () => <Delete />,
                  tooltip: 'Rejeter',
                  onClick: (event, rowData) => {
                    store.setLoading(true);

                    globalC.setSnack({
                      ...globalC.snack,
                      open: true,
                      message: "L'évènement a été modifié.",
                      color: 'success',
                    });

                    updateEventRequest(rowData.id, {
                      status: 'rejected',
                    });
                  },
                }),
              ]}
            />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default RequestView;
